import * as React from "react";
export const UnderlineIcon: React.FC<SvgIconProps> = ({
  height = "25px",
  width = "25px",
  fillColor = "#fff",
}: SvgIconProps) => (
    <svg
    width={width}
    height={height}
    viewBox="-2 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="underline" transform="translate(-4 -2)">
      <line
        id="primary"
        x1={14}
        transform="translate(5 21)"
        fill="none"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        id="primary-2"
        data-name="primary"
        d="M7,3v9a5,5,0,0,0,5,5h0a5,5,0,0,0,5-5V3"
        fill="none"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </g>
  </svg>
);