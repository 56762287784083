import { title } from "process"
import "./dashboardQuestionSection.scss"

const questions=[{
    // title=
}]

export const DashboardQuestionSection:React.FC=()=>{
    // const questions=
return <div className="dashboard-question-section-container">
    <div className="question-section-header">Questions</div>
    <div className="question-section-content">
      
    </div>
</div>
}