import React from "react";
import './IntroAutoSlider.scss'

import { HtmlIcon } from "../../../../icons/NewLandingPageIcons/htmlIcon";
import { CssIcon } from "../../../../icons/NewLandingPageIcons/cssIcon";
import { SassIcon } from "../../../../icons/NewLandingPageIcons/scssIcon";
import { JavascriptIcon } from "../../../../icons/NewLandingPageIcons/javascript";
import { TypeScript } from "../../../../icons/NewLandingPageIcons/typescript";
import { ReactIcon } from "../../../../icons/NewLandingPageIcons/reactIcon";
import { NodeIcon } from "../../../../icons/NewLandingPageIcons/nodeIcon";
import { ExpressIcon } from "../../../../icons/NewLandingPageIcons/expressIcon";
import { MongoDB } from "../../../../icons/NewLandingPageIcons/mongoDB";
import { GitIcon } from "../../../../icons/NewLandingPageIcons/gitIcon";
import { GitHubIcon } from "../../../../icons/NewLandingPageIcons/gitHubIcon";

export const IntroAutoSlider: React.FC =()=>{
    return(
        <div className="auto-slider-container">
        <div className="left shadow"></div>
        <div className="right shadow"></div>
        <div className="auto-slider">
            <div className="stacks">
                <div className="stack-logo"><HtmlIcon/></div> 
                Html
            </div>
            <div className="stacks">
                <div className="stack-logo"><CssIcon/></div> 
                Css
            </div>
            <div className="stacks">
                <div className="stack-logo"><SassIcon/></div> 
                Sass
            </div>
            <div className="stacks">
                <div className="stack-logo"><JavascriptIcon/></div> 
                JavaScript
            </div>
            <div className="stacks">
                <div className="stack-logo"><TypeScript/></div> 
                TypeScript
            </div>
            <div className="stacks">
                <div className="stack-logo"><ReactIcon/></div> 
                React
            </div>
            <div className="stacks">
                <div className="stack-logo"><NodeIcon/></div> 
                Node
            </div>
            <div className="stacks">
                <div className="stack-logo"><ExpressIcon/></div> 
                Express
            </div>
            <div className="stacks">
                <div className="stack-logo"><MongoDB/></div> 
                MongoDB
            </div>
            <div className="stacks">
                <div className="stack-logo"><GitIcon/></div> 
                Git
            </div>
            <div className="stacks">
                <div className="stack-logo"><GitHubIcon/></div> 
                GitHub
            </div>
        </div>
        <div className="auto-slider">
            <div className="stacks">
                <div className="stack-logo"><HtmlIcon/></div> 
                Html
            </div>
            <div className="stacks">
                <div className="stack-logo"><CssIcon/></div> 
                Css
            </div>
            <div className="stacks">
                <div className="stack-logo"><SassIcon/></div> 
                Sass
            </div>
            <div className="stacks">
                <div className="stack-logo"><JavascriptIcon/></div> 
                JavaScript
            </div>
            <div className="stacks">
                <div className="stack-logo"><TypeScript/></div> 
                TypeScript
            </div>
            <div className="stacks">
                <div className="stack-logo"><ReactIcon/></div> 
                React
            </div>
            <div className="stacks">
                <div className="stack-logo"><NodeIcon/></div> 
                Node
            </div>
            <div className="stacks">
                <div className="stack-logo"><ExpressIcon/></div> 
                Express
            </div>
            <div className="stacks">
                <div className="stack-logo"><MongoDB/></div> 
                MongoDB
            </div>
            <div className="stacks">
                <div className="stack-logo"><GitIcon/></div> 
                Git
            </div>
            <div className="stacks">
                <div className="stack-logo"><GitHubIcon/></div> 
                GitHub
            </div>
        </div>
      </div>

    );
};
