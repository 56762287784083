import React from 'react'
import './HomePageHeaderSection.scss';
import HomePageBackground from "./HomePageBackgroundSVG.";
import { IntroAutoSlider } from '../IntroAutoSlider/IntroAutoSlider';
import img1 from '../../../assets/images/kraticDp.jpeg'
import img2 from '../../../assets/images/sagarDp.jpeg'
import img3 from '../../../assets/images/rishabhDp.jpeg'
import { useNavigate } from 'react-router-dom';

const HomePageHeaderSection = () => {
  const Navigate =  useNavigate()
  return (
    <div className='home-page-header-section-container'>
      <div className='home-page-background'>
        <HomePageBackground />
      </div>
      <div className='header-page-container-box'>
        <div className='beWebMaster-tag'>
          @WebMasters
        </div>
        <div className='success-story-button'>
          <div className='avator1'>
            <img className='avator-img' src={img3} alt="" />
          </div>
          <div className='avator2'>
            <img className='avator-img' src={img2} alt="" />
          </div>
          <div className='avator3'>
            <img className='avator-img' src={img1} alt="" />
          </div>
          <div className='text'> 100+ members</div>
          <div className='join-now-button'
          onClick={()=> Navigate('/register')}
          > Join Now
          </div>
        </div>
        <div className='header-page-main-heading'>
          <h1 className='header-page-main-heading-h1' >Don’t Just Learn to Code,</h1>
          <h1 className='header-page-main-heading-h1' > Learn to Get Hired.</h1>
        </div>
        <div className='header-page-main-subheading'>
          Gain the skills top employers are looking for and start your journey to a 6LPA+ career.
        </div>
        <div className='header-page-join-weebinar-button'
        onClick={()=> Navigate('/register')}
        >
          Join Us
        </div>
      </div>

      <div className='auto-nav-container'>
        <div className='left-box box-shadow'></div>
        <IntroAutoSlider />
        <div className='right-box box-shadow'></div>
      </div>
    </div>
  )
}

export default HomePageHeaderSection
