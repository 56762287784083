import { createSlice } from "@reduxjs/toolkit";

const initialState: HeaderStateType = {
  title: "",
  options: []
};

export const headerSlice = createSlice({
  name: "header",
  initialState: initialState,
  reducers: {
    setHeader: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});



export const { setHeader } = headerSlice.actions;
export default headerSlice.reducer;