import React from 'react'
import "./AboutUsComponent.scss"
import aboutUsImage from "../../assets/images/aboutUsImage.jpg"
import FlagIcon from '../../assets/icons/FlagIcon';
import { useNavigate } from 'react-router-dom';
interface AboutUsComponentProps{
    navRef: React.RefObject<HTMLDivElement>;
}
export const AboutUsComponent: React.FC<AboutUsComponentProps> = ({navRef}) => {
    const Navigate = useNavigate()
    return (
        <div className='about-us-component'
        ref={navRef}
        >
            <div className='about-us-top'>
                <div className='about-us-top-text-container'>
                    <h4 className='about-us-top-text-h4'>
                      <FlagIcon/> About Us
                    </h4>
                    <h1 className='about-us-top-text-h1'>Empowering Students to Build Careers That Matter</h1>
                    <h4 className='about-us-top-text-h5'>We’ve walked in your shoes—now let’s walk you into a 6LPA+ job.</h4>
                    <p className='about-us-top-text-p'>At WebMasters, we’re more than a coding bootcamp—we’re your launchpad to a high-paying tech career. Our 6-month Full Stack Web Development course goes beyond theory, giving you real-world experience and job-ready skills. What sets us apart? Our course is designed and taught by experienced software developers who themselves are working in high-paying tech jobs. We focus on what companies actually hire for, with hands-on projects, personalized mentorship, and career support to help you land 6LPA+ jobs. If you’re serious about turning your coding passion into a career, WebMasters is where it all starts.</p>
                    <button className='about-us-top-text-contact-us-button' 
                     onClick={()=> Navigate('/register')}
                    >Contact us</button>
                    <div className='about-us-top-text-advancement-container'>

                        <div className='about-us-top-text-advancement-container-up'>
                            <div className='about-us-top-text-advancement-card'>
                                <div className='about-us-top-text-advancement-card-text'>
                                    <h4 className='about-us-top-text-advancement-card-text-h4'>12+</h4>
                                    <h5 className='about-us-top-text-advancement-card-text-h5'>placement</h5>
                                </div>
                            </div>
                            <div className='about-us-top-text-advancement-card'>
                                <div className='about-us-top-text-advancement-card-text'>
                                    <h4 className='about-us-top-text-advancement-card-text-h4'>32+</h4>
                                    <h5 className='about-us-top-text-advancement-card-text-h5'>Student</h5>
                                </div>
                            </div>
                        </div>
                        <div className='about-us-top-text-advancement-container-down'>
                            <div className='about-us-top-text-advancement-card'>
                                <div className='about-us-top-text-advancement-card-text'>
                                    <h4 className='about-us-top-text-advancement-card-text-h4'>4.2</h4>
                                    <h5 className='about-us-top-text-advancement-card-text-h5'>Ratings</h5>
                                </div>
                            </div>
                            <div className='about-us-top-text-advancement-card'>
                                <div className='about-us-top-text-advancement-card-text'>
                                    <h4 className='about-us-top-text-advancement-card-text-h4'>20+</h4>
                                    <h5 className='about-us-top-text-advancement-card-text-h5'>Team Members</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='about-us-top-image-container'>
                    <img className='about-us-top-image' src={aboutUsImage} alt="" />
                </div>
            </div>

            <div className='about-us-bottom'>
                <div className='about-us-bottom-card'>
                    <h4 className='about-us-bottom-card-h4'>Why Choose WebMasters ?</h4>
                    <p className='about-us-bottom-card-p'>At WebMasters, we offer hands-on learning, expert mentorship, real-world projects, and earning opportunities to build your web development career.</p>
                </div>
                <div className='about-us-bottom-card'>
                    <h4 className='about-us-bottom-card-h4'>Our Mission</h4>
                    <p className='about-us-bottom-card-p'>Empowering students with skills and confidence through hands-on learning that bridges the gap between theory and industry demands.</p>
                </div>
                <div className='about-us-bottom-card'>
                    <h4 className='about-us-bottom-card-h4'>Our Team</h4>
                    <p className='about-us-bottom-card-p'>Our passionate mentors are industry professionals dedicated to guiding the next generation of web developers with real-world expertise.</p>
                </div>

            </div>


        </div>
    )
}
