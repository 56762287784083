import "./createGoalComponent.scss";
import { useState } from "react";
import { CloseCrossIcon } from "../../icons/closeCrossIcon";
import { InputComponent } from "../input/inputComponent";
import { GoalSelectContainer } from "../goalSelectContainer/goalSelectContainer";
import { Button } from "../button/button";
import { createGoal } from "../../redux/actions/admin/createGoal";
import { Text } from "../text/text";
import RichTextEditor from "../richTextEditor/richTextEditor";
export const CreateGoalComponent: React.FC<CreateGoalComponentProps> = ({
  onClose,
}) => {
  const [goalData, setGoalData] = useState<CreateGoalType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleOnSetTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGoalData((goalData) => {
      return { ...goalData, title: e?.target?.value };
    });
  };
  const handleOnSetDesription = (e:string) => {
    setGoalData((goalData) => {
      return { ...goalData, description: e };
    });
  };

  const handleOnAddGoal = async () => {
    setIsLoading(true);
    const response = await createGoal({
      batchCode: goalData?.batchCode,
      description: goalData?.description,
      endWeek: goalData?.endWeek,
      goalType: goalData?.goalType,
      isLive: goalData?.isLive,
      isMandatory: goalData?.isMandatory,
      startWeek: goalData?.startWeek,
      title: goalData?.title,
      isActive:goalData?.isActive,
      userResponseLabels:goalData?.userResponseLabels,
    });
    setIsLoading(false);
    const status = response?.response?.status;
    const message = response?.response?.message;
    if (status) window.alert(message);
  };
  return (
    <div className="create-goal-component-wrapper">
      <div className="close-button" onClick={onClose}>
        <CloseCrossIcon fillColor="#fff" />
      </div>
      <div className="goal-component-header">
        <Text className="goal-header-text" textType="h2">
          Create new goal
        </Text>
      </div>
      <div className="create-goal-input-wrapper">
        <label htmlFor="batch-code" className="create-goal-label">
          Title :
        </label>
        <InputComponent
          className="create-goal-input"
          key={"title"}
          type="text"
          onChange={handleOnSetTitle}
          placeholder="Title"
        />
      </div>
      <GoalSelectContainer setGoalData={setGoalData} goalData={goalData}/>
      <div className="create-goal-input-wrapper">
        <label htmlFor="batch-code" className="create-goal-label">
          Enter description :
        </label>
        <RichTextEditor onChange={handleOnSetDesription} value="" />
      </div>
      <div className="add-goal-button-container">
        <Button
          key={"addButton"}
          text={"Add Goal"}
          onClick={handleOnAddGoal}
          className="add-goal-button"
          isLoading={isLoading}
          iconPosition="center"
          isDisabled={!goalData?.batchCode || !goalData?.title || !goalData?.userResponseLabels?.length}
        />
      </div>
    </div>
  );
};
