import React from "react";
import { apolloClient } from "../../graphql/apolloClient/apolloClient";
import { actions } from "../slices/question/questionSlice";
import { GET_ALL_QUESTIONS } from "../../graphql/query/question/getAllQuestions";
import { selectQuestions } from "../slices/question/questionSlice";
import { useDispatch, useSelector } from "react-redux";
import { GET_ALL_DSA_QUESTIONS } from "../../graphql/query/question/getAllDsaQuestion";
import { GET_ALL_QUESTION_TOPIC_SUBTOPIC } from "../../graphql/query/question/getAllQuestionsDataBySubTopics";
import {
  questionTopicSubtopicAction,
  topicSubtopicQuestionCountData,
} from "../slices/question/topicSubtopicQuestionCount";
import { GET_QUESTION } from "../../graphql/query/question/getQuestionById";

export const useQuestions = () => {
  const questions = useSelector(selectQuestions);
  const questionCountData = useSelector(topicSubtopicQuestionCountData);
  const dispatch = useDispatch();
  const getAllQuestions = async ({
    day,
    week,
    batchCode,
    isActive,
    isArchived,
    topic,
    skip,
    limit,
    tags,
  }: GetAllQuestionProps) => {
    try {
      dispatch(actions.setIsQuestionLoading(true));
      const response = await apolloClient.query({
        query: GET_ALL_QUESTIONS,
        variables: {
          filterData: {
            day,
            week,
            batchCode,
            isActive,
            isArchived,
            topic,
            tags,
          },
          pagination: {
            skip,
            limit,
          },
        },
      });
      dispatch(actions.setQuestions(response.data));
      return response;
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(actions.setIsQuestionLoading(false));
    }
  };

  const getAllDsaQuestions = async ({
    day,
    week,
    batchCode,
    isActive,
    isArchived,
    topic,
    skip,
    limit,
    tags,
  }: GetAllQuestionProps) => {
    try {
      dispatch(actions.setIsQuestionLoading(true));
      const response = await apolloClient.query({
        query: GET_ALL_DSA_QUESTIONS,
        variables: {
          filterData: {
            day,
            week,
            batchCode,
            isActive,
            isArchived,
            topic,
            tags,
          },
          pagination: {
            skip,
            limit,
          },
        },
      });
      return response.data.getAllDsaQuestions;
    } catch (err) {
      console.error(err);
    }
  };

  const getAllQuestionByTopicSubtopicCount = async () => {
    const { setQuestionTopicSubtopic } = questionTopicSubtopicAction;
    try {
      const response = await apolloClient.query({
        query: GET_ALL_QUESTION_TOPIC_SUBTOPIC,
        variables: {},
      });
      dispatch(
        setQuestionTopicSubtopic(
          response?.data?.getAllQuestionsDataBySubTopics?.questionData || []
        )
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch question topic subtopic", error);
    }
  };
  const getQuestion = async ({questionId}:{questionId:string}) => {
    try {
      const response = await apolloClient.query({
        query: GET_QUESTION,
        variables: {
          questionId: questionId,
        },
      });
      return response.data.getQuestionById;
    } catch (error) {
      console.error("Failed to fetch question topic subtopic", error);
      return null
    }
  };

  return {
    getAllQuestionByTopicSubtopicCount,
    getAllDsaQuestions,
    questions,
    getAllQuestions,
    questionCountData,
    getQuestion
  };
};
