import Accordion from "../accordion/accordion";
import { FLagIcon, DownIcon, PlayIcon, LeftArrowIcon } from "../../icons";
import "./tutorial.scss";
import React, { ReactNode, useEffect, useState } from "react";
import { TutorialAccordianContent } from "../tutorialAccordianContent/tutorialAccordianContent";
import datastructure from  "../../monorepoClient/assets/images/datastucture.png" 
import conditionals from  "../../monorepoClient/assets/images/conditionals.png" 
import forloop from  "../../monorepoClient/assets/images/forloop.png" 
import hoisting from  "../../monorepoClient/assets/images/hoisting.png" 
const accordionData = [
  {
    title: (
      <span className="tutorial-accordian-title">
        {/* <span role="img" aria-label="clock">
          🕒
        </span> */}
        Data structure in JavaScript
      </span>
    ),
    subTitle: "A data structure in JavaScript is a way of organizing and storing data that allows developers to efficiently perform operations on that data.",
    content: (
      <>
        <TutorialAccordianContent
          title="Data structure in JavaScript"
          description="A data structure in JavaScript is a way of organizing and storing data that allows developers to efficiently perform operations on that data."
          thumbnailUrl= {datastructure}
          videoUrl='https://drive.google.com/file/d/1ksMFjaXuhwEWNGTViPNRcptxD6qUGYjV/view'
        />
      </>
    ),
  },
  {
    title: (
      <span className="tutorial-accordian-title">
        {/* <span role="img" aria-label="collaboration">
          👥
        </span> */}
        Conditionals in JS
      </span>
    ),
    subTitle:
      "Conditionals in JavaScript control code execution based on conditions using if, else, else if, and switch statements",
    content: (
      <>
        <TutorialAccordianContent
          title="Conditionals in JS"
          description="Conditionals in JavaScript control code execution based on conditions using if, else, else if, and switch statements"
          thumbnailUrl={conditionals}
          videoUrl= "https://youtu.be/cxCTQpXDJ_g"
        /> 
      </>
    ),
  },
  {
    title: (
      <span className="tutorial-accordian-title">
        {/* <span role="img" aria-label="clock">
          🕒
        </span> */}
        For Loop in JavaScript 
      </span>
    ),
    subTitle: "A for loop in JavaScript repeatedly executes code as long as a specified condition is true, ideal for iterating over arrays.",
    content: (
      <>
        <TutorialAccordianContent
          title="For Loop in JavaScript "
          description="A for loop in JavaScript repeatedly executes code as long as a specified condition is true, ideal for iterating over arrays."
          thumbnailUrl={forloop}
          videoUrl="https://youtu.be/UWiCKYZhnYc"
        />
      </>
    ),
  },
  {
    title: (
      <span className="Hoisting in JavaScript">
        {/* <span role="img" aria-label="collaboration">
          👥 
        </span> */}
         Hoisting in JavaScript 
      </span>
    ),
    subTitle:
      "Hoisting in JavaScript moves variable and function declarations to the top of their scope before code execution.",
    content: (
      <>
        <TutorialAccordianContent
          title="Hoisting in JavaScript"
          description="Hoisting in JavaScript moves variable and function declarations to the top of their scope before code execution."
          thumbnailUrl={hoisting}
          videoUrl='https://youtu.be/tILLKebY-Js'
        />
      </>
    ),
  },
];
interface TutorialsSectionProps {
  className?: string;
  navRef: React.RefObject<HTMLDivElement>;
}

export const TutorialsSection: React.FC<TutorialsSectionProps> = ({
  className, navRef
}) => {
  const [activeTutorials, setActiveTutorials] = useState<number[]>([0]);
  const [currentTutorialThumbnail, setCurrentTutorialTumbnail] =
    useState<ReactNode>(accordionData[0].content);
  const [isDesktopView, setIsDesktopView] = useState<boolean>(
    window.innerWidth >= 1200
  );

  const handleResize = () => {
    setIsDesktopView(window.innerWidth >= 1200);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOnOpen = (index: number) => {
    if (activeTutorials.length === 1 && isDesktopView) {
      setActiveTutorials([index]);
    } else {
      setActiveTutorials((prevActiveTutorials) =>
        prevActiveTutorials.includes(index)
          ? prevActiveTutorials.filter((i) => i !== index)
          : [...prevActiveTutorials, index]
      );
    }
    setCurrentTutorialTumbnail(accordionData[index].content);
  };

  return (
    <div className={`tutorials-container-wrapper ${className}`} ref={navRef}>
      <div className="tutorial-header-container">
        <a className="tutorial-icon-container"
          target="_blank"
          href='https://www.youtube.com/@BeWebMasters'
        >
          <FLagIcon /> Videos
        </a>
        <div className="tutorial-header">Master Web with Our Tutorials</div>
        <p className="tutorial-header-paragraph">
          Unlock the full potential of Web with our step-by-step tutorials
          covering key features and functionalities.
        </p>
      </div>

      <div className="tutorials-container">
        <div className="sidebar-accordian-container">
          {accordionData.map((data, index) => {
            const isActive = activeTutorials.includes(index);
            return (
              <Accordion
                key={data.subTitle}
                title={data.title}
                subTitle={data.subTitle}
                defaultOpen={isActive}
                icon={
                  isDesktopView ? (
                    <>
                      <LeftArrowIcon fillColor="#333333" />
                    </>
                  ) : (
                    <DownIcon />
                  )
                }
                isSideSelectorOpen={isDesktopView && isActive}
                onOpen={() => handleOnOpen(index)}
                className={`tutorial-item-list-accordian ${isActive && "active-tutorial-item-list-accordian"
                  } ${isDesktopView && "desktop-view-tutorial-item-list-accordian"}`}
              >
                {!isDesktopView && (
                  <div className="tutorial-content-wrapper">
                    {" "}
                    {data.content}
                  </div>
                )}
              </Accordion>
            );
          })}
        </div>
        {isDesktopView && (
          <div className="tutorial-selected-video-thumbnail-container">
            {currentTutorialThumbnail}
          </div>
        )}
      </div>
    </div>
  );
};
