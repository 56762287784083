import UIIMage from "../../assets/images/screen_shot_whapp.png";

const DummyComponentForWhatsapp = () => {
  return (
    <div>
      <img src={UIIMage} alt="whatsapp-img" />
    </div>
  );
};

export default DummyComponentForWhatsapp;
