import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import { DashboardClassComponent } from "../../components/dashboardClassComponent/dashboardClassComponent";
import { DashboardQuestionComponent } from "../../components/dashboardQuestionComponent/dashboardQuestionComponent";
import { DashboardAssignmentComponent } from "../../components/dashboardAssignmentComponent/dashboardAssignmentComponent";
import useHeader from "../../redux/actions/headerAction";
import { DashboardQuestionSection } from "../../components/dashboardQuestionSection/dashboardQuestionSection";
import { useSelector } from "react-redux";
import { useUser } from "../../redux/actions/userAction";


export const Dashboard: React.FC<DashboardProps> = ({}: DashboardProps) => {
  const { updateHeader } = useHeader();
  useEffect(() => {
    updateHeader({ title: "Dashboard Page", options: [] })
  }, []);
  const { user } = useUser();
  const { userData } = user || {};
  const { batchCode } = userData || {};

  return (
    <div className={`dashboard`}>
    { batchCode === "WMB3-16092024" && user?.isAdmin !== true && <div className="info-card">
      <div className="info-icon">ℹ️</div>
      <div className="info-content">
        <p className="info-message"><strong>{"The data will be displayed as soon as the first installment is paid."}</strong></p>
        {/* <p className="info-sub-message">{"The data will be displayed as soon as the first installment is paid."}</p> */}
      </div>
      {/* <div className="info-close" onClick={()=>{}}>×</div> */}
    </div>}
      <div className={`dashboard-container`}>
        <DashboardClassComponent />
        <DashboardQuestionComponent/>
        <DashboardAssignmentComponent/>
        <DashboardQuestionSection/>
      </div>
      <div className="dashboard-overview"></div>
    </div>
  );
};
