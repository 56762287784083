import React, { useEffect, useRef, useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './richTextEditor.scss'

const RichTextEditor = ({ value, onChange, editable=true,className }: RichTextEditorProps) => {
  const [editorState, setEditorState] = useState(() => {
    let state = EditorState.createEmpty();
    if (value) {
      try {
        state = EditorState.createWithContent(
          convertFromRaw(JSON.parse(value))
        );
      } catch (err) {}
    }
    return state;
  });

  const handleEditorChange = (state: any) => {
    if(editable)
    setEditorState(state);
  };

  return (
    <div onClick={(e: any) => e.stopPropagation()} className={className}>
      <Editor
        toolbarClassName={`rich-text-editor-toolbar ${!editable &&"hidden"}`}
        wrapperClassName="rich-text-editor-wrapper"
        editorClassName="rich-text-editor-container"
        editorState={editorState}
        onContentStateChange={(e) => onChange && onChange(JSON.stringify(e))}
        onEditorStateChange={handleEditorChange}
        toolbar={{
          options: [
            "history",
            "inline",
            "fontFamily",
            "fontSize",
            "blockType",
            "textAlign",
            "list",
            "colorPicker",
            "link",
            "embedded",
            "emoji",
            "image",
          ],
          inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline", "strikethrough"],
          },
          blockType: { inDropdown: true },
          fontSize: {
            options: [10, 12, 14, 16, 18],
            default: 10,
            isDropdown: true,
          },
          fontFamily: {
            options: [
              "Arial",
              "Georgia",
              "Impact",
              "Tahoma",
              "Times New Roman",
              "Verdana",
            ],
          },
          list: { inDropdown: false },
          textAlign: { inDropdown: false },
          colorPicker: { inDropdown: true },
          backgroundColor: { inDropdown: true },
          link: { inDropdown: true },
          embedded: { inDropdown: false },
          emoji: { inDropdown: false },
          image: {
            uploadEnabled: true,
            alt: { present: true, mandatory: true },
          },
        }}
      />
    </div>
  );
};

export default RichTextEditor;