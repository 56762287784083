import { useDispatch, useSelector } from "react-redux";
import { apolloClient } from "../../graphql/apolloClient/apolloClient";
import { actions, selectBatch } from "../slices/batch/batchSlice";
import { GET_BATCH_CODE } from "../../graphql/query/batch/getBatchCode";
import { useUser } from "./userAction";
import { GET_ALL_BATCHES } from "../../graphql/query/batch/getAllBatches";
import { GET_BATCH_DETAILS } from "../../graphql/query/batch/getBatchDetails";
export const useBatch = () => {
    const dispatch = useDispatch();
    const { batchData, currentBatch, discordServerLink, githubOrgRepo  } = useSelector(selectBatch);
    const { batchCode } = useUser()?.user?.userData || {};

    const getBatchCode= async () => {
        try {
            const response = await apolloClient.query({
                query: GET_BATCH_CODE,
                variables: {},
            });
            dispatch(actions.setBatchCode(response.data.getBatchCode));
            return response;
        } catch (err) {
            console.error(err);
        }
    };

    const getAllBatches= async () => {
        try {
            const response = await apolloClient.query({
                query: GET_ALL_BATCHES,
                variables: {},
            });
            dispatch(actions.setBatchCode(response.data.getAllBatches));
        } catch (err) {
            console.error(err);
        }
    };

    const setCurrentBatchCode = (currBatch?: CurrentBatchType) => {
        dispatch(actions.setCurrentBatchCode(currBatch ?? {batchCode:batchCode?? ""}));
    };
     
    const getBatchDetails= async (batchCode:string)=>{
        
        try {
             const response = await apolloClient.query({
                query:GET_BATCH_DETAILS,
                variables:{
                    batchCode:batchCode
                }
             })
             
             dispatch(actions.setBatchGithubOrgRepoAndDiscordServerLink(response.data?.getBatchDetails?.batchDetails));
        } catch (error) {
            console.error(error);
        }
    }
    return { batchData, getBatchCode, setCurrentBatchCode, currentBatch, getAllBatches, getBatchDetails, discordServerLink, githubOrgRepo };
};