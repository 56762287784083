import React, { useRef } from "react";
import useOnOutsideClick from "../../hooks/useOnOutsideClick";
import { CloseCrossIcon } from "../../icons/index";
import { Modal } from "../../components/Modal/modal";
import { Text } from "../../components/text/text";
import "./goalInfoModal.scss";
export const GoalInfoModal : React.FC<GoalInfoModalProps> = (props : GoalInfoModalProps) => {
  const {title,onOutsideClick=()=>{},children, onClick=()=>{}} = props;
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  useOnOutsideClick(tooltipRef, onOutsideClick);
  return (
    <Modal className="goal-info-card-modal-main-container" isClosable={true}>
      <div
        ref={tooltipRef}
        onClick={(e) => e.stopPropagation()}
        className="goal-info-card-main-wrapper"
      >
        <div className="goal-modal-close-button-wrapper">
          <div className="goal-modal-close-button" onClick={onClick}>
            <CloseCrossIcon fillColor="black" height="20" width="20" />
          </div>
        </div>
        <div className="goal-info-card-main-container">
          <div className="goal-info-card-header">
            {title && <div className="goal-info-card-title">{title}</div>}
          </div>
          <div className="goal-info-card-children">{children}</div>
        </div>
      </div>
    </Modal>
  );
};