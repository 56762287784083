import React from "react";
export const CloseCrossIcon: React.FC<SvgIconProps> = (props : SvgIconProps) => {
  const { fillColor, height=20, width=20,isDarkMode } = props;
  return (
    <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 6L18 18" stroke={fillColor ? fillColor : "white"} strokeLinecap="round" />
    <path d="M18 6L6.00001 18" stroke={fillColor ? fillColor : "white"} strokeLinecap="round" />
  </svg>
  );
};
