import { useContext } from "react";
import { ThemeContext } from "../Themecontext/ThemeContext";
import "./PricingInfo.scss";
import FLagIcon from "../../assets/icons/FlagIcon";

export const PricingInfo = ({ navRef }) => {
  const { darkMode } = useContext(ThemeContext);
  const features = [
    { title: "Guaranteed Job Assistance", subTitle: "Get personalized job placement support to land a 6LPA+ job after the course." },
    { title: "Real-World Projects", subTitle: "Work on industry-level projects that build your portfolio and make you stand out to employers." },
    { title: "Expert Instructors", subTitle: "Learn from full-time software developers with years of real-world experience." },
    { title: "Mentorship and Career Guidance", subTitle: "1-on-1 mentorship and career counseling to guide you through interviews and beyond." },
    { title: "Lifetime Access to Course Material", subTitle: "Lifetime access to all course content, so you can revisit and stay updated even after the course ends" }
  ];
  const highlightMessage = false;
  return (
    <div
      ref={navRef}
      className={`${darkMode
        ? "faqComponent faqComponentBlack"
        : "faqComponent faqComponentWhite"
        } pricing-info-container`}
      id="pricing"
    >
      <div className="pricing-info-main-header">
        <div className="pricing-info-icon">
          <FLagIcon />
          Pricing
        </div>
        <h2 className="pricing-main-header">Efficient Pricing </h2>
        <p className="pricing-info-header-paragraph">
        Master Web Development with Expert Instructors, Career Guidance, and Guaranteed Job Assurance
        </p>
      </div>
      <div className="pricing-info-sub-container">
        <div className="pricing-info-header">
          <img
            className="pricing-info-logo"
            src="https://framerusercontent.com/images/UAAxrCvTYxUJ4RMcQOY6Bkkhmn4.png"
          />
          <span className="pricing-info-rate">
            <strong>₹19,999</strong>
            {/* / Per Yearly */}
          </span>
        </div>
        <div className="pricing-info-category">Full Stack Development</div>
        <div className="pricing-info-description">
        Achieve Your Dream Job: Practical Learning, 1-on-1 Mentorship, and a 6LPA+ Salary Secured
        </div>
        <hr className="pricing-info-content-separator" />
        <div className="pricing-info-feature-container">
          <div className="pricing-info-feature">Features Included:</div>
          {features.map(({title, subTitle}) => (
            <div className="pricing-info-feature">
              <span>
                <img
                  className="pricing-check-icon"
                  src="https://framerusercontent.com/images/0IM5CZrHPjymIMt0M4hQfVmharg.svg"
                />
                <span>
                {title}
                <p className="pricing-p">{subTitle}</p>
                </span>
              </span>
              <img
                className="pricing-info-icon"
                src="https://framerusercontent.com/images/hk21VWZrxkkqQvwa0rq5fPTw.svg"
              />              
            </div>
          ))}
        </div>
        {highlightMessage && <span className="pricing-info-highlighted-message">
          Use “FIRST100” code for 60% Discount
        </span>}
      </div>
    </div>
  );
};
