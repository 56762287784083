import React, { useEffect, useState } from "react";
import "./blog.scss"; // Make sure to create and style this file
import { LeftArrowIcon, ArrowUpRightIcon } from "../../icons";
import { ClapIcon } from "../../monorepoClient/icons/ClapIcon";
import one from "../../monorepoClient/assets/images/1.png"
import two from "../../monorepoClient/assets/images/2.png"
import three from "../../monorepoClient/assets/images/3.png"
import four from "../../monorepoClient/assets/images/4.png"
import five from "../../monorepoClient/assets/images/5.png"
import six from "../../monorepoClient/assets/images/6.png"
import seven from "../../monorepoClient/assets/images/7.png"
import eight from "../../monorepoClient/assets/images/8.png"
import webMasterDp from '../../monorepoClient/assets/images/webMastersDP.png'
import FlagIcon from "../../monorepoClient/assets/icons/FlagIcon";


interface Blog {
  title: string;
  description: string;
  author: string;
  readTime: string;
  image: any;
  authorImage: string;
  blogUrl: string;
}

const blogs: Blog[] = [
  {
    title: "CSS Pseudo-classes",
    description:
      "CSS pseudo-classes allow you to style elements based on their state, position, or user interactions like hover, focus, or checked status.",
    author: "webmasters",
    authorImage: webMasterDp,
    readTime: '617',
    image: one,
    blogUrl: "https://www.instagram.com/reel/C8xUtBTyY-5/?utm_source=ig_web_copy_link",
  },
  {
    title: "Virtual and Real DOM",
    description:
      "The virtual DOM is a lightweight copy of the real DOM, enabling faster updates by minimizing direct DOM manipulation.",
    author: "webmasters",
    readTime: "47",
    authorImage: webMasterDp,
    image:  two,
    blogUrl: "https://www.instagram.com/reel/C8wsv9fSFUD/",
  },
  {
    title: "HTML video Tag",
    description:
      "The HTML <video> tag embeds video files in a webpage, supporting controls like play, pause, and volume adjustments.",
    author: "webmasters",
    readTime: "123",
    authorImage:webMasterDp,
    image: three,
    blogUrl: "https://www.instagram.com/reel/C9Amt4aNBEF/?igsh=Y2pjYmN0Ym5qMnMw",
  },
  {
    title: "Create an Express.js Application",
    description:
      "An Express.js application creates a web server, handling routes, middleware, and requests for building web APIs or websites.",
    author: "webmasters",
    readTime: "296",
    authorImage:webMasterDp,
    image: four,
    blogUrl: "https://www.instagram.com/reel/C9ktMY0y4Cc/?igsh=YXRhdTBmMmc4bWh3",
  },
  {
    title: "Let variable declaration",
    description:
      "The let variable declaration in JavaScript allows block-scoped variables, preventing redeclaration within the same scope.",
    author: "webmasters",
    readTime: "28",
    authorImage: webMasterDp,
    image: five,
    blogUrl: "https://www.instagram.com/reel/C-w7pB7yqaN/?igsh=ODBpa2dkbmRrZG41",
  },
  { 
    title: "Arithmetic Operations in JavaScript",
    description:
      "Arithmetic operations in JavaScript include addition, subtraction, multiplication, division, and modulus, used for basic mathematical calculations.",
    author: "webmasters",
    readTime: "123",
    authorImage: webMasterDp,
    image: six,
    blogUrl: "https://www.instagram.com/reel/C9VC20-SPuN/?igsh=NXFtY3dlOG5xeHU2",
  },
  {
    title: "Module Scope in JavaScript",
    description:
      "Module scope in JavaScript restricts variables and functions to the file, preventing them from being accessible globally.",
    author: "webmasters",
    readTime: "19",
    authorImage: webMasterDp,
    image: seven,
    blogUrl: "https://www.instagram.com/reel/C-w7pB7yqaN/?igsh=ODBpa2dkbmRrZG41",
  },
  {
    title: "For In Loop in JavaScript",
    description:
      "The for...in loop in JavaScript iterates over enumerable properties of an object, returning property names or keys.",
    author: "webmasters",
    readTime: "15",
    authorImage: webMasterDp,
    image: eight,
    blogUrl: "https://www.youtube.com/shorts/cyt8313DtHE",
  },
];
interface BlogSectionProps {
  navRef: React.RefObject<HTMLDivElement>;
}

export const BlogSection: React.FC<BlogSectionProps> = ({ navRef }) => {
  const [visibleBlogs, setVisibleBlogs] = useState<Blog[]>([]);
  const [isViewAll, setISViewAll] = useState(false);
  const [buttonTitle, setButtonTitle] = useState('View All Blogs')

  useEffect(() => {
    if (isViewAll) {
      setVisibleBlogs(blogs)
      setButtonTitle('Hide')
    } else {
      const handleResize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 1200) {
          setVisibleBlogs(blogs.slice(0, 2));
        } else {
          setVisibleBlogs(blogs.slice(0, 3));
        }
      };
      handleResize(); // Set initial state
      window.addEventListener("resize", handleResize);
      setButtonTitle('View All Blogs')
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [isViewAll]);

  return (
    <div className="blog-section"
      ref={navRef}
    >
      <div className="blog-header">
        <div className="blog-icon">
          <FlagIcon/> Our Posts
          </div>
        <h2 className="blog-main-header">Get Job-Ready with Our Expert Tech Videos!</h2>
        <p className="blog-header-paragraph">
        Master In-Demand Skills and Accelerate Your Career Today!
        </p>
      </div>
      <div className="blog-grid">
        {visibleBlogs.map((blog, index) => (
          <a
            target="_blank"
            href={blog.blogUrl}
            key={index}
            className="blog-card"
            rel="noreferrer"
          >
            <div className="blog-card-mask-container"></div>
            <div className="blog-image-container">
              <img src={blog.image} alt={blog.title} className="blog-image" />
              <div className="open-blog-container">
                <span className="open-blog-text">Open</span>
                <span className="open-blog-arrow-icon">
                  <ArrowUpRightIcon />
                </span>
              </div>
            </div>
            <div className="blog-content-footer-container">
              <div className="blog-content">
                <h3 className="blog-title">{blog.title}</h3>
                <p className="blog-description">{blog.description}</p>
              </div>
              <div className="blog-content-dash-line"></div>
              <div className="blog-footer">
                <span className="blog-author-details">
                  <img
                    className="blog-author-image"
                    src={blog.authorImage}
                    alt=""
                  />
                  <div className="blog-author-name">{blog.author}</div>
                </span>
                <span className="blog-read-time"><ClapIcon/>{blog.readTime}</span>
              </div>
            </div>
          </a>
        ))}
      </div>

      <div className="blog-footer-button">
        <button className="view-all-blogs"
          onClick={()=>setISViewAll(!isViewAll)}
        >
          {buttonTitle}{' '}
          <div className="blog-arrow-icon">
            <LeftArrowIcon fillColor="#333333" />
          </div>
        </button>
      </div>
    </div>
  );
};
