import { io, Socket } from "socket.io-client";

import { useCallback, useEffect, useState } from "react";
import { socketEvents } from "../utils/constants";
import { useDispatch } from "react-redux";
import {
  addMessage,
  setMessageLoading,
} from "../redux/slices/discussion/discussionSlice";
import { getCookie } from "../utils/getCookie";

const useSocket = () => {
  const dispatch = useDispatch();
  const [socket, setSocket] = useState<Socket>();
  const [isConnected, setIsConnected] = useState(false);

  const sendGeneralMessage = useCallback(
    (socketData: SocketEventDataType) => {
      socket && socket.emit(socketEvents.GENERAL, socketData);
      dispatch(setMessageLoading(true));
    },
    [socket]
  );

  const generalListener = (socketData: SocketEventDataType) => {
    dispatch(addMessage(socketData));
    dispatch(setMessageLoading(false));
  };

  useEffect(() => {
    const _socket = io(process.env.REACT_APP_SOCKET_SERVER_URL || "", {
      auth: {
        token: getCookie(process.env.REACT_APP_JWT_SECRET_KEY || ""),
      },
    });
    _socket.on(socketEvents.CONNECT, () => {
      _socket.on(socketEvents.GENERAL, generalListener);
      setIsConnected(true)
    });
    _socket.on(socketEvents.DISCONNECT, () => {
      setIsConnected(false);
      socket?.disconnect()
      dispatch(setMessageLoading(false))
    })
    setSocket(_socket);
    return () => {
      if (_socket.connected) {
        _socket.off(socketEvents.GENERAL, generalListener);
        _socket.disconnect();
      }
      setSocket(undefined);
    };
  }, []);

  return { sendGeneralMessage, isConnected };
};

export default useSocket;