import * as React from "react";
export const PaperPlanIcon:  React.FC<SvgIconProps> = ({
    height = "35px",
    width = "35px",
    fillColor = "#4caf50",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12.7 12.7"
    id="svg8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs2">
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5343">
        <path
          d="m 3.0688351,285.55677 c -1.613568,1.19596 -2.5662181,3.08478 -2.56883509,5.09323 0,3.50701 2.84299219,6.35 6.35000009,6.35 C 10.357008,297 13.2,294.15701 13.2,290.65 c -0.0022,-0.84296 -0.452078,-2.71271 -0.500228,-2.45359 -0.02424,0.84121 -0.571809,2.35322 -0.602031,2.23138 -0.0026,-0.50621 -0.2876,-1.42626 -0.305098,-1.19509 0.02804,1.40695 -1.48374,3.17677 -2.3076623,3.50038 -0.8310457,0.36942 -1.7371637,0.56412 -2.6117267,0.56534 -0.1736918,2e-5 -1.1686519,-0.13807 -1.6443439,-0.70435 -0.826376,-0.89253 -0.00201,-1.09994 0.4625039,-1.27331 0.5055862,-0.18871 1.030887,-0.30969 1.5203212,-0.42013 0.7456988,-0.16826 0.4710618,-0.52626 0.1049028,-0.6289 -0.9266939,-0.25976 -2.044886,0.33543 -2.8654579,0.30593 -0.848406,-0.0291 -0.804618,-1.69621 -0.1405601,-1.76217 0.7394212,-0.0734 1.6665151,-0.51079 1.461779,-0.65673 -0.8303549,-0.61672 -0.9617369,-0.79081 -1.0595549,-1.16418 -0.1480201,-0.565 0.341282,-1.03557 0.05777,-0.96356 -0.2596169,0.08 -0.4110678,0.15641 -0.5988619,0.25715 -0.1672929,0.0897 -0.293814,0.0546 -0.6751841,0.002 -0.3249449,-0.045 -0.3870058,-0.0557 -0.3755009,-0.25673 0.0115,-0.20097 0.07295,-0.52125 -0.05223,-0.47648 z"
          id="path5345"
          style={{
            fill: "#7aafff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 2.01045942,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath4543">
        <path
          d="m 1.7760088,291.5696 c -1.82613339,0.17434 -3.2940315,0.91934 -3.4903996,2.16451 -0.2629054,1.66729 1.5678591,3.34127 4.0891213,3.73893 1.2172084,0.19119 2.434234,0.053 3.3807423,-0.38387 0.7310194,0.5283 1.669357,0.90078 2.6704721,1.06008 2.5212691,0.39771 4.7783381,-0.6315 5.0412801,-2.2988 0.262905,-1.6673 -1.490667,-1.94329 -4.257163,-2.17938 -2.7664958,-0.23609 -3.6163896,-1.68839 -5.7470448,-2.04575 -0.5660191,-0.0893 -1.1380808,-0.10817 -1.6870084,-0.0557 z"
          id="path4545"
          style={{
            opacity: 1,
            vectorEffect: "none",
            fill: "#666666",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 2.11666656,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
      </clipPath>
    </defs>
    <g id="layer1" transform="translate(0,-284.3)">
      <circle
        cx={6.3499999}
        cy={290.64999}
        id="path4514"
        r={6.3499999}
        style={{
          fill: fillColor,
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 1.74658811,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
      <g id="g4914" transform="translate(0.02302376,0.04129)">
        <path
          d="m 9.5402832,287.60483 -7.043837,3.87245 2.0020372,0.89615 0.2842075,1.66644 0.8201117,-1.10903 2.2149307,1.40687 z"
          id="path4609-2"
          style={{
            fill: "#000",
            fillOpacity: 1,
            fillRule: "evenodd",
            stroke: "none",
            strokeWidth: "0.07474231px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
          }}
        />
        <g
          id="g4842"
          transform="matrix(1.0147141,0,0,1.0147141,0.16685811,-4.2333039)"
        >
          <path
            d="m 34.423693,10.49624 -18.778671,17.761549 1.057134,6.207084 1.005707,-5.432432 1.071434,2.553497 z"
            id="path4609"
            style={{
              fill: "#cccccc",
              fillOpacity: 1,
              fillRule: "evenodd",
              stroke: "none",
              strokeWidth: "0.27839428px",
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeOpacity: 1,
            }}
            transform="matrix(0.26458333,0,0,0.26458333,0,284.3)"
          />
          <path
            d="m 9.1079353,287.07713 -4.9685232,4.69941 -1.9731683,-0.88341 z"
            id="path4607"
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "evenodd",
              stroke: "none",
              strokeWidth: "0.07365849px",
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeOpacity: 1,
            }}
          />
          <path
            d="m 4.6852054,291.9815 -0.2660933,1.43733 0.894331,-1.20897 z"
            id="path4605"
            style={{
              fill: "#999999",
              fillOpacity: 1,
              fillRule: "evenodd",
              stroke: "none",
              strokeWidth: "0.07365849px",
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeOpacity: 1,
            }}
          />
          <path
            d="m 4.6852054,291.9815 4.4227299,-4.90437 -1.6975093,6.63524 z"
            id="path4510-6"
            style={{
              opacity: 1,
              vectorEffect: "none",
              fill: "#ffffff",
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: 0.32897681,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeDashoffset: 15,
              strokeOpacity: 1,
              paintOrder: "stroke markers fill",
            }}
          />
        </g>
      </g>
    </g>
  </svg>
);