/**
 *
 * @param length - total length of the header
 * @param fillData - data to be filled in the header
 * @returns  - list of headers data with numbers
 */
const getHeader = (
    length: number,
    fillData: { title: string }
  ): Array<{ title: string }> =>
    Array(length)
      .fill(fillData)
      .map((headData, index) => ({
        title: `${headData?.title}${index + 1}`,
      }));
  
  /**
   * table may contain empty cells, which will not  be displayed.
   * this function is used to remove empty cells in table
   * and replace them with an empty object
   *
   */
  const fillEmptyCellsInTable = (
    goalTableData: Partial<UIGoalsType>[][],
    maxRows: number,
    maxColumns: number
  ) => {
    for (let rowIndex = 0; rowIndex <= maxRows; rowIndex += 1) {
      if (!goalTableData[rowIndex]) {
        goalTableData[rowIndex] = [];
      }
      for (let colIndex = 0; colIndex < maxColumns; colIndex += 1) {
        if (!goalTableData[rowIndex][colIndex]) {
          goalTableData[rowIndex][colIndex] = {};
        }
      }
      goalTableData[rowIndex] = goalTableData[rowIndex]?.slice(0,maxColumns);
    }
  };
  
  /** This function will find the next empty row for given column in the table  */
  const getNextEmptyColumnByRow = (
    goalTableData: Partial<UIGoalsType>[][],
    colIndex: number
  ): number => {
    let rowIndex = 0;
    while (
      goalTableData &&
      goalTableData[rowIndex] &&
      goalTableData[rowIndex][colIndex]
    ) {
      rowIndex += 1;
    }
    return rowIndex;
  };
  
  /** create an entry on given row and column to mark dublicate */
  const createEntryForDublicates = (
    goalTableData: Partial<UIGoalsType>[][],
    rowIndex: number,
    colIndex: number
  ) => {
    if (!goalTableData[rowIndex]) {
      goalTableData[rowIndex] = [];
    }
    goalTableData[colIndex][rowIndex] = {
      duplicate: true,
    };
  };
  
  /** create empty entry on given row and column in a table */
  const createEntryForEmptyCellsByRowAndColIndex = (
    goalTableData: Partial<UIGoalsType>[][],
    rowIndex: number,
    colIndex: number
  ) => {
    if (!goalTableData[colIndex]) {
      goalTableData[colIndex] = [];
    }
    if (!goalTableData[colIndex][rowIndex]) {
      goalTableData[colIndex][rowIndex] = {};
    }
  };
  
  /**
   * create empty and dublicate entries in a table
   * @param weekCount - used to check if the column is not more than week count
   * @returns - it returns the total number of rows placed in table
   */
  
  const createDublicateAndEmptyEntries = (
    goalTableData: Partial<UIGoalsType>[][],
    rowIndex: number,
    colIndex: number,
    weekCount: number
  ): number => {
    let tempRowIndex = 0,
      tempColIndex = 0;
    while (tempRowIndex < colIndex || tempColIndex < weekCount) {
      if (tempRowIndex < colIndex) {
        createEntryForEmptyCellsByRowAndColIndex(
          goalTableData,
          tempRowIndex,
          colIndex
        );
        tempRowIndex += 1;
      }
      if (tempColIndex < weekCount) {
        createEntryForDublicates(
          goalTableData,
          tempColIndex + rowIndex,
          colIndex
        );
        tempColIndex += 1;
      }
    }
    return tempRowIndex;
  };
  
  export const createTableDataByGoals = (goalsList: userGoalList[]): TableDataType => {
    let totalWeeks = 0;
    const goalTableData: Partial<UIGoalsType>[][] = [];
    const goalTableHeader: GoalsTableDataHeaderType[] = [];
    let maxRows = 0;
    goalsList.forEach((currentGoal) => {
      const { weekNumber: startWeekNumber } = currentGoal.goalId?.startWeek || {};
      const { weekNumber: endWeekNumber } = currentGoal.goalId?.endWeek || {};
      if (startWeekNumber && endWeekNumber && startWeekNumber > 0 && currentGoal.goalId?.isLive) {
        if(endWeekNumber) totalWeeks = Math.max(totalWeeks,endWeekNumber);
        const rowIndex = startWeekNumber - 1;
        const weekCount = endWeekNumber - startWeekNumber + 1
        let columnIndex = getNextEmptyColumnByRow(goalTableData, rowIndex);
        if (!goalTableData[columnIndex]) {
          goalTableData[columnIndex] = [];
        }
        let maxRowIndex = createDublicateAndEmptyEntries(
          goalTableData,
          rowIndex,
          columnIndex,
          weekCount
        );
        goalTableData[columnIndex][rowIndex] = { ...currentGoal, weekCount };
        maxRows = Math.max(maxRows, maxRowIndex);
      }
    });
    fillEmptyCellsInTable(goalTableData, maxRows, totalWeeks);
    goalTableHeader.push(...getHeader(totalWeeks, { title: "Week " }));
    return { header: goalTableHeader, rows: goalTableData };
  };
  