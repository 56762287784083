import React from 'react'

/*TODO: @dhananjay - this page is WIP, adding here for example  */

const HomePage = () => { 
  return (
    <div>HomePage</div>
  )
}

export default HomePage;