export const convertDateToString = (inputDate: Date): string =>
  new Date(inputDate).toDateString();
export const converDateToIsoString = (inputDate: Date): string =>
  new Date(inputDate)?.toDateString();

export const subtractDaysFromDate = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(date.getDate() - days);
  return result;
};
export const resetTimeToZero = (date = new Date()) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}
export const currentDateTime = new Date();