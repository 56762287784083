import { converDateToIsoString } from "../../utils/dateUtils";
import "./userGoalStatusCard.scss";

export const UserGoalStatusCard: React.FC<UserGoalStatusCardProps> = ({
  goalData,
  onClick=()=>{},
}) => {
  const id = goalData?._id;
  const statusData = {
    title: goalData?.goalId?.title,
    name: goalData?.userId?.name,
    batchCode: goalData?.userId?.batchCode,
    tagName: goalData?.currentGoalStatus?.status?.tagName,
    time: converDateToIsoString(goalData?.currentGoalStatus?.time) || "",
    startWeek: goalData?.goalId?.startWeek?.weekNumber,
    endWeek: goalData?.goalId?.endWeek?.weekNumber
  };
  return (
    <tr className="user-status-card" onClick={()=>onClick(id??"")}>
      <td className="content-text title">{statusData?.title}</td>
      <td className="content-text name">{statusData?.name}</td>
      <td className="content-text batchCode">{statusData?.batchCode}</td>
      <td className="content-text tagName">
        <span className="status-tag">{statusData?.tagName}</span>
      </td>
      <td className="content-text time">{statusData?.time}</td>
      <td className="content-text time">{statusData?.startWeek}</td>
      <td className="content-text time">{statusData?.endWeek}</td>
    </tr>
  );
};
