import Chart, { ChartConfiguration } from "chart.js/auto";
import { useEffect, useRef } from "react";
export const PieChart: React.FC<PieChartProps> = ({
  type,
  dataCount,
  backgroundColor,
  labels,
  height = "200px",
  width = "200px",
  className,
  containerClassName
}) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart<ChartType, number[], string> | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;

    const ctx = chartRef.current.getContext("2d");

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (ctx) {
      const defaultOptions: ChartConfiguration<
        ChartType,
        number[],
        string
      >["options"] = {
        responsive: true,
        plugins: {
          legend: {
            display: false // Hide legend
          },
          title: {
            display: false
          }
        }
      };

      const data = {
        labels: labels,
        datasets: [
          {
            data: dataCount,
            backgroundColor: backgroundColor
          }
        ]
      };

      // Create the new chart instance
      chartInstance.current = new Chart(ctx, {
        type: type,
        data: data,
        options: defaultOptions
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [type, dataCount, backgroundColor, labels]);

  return (
    <div className={`pie-chart-container ${containerClassName}`}>
      <canvas
        ref={chartRef}
        width={200}
        height={200}
        className={`${className}`}
      ></canvas>
    </div>
  );
};
