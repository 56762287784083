import { t } from "i18next";
import { AttemptedQuestionComponent } from "../../../components/attemptedQuestionComponent/attemptedQuestionComponent";
import NoDataFoundComponent from "../../../components/noDataFound/noDataFound";
import Spinner from "../../../components/spinner/spinner";
import { useBatch } from "../../../redux/actions/batchAction";
import { useQuestionAttempt } from "../../../redux/actions/questionAttemptAction";
import "./attemptedQuestion.scss";
import { useEffect, useState } from "react";

export const AttemptedQuestion: React.FC = () => {
  const { currentBatch } = useBatch();
  const { getAttemptedQuestion } = useQuestionAttempt();
  const [questionAttemptedData, setQuestionAttemptedData] = useState<
    QuestionAttemptedDataType[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const attemptData = async () => {
    setIsLoading(true);
    const data = await getAttemptedQuestion({
      batchCode: currentBatch?.batchCode ?? "",
    });
    setQuestionAttemptedData(
      data?.questionAttemptedData as QuestionAttemptedDataType[]
    );
    setIsLoading(false);
  };
  useEffect(() => {
    attemptData();
  }, [currentBatch.batchCode]);
  return (
    <div className="all-user-goal-status-container">
      <h2 className="user-goal-status-header">Attempted Questions</h2>
      <div className="goal-select-wrapper"></div>
      {isLoading ? (
        <Spinner />
      ) : questionAttemptedData?.length ? (
        <>
          <table className="goals-list-card-container">
            <thead className="goals-list-table-head">
              <th className="list-head title-head">Github Link</th>
              <th className="list-head name-head">Name</th>
              <th className="list-head question-type-head">Question Type</th>
              <th className="list-head status-head">Attempt Date</th>
              <th className="list-head batch-head">Total marks</th>
              <th className="list-head submission-head">Obtain Marks</th>
              <th className="list-head submission-head">Save Marks</th>
            </thead>
            <tbody className="goals-list-body">
              {questionAttemptedData?.map((data, index) => {
                const {
                  attemptDate,
                  marksObtained,
                  questionAttemptId,
                  userName,
                  prLink,
                  totalMarks,
                  questionType,
                  questionId
                } = data;
                return (
                  <AttemptedQuestionComponent
                    attemptDate={attemptDate}
                    marksObtained={marksObtained}
                    questionAttemptId={questionAttemptId}
                    userName={userName}
                    prLink={prLink}
                    totalMarks={totalMarks}
                    attemptData={attemptData}
                    questionType={questionType}
                    questionId={questionId}
                  />
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <NoDataFoundComponent
          className="no-data-found-container"
          height="200px"
          width="100%"
          message={"No data found"}
        />
      )}
    </div>
  );
};
