import { ChangeEvent, useState } from "react";
import { Button } from "../../components/button/button";
import "./attemptedQuestionComponent.scss";
import { useQuestionAttempt } from "../../redux/actions/questionAttemptAction";
import { useNavigate } from "react-router-dom";
export const AttemptedQuestionComponent: React.FC<
  QuestionAttemptedDataType
> = ({ attemptDate, totalMarks, questionAttemptId, userName, prLink,attemptData, questionType, questionId}) => {
  const { updateAttemptedQuestion } = useQuestionAttempt();
  const [isLoading, setIsLoading] = useState(false);
  const [obtainMarks, setObtainedMarks] = useState(0);
  const navigate = useNavigate();
  const onClick = async () => {
    try {
      setIsLoading(true);
      const updated = await updateAttemptedQuestion({
        attemptId: questionAttemptId,
        marksObtained: obtainMarks,
      });
      if (updated?.status === 200) {
         await attemptData();
        alert("Marks updated successfully");
      }
      else{
        alert("Failed to update marks");
      }
    } catch (error) {
        alert("Failed to update marks");
        console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const date = new Date(attemptDate).toLocaleDateString();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const marks = parseInt(e.target.value);
    if (marks < 0 || marks > totalMarks) {
      alert("Marks should be between 0 and total marks");
      return;
    }
    setObtainedMarks(marks);
  };
  const handleOnClickOfQuestionBody=() => {
    navigate(`/question-page/${questionId}`)
  }
  return (
    <tr className="user-status-card" onClick={handleOnClickOfQuestionBody}>
      <td className="content-text title" onClick={(e)=>e.stopPropagation()}>
        <a href={prLink} target="_blank">
          Github Link
        </a>
      </td>
      <td className="content-text name">{userName}</td>
      <td className="content-text question-type-body">{questionType}</td>
      <td className="content-text time">{date}</td>
      <td className="content-text batchCode">{totalMarks}</td>
      <td className="content-text time" onClick={(e)=>e.stopPropagation()}>
        <input
          onChange={handleOnChange}
          type="number"
          value={obtainMarks}
          className="input-obtained-marks"
        ></input>
      </td>
      <td className="content-text time" onClick={(e)=>e.stopPropagation()}>
        <Button
          className="save-marks"
          isLoading={isLoading}
          onClick={onClick}
          text="Save"
          isDisabled={!obtainMarks}
        />
      </td>
    </tr>
  );
};
