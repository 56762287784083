import { createSlice } from "@reduxjs/toolkit";

const initialState:GoalsDataStateType = {
  userGoalsList: [],
  userGoal: {},
  response: null,
  isGoalLoading: true,
  isUserGoalLoading: true,
};

export const UserGoalslice = createSlice({
  name: "userGoals",
  initialState: initialState,
  reducers: {
    setUserGoalsData: (state, action) => {
      const { userGoals, response } = action.payload;
      state.userGoalsList = userGoals;
      state.response = response;
    },
    setUserGoalData: (state, action) => {
      const { userGoalCompletion, response } = action.payload;
      state.userGoal = userGoalCompletion;
      state.response = response;
    },
    setIsGoalsDataLoading: (state, action) => {
      state.isGoalLoading = action.payload;
    },
    setIsUserGoalLoading: (state, action) => {
      state.isUserGoalLoading = action.payload;
    },
  },
});



export const { actions : userGoalsActions } = UserGoalslice;
export const selectUserGoals = (state: { userGoals : GoalsDataStateType}) => state.userGoals;
export default UserGoalslice.reducer;