import React from 'react'
import './discussionSidebar.scss';

const DiscussionSidebar: React.FC<DiscussionSidebarProps> = ({
    optionList = []
}) => {
  return (
    <div className='discussion-sidebar-container'>
      <div className='discussion-sidebar-sub-container'>
        <h3> Channels </h3>
        <div className='discussion-sidebar-options-container'>
            {
                optionList.map((option, index) => <div className='discussion-sidebar-option' key={index}>
                    { <option.icon /> }
                    <span>
                        { option.displayText }
                    </span>
                </div>)
            }
        </div>
      </div>
    </div>
  )
}

export default DiscussionSidebar;