import { useDispatch, useSelector } from "react-redux";
import { apolloClient } from "../../graphql/apolloClient/apolloClient";
import { actions, selectVideos } from "../slices/videos/videosSlice";
import { GET_VIDEOS } from "../../graphql/query/videos/getAllVideos";
import {
  topicSubtopicVideosCountData,
  videosTopicSubtopicAction,
} from "../slices/videos/topicSubtopicVideoCount";
import { GET_ALL_VIDEOS_BY_TOPIC_SUBTOPIC } from "../../graphql/query/videos/getAllVideosBySubtopics";
export const useVideos = () => {
  const dispatch = useDispatch();
  const videoData = useSelector(selectVideos);

  const getAllVideos = async ({
    title,
    description,
    dayNumber,
    weekNumber,
    videoNumber,
    topics,
    links,
    isActive,
    duration,
    tags,
  }: VideoDataType) => {
    try {
      dispatch(actions.setIsLoading(true));
      const response = await apolloClient.query({
        query: GET_VIDEOS,
        variables: {
          videoDataFilter: {
            title,
            description,
            weekNumber,
            dayNumber,
            videoNumber,
            topics,
            links,
            isActive,
            duration,
            tags,
          },
        },
      });
      dispatch(actions.setVideos(response.data.getAllVideos));
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(actions.setIsLoading(false));
    }
  };
  const videosCountData = useSelector(topicSubtopicVideosCountData);

  const getAllVideosByTopicSubtopicCount = async () => {
    const { setVidoesTopicSubtopic } = videosTopicSubtopicAction;
    try {
      const response = await apolloClient.query({
        query: GET_ALL_VIDEOS_BY_TOPIC_SUBTOPIC,
        variables: {},
      });
      dispatch(
        setVidoesTopicSubtopic(
          response?.data?.getAllVideosBySubtopics?.videoData || []
        )
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch videos topic subtopic", error);
    }
  };

  return { videoData, getAllVideos, getAllVideosByTopicSubtopicCount, videosCountData };
};
