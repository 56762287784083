import React, { useEffect } from "react";
import "./adminPage.scss";
import AllUserPaymentsList from "../allUsersPaymentsList/AllUsersPaymentsList";
import { CreateUserGoals } from "../createUserGoals/createUserGoals";
import { CreateQuestion } from "../createQuestion/createQuestion";
import { AllUsersGoalStatus } from "../allUsersGoalStatus/allUsersGoalStatus";
import { Select } from "../../../components";
import { useBatch } from "../../../redux/actions/batchAction";
import { AttemptedQuestion } from "../attemptedQuestion/attemptedQuestion"


const AdminPanel: React.FC = () => {
  const { batchData, getAllBatches, setCurrentBatchCode, currentBatch } = useBatch();
  const handleOnSetBatch = (option: SelectOptionType) => {
    setCurrentBatchCode({batchCode:option.text,_id: option.value});
  };

  useEffect(()=>{
    (async()=> await getAllBatches())();
  },[])
  return (
    <div className="admin-panel-container">
      <div className="create-goal-select-wrapper">
        <label htmlFor="batch-code" className="create-goal-label">
          Select batch code :
        </label>
        <Select
          className="create-goal-select"
          key={"batch-code"}
          data={batchData?.map(({batchCode,_id})=>({text: batchCode, value: _id ?? batchCode}))?? []}
          // defaultSelected={currentBatch.batchCode}
          onSelect={handleOnSetBatch}
          isRequired
        ></Select>
      </div>
      <CreateUserGoals />
      <CreateQuestion />
      <AllUserPaymentsList />
      <AllUsersGoalStatus />
      <AttemptedQuestion/>
    </div>
  );
};

export default AdminPanel;
