import { Tab } from "../../components/tab/tab";
import {
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import "./tabs.scss";

export const Tabs: React.FC<TabsProps> = (props: TabsProps) => {
  const { dataList, className="", style={}, children, onClick=()=>{}, selectedTabIndex, multi=false,disabled:tabDisable=false} = props;
  const [tabSwitchMap, setTabSwitchMap] = useState<Record<string, boolean>>({});
  const [tabSliderWidth, setTabSliderWidth] = useState<string>();
  const handleTabSwitch: MouseEventHandler<HTMLDivElement> = (e) => {
    const id = Number(e.currentTarget.id);
    if (!dataList[id].disabled) {
      if(multi && id!==0){
        let newTabSwitch = {...tabSwitchMap};
        newTabSwitch[id] = newTabSwitch[id] ? false : true;
        newTabSwitch && onClick(id,[...Object.entries(newTabSwitch)
        .filter(([key, val]) => val)
        .map(([key, val]) => Number(key))]);
        setTabSwitchMap(newTabSwitch);
      } else {
        setTabSwitchMap({ [id]: true });
        onClick(id);
      }
    }
  };
  const setDefaultSelected = () =>{
    const defaultSelectedObj : Record<number, boolean>= {};
    let isDefaultSelected = false;
    dataList?.forEach((val,idx)=>{
        if(val.selected) {
          defaultSelectedObj[idx] = val.selected;
          isDefaultSelected = true;
        }
    });
    if(!isDefaultSelected) {
      if(typeof selectedTabIndex !== "number") {
        if (selectedTabIndex) {
        (selectedTabIndex.length) ? 
        selectedTabIndex.map((idx)=>(defaultSelectedObj[idx] = true)) : 
        defaultSelectedObj[selectedTabIndex[0]] = true;
        }
      } else {
        defaultSelectedObj[0] = true 
      }
      
    } 
    setTabSwitchMap({...defaultSelectedObj});
  }
  const getXYOrigin : MouseEventHandler<HTMLDivElement> = (e)=>{
    setTabSliderWidth(String(e.clientX))
  }
  useEffect(()=>{
    setDefaultSelected();
  },[])
  return (
    <div className="tabs-main-container">
      <div className={`tabs-main-wrapper ${className}`} style={style}>
      {dataList.map((data, idx) => {
        const {text,value,disabled,selected,onClick=()=>{}, className="",selectedClass="",style={}} = data;
        return <Tab
          key={idx}
          index={idx}
          label={text ?? value}
          disabled={disabled || tabDisable}
          value={value}
          isSelected={(tabSwitchMap && tabSwitchMap[idx])}
          onClick={(e) => {
            getXYOrigin(e);
            handleTabSwitch(e);
            onClick(e);
          }}
          className={className}
          selectedClass={selectedClass}
          style={style}
        />
        })}
      </div>
      {children && <div className="tabs-children-wrapper">{children}</div>}
    </div>
  );
};
