import React from 'react';


export const TwitterLogo = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width='24'
    height='24'
    fill='currentColor'
  >
    <path d="M20.76,4.28l-6.42,7.62,6.66,8H15.57l-4.79-5.78L6,20H2.4l7-8.24L3.19,4h4.47L12,10.18,15.5,4Z"/>
  </svg>
  )
}





