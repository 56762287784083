import { ReactNode } from "react";
import "./userResponseCard.scss";
type UserResponseCardProps = {
  children?: ReactNode;
  className?: string;
  label: string;
};
export const UserResponseCard: React.FC<UserResponseCardProps> = ({
  children,
  className,
  label,
}: UserResponseCardProps) => {
  return (
    <div className={`user-response-card-data-content-wrapper ${className}`}>
      <span className="user-response-card-data-content user-response-card-content-title">{label}</span>
      {children && <span className="user-response-card-data-content"> {children} </span>}
    </div>
  );
};
