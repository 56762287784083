import { gql } from "@apollo/client";

export const GET_ALL_VIDEOS_BY_TOPIC_SUBTOPIC = gql`
  query GetAllVideosBySubtopics {
    getAllVideosBySubtopics {
      videoData {
        parentTagId
        subtopics {
          totalCount
          parentTagName
          parentTagId
          parentTagKey
          subTopicTagName
          subTopicTagId
          subTopicTagKey
        }
      }
      response {
        status
        message
      }
    }
  }
`;
