import "./createDsaQuestionComponent.scss";
import React, { ChangeEvent, TextareaHTMLAttributes, useState } from "react";
import { InputComponent } from "../input/inputComponent";
import { GithubFilesContainer } from "../githubFilesContainer/githubFilesContainer";
import { AddIcon } from "../../icons/addIcon";
import { CloseCrossIcon } from "../../icons/closeCrossIcon";
import { useDispatch, useSelector } from "react-redux";
import { createQuestionActions } from "../../redux/slices/createQuestion/createQuestionSlice";

export const CreateDSAQuestionComponent = ({
  DSAType = false,
}: CreateDSAQuestionComponentProps) => {
  const dispatch = useDispatch();
  const { updateState } = createQuestionActions;
  let files: Record<string, object> = {};
  const key = Date.now().toString();
  files[key] = { filename: "", fileContent: "" };
  const [filesData, setFilesData] = useState(files);
  const handleOnAddFiles = () => {
    const newKey = Date.now().toString();
    filesData[newKey] = { filename: "", fileContent: "" };
    setFilesData({ ...filesData });
  };
  const handleOnRemoveFiles = (key: string, index:number) => {
    const { [key]: _, ...newFilesData } = filesData;
    setFilesData(newFilesData);
  };
  const handleOnChangeIssueBody = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const path = `githubData.issueBody`;
    dispatch(updateState({ path, value: e.target.value }));
  };
  const handleOnSetIssueTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const path = `githubData.issueTitle`;
    dispatch(updateState({ path, value: e.target.value }));
  };
  const handleOnSetBranchName = (e: ChangeEvent<HTMLInputElement>) => {
    const path = `githubData.branchName`;
    dispatch(updateState({ path, value: e.target.value }));
  };
  return (
    <div className="create-dsa-question-container">
      {DSAType && (
        <div className="issue-title-body-container">
          <div className="issue-title-input-wrapper">
            <label className="issue-label">Issue Title :</label>
            <InputComponent
              className="create-issue-title-input"
              type="text"
              key={"title"}
              onChange={handleOnSetIssueTitle}
              placeholder="Issue title"
              disabled={false}
            />
          </div>
          <div className="issue-body-input-wrapper">
            <label className="issue-label">Issue Body :</label>
            <textarea
              rows={5}
              className="description-text-conatiner"
              onChange={handleOnChangeIssueBody}
            ></textarea>
          </div>
        </div>
      )}
      <div className="branch-files-container-wrapper">
        <div className="issue-title-input-wrapper">
          <label className="issue-label">Branch Name :</label>
          <InputComponent
            className="create-issue-title-input"
            type="text"
            key={"branch"}
            onChange={handleOnSetBranchName}
            placeholder="Branch Name"
            disabled={false}
          />
        </div>
        <div className="files-wrapper">
          {Object.entries(filesData).map(([key, value],index) => {
            return (
              <React.Fragment>
                <GithubFilesContainer prevPath={`githubData.files.${index}`} />
                <div
                  className="remove-file"
                  onClick={() => {
                    handleOnRemoveFiles(key,index);
                  }}
                >
                  <span className="file-remove-icon">
                    <CloseCrossIcon fillColor="#000" />
                  </span>
                </div>
                <h1 className="break-line"></h1>
              </React.Fragment>
            );
          })}
          <div className="add-files-container">
            <span onClick={handleOnAddFiles} className="add-button">
              <AddIcon height="30" width="30" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
