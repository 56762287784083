import { Dispatch, SetStateAction } from "react";
import { questionFilterTagTypes } from "./constants";

export const questionTags : string[] = Object.values(questionFilterTagTypes);

const { 
  QuestionDifficultyLevel, QuestionPlatformType,
  QuestionUsageCategory, SubTopic, Topic,
} = questionFilterTagTypes;
const fillData = (
  filterData: QuestionFilterKeyType,
  tagType: string,
  tagsData: { [key: string]: TagsSchemaType[] },
  defaultVal?: TabOptionsType
) => {
  if (filterData[tagType] && tagsData[tagType]) {
    filterData[tagType].dataList = tagsData[tagType]?.map(
      (data: TagsSchemaType) => ({
        value: data.tagKey,
        text: data.tagName,
        tagType: data.tagType,
      })
    );
    if (defaultVal) {
      (filterData[tagType].dataList as TabOptionsType[])?.unshift(defaultVal);
    }
  }
};

export const handleFilterDataState = (tagsData: {
  [key: string]: TagsSchemaType[];
}) => {
  const newFilterData: QuestionFilterType = {
    QuestionUsageCategory: { dataList: [] },
    Topic: { dataList: [] },
    QuestionDifficultyLevel: { dataList: [] },
    SubTopic: { dataList: {} },
  };
  const defaultQuestionCategory = {
    value: "allCategory",
    text: "All Category",
    tagType: QuestionUsageCategory,
  };
  const defaultTopic = {
    value: "allTopics",
    text: "All Topics",
    tagType: Topic,
  };
  fillData(
    newFilterData,
    QuestionUsageCategory,
    tagsData,
    defaultQuestionCategory
  );
  fillData(newFilterData, Topic, tagsData, defaultTopic);
  fillData(newFilterData, QuestionDifficultyLevel, tagsData);

  if (newFilterData.SubTopic && tagsData?.SubTopic) {
    tagsData.SubTopic?.forEach((data: TagsSchemaType) => {
      const SubTopicData = {
        ...data,
        value: data.tagKey,
        text: data.tagName,
        tagType: data.tagType,
      };
      if (data.parentTagId && data.parentTagId.tagKey) {
        const parentTagKey = data.parentTagId.tagKey;
        if (newFilterData.SubTopic && newFilterData.SubTopic.dataList) {
          if (newFilterData.SubTopic.dataList[parentTagKey]) {
            newFilterData.SubTopic.dataList[parentTagKey]?.push(SubTopicData);
          } else {
            newFilterData.SubTopic.dataList[parentTagKey] = [SubTopicData];
          }
        }
      }
    });
    newFilterData.SubTopic.dataList["allTopics"] = tagsData.SubTopic?.map(
      (data: TagsSchemaType) => ({
        ...data,
        value: data.tagKey,
        text: data.tagName,
        tagType: data.tagType,
      })
    );
  }
  return newFilterData;
};

export const handleQuestionFilterMap = (
  questionFilterMap: Record<string, string[]>,
  tagId: string,
  tagType: string,
  setSelectedCategoryTagIndex?: Dispatch<SetStateAction<number[]>>,
  setSelectedTopicTagIndex?: Dispatch<SetStateAction<number[]>>,
  idx?: number
) => {
  const newQuestionFilterMap: Record<string, string[]> = {
    ...questionFilterMap,
  };
  if (idx === 0) {
    if (tagType === QuestionUsageCategory) {
      newQuestionFilterMap[tagType] = [];
      setSelectedCategoryTagIndex && setSelectedCategoryTagIndex([0]);
    } else if (tagType === Topic) {
      newQuestionFilterMap[tagType] = [];
      setSelectedTopicTagIndex && setSelectedTopicTagIndex([0]);
    }
  } else {
    if (tagId && newQuestionFilterMap[tagType]) {
      const filterSet = new Set(newQuestionFilterMap[tagType]);
      if (filterSet.has(tagId)) {
        filterSet.delete(tagId);
      } else filterSet.add(tagId);
      newQuestionFilterMap[tagType] = Array.from(filterSet.values());
    } else {
      newQuestionFilterMap[tagType] = [tagId];
    }
  }
  return newQuestionFilterMap;
};

export const getTagIdByTagTypeAndKey = (
  tagsData: { [key: string]: TagsSchemaType[] },
  key: string,
  type: string
): string => {
  let tagId = "";
  for (let tag of tagsData[type]) {
    if (tag.tagKey === key) return tag._id ?? '';
  }
  return tagId;
};
