import "./rejectGoalFeedback.scss";
import { CloseCrossIcon } from "../../icons/closeCrossIcon";
import { useRef } from "react";
import useOnOutsideClick from "../../hooks/useOnOutsideClick";
import RichTextEditor from "../richTextEditor/richTextEditor";
import { Button } from "../button/button";
export const RejectGoalFeedback: React.FC<RejectGoalFeedbackComponentProps> = ({
  onClose = () => {},
  handleOnRejectGoal = () => { },
  isLoading = false,
  onChange,
  value
}) => {
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  useOnOutsideClick(tooltipRef, onClose);
  return (
    <div
      ref={tooltipRef}
      onClick={(e) => e.stopPropagation()}
      className="reject-goal-feedback-container"
    >
      <div className="close-button" onClick={onClose}>
        <CloseCrossIcon fillColor="#000" height="25" width="25" />
      </div>
      <div className="goal-feedback-input-wrapper">
        <label className="feedback-label">Enter Goal Feedback :</label>
        <RichTextEditor onChange={onChange} value="" />
      </div>
      <Button
        className="reject-button"
        isLoading={isLoading}
        iconPosition="center"
        text="Reject"
        onClick={handleOnRejectGoal}
      />
    </div>
  );
};
