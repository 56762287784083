import React, { useContext, useState } from "react";
import "./CoursePage.scss";
import TextComponent from "../TextComponent/TextComponent";
import ImageComponent from "../ImageComponent/ImageComponent";
import RightArrowBlack from "../../assets/images/rightArrowBlack.svg";
import RightArrow from "../../assets/images/rightArrow.svg";
import RightSubscriptionCircle from "../../assets/images/rightSubscriptionCircle.svg";
import leftSubscriptionCircle from "../../assets/images/leftSubscriptionCircle.svg";
import { Link } from "react-router-dom";
import { ThemeContext } from "../Themecontext/ThemeContext";
import { googleAnalyticsButton } from "../../helpers/utils/googleAnalyticsButton";
import { CourseInfoCard } from "../CourseInfoCard/CourseInfoCard";
import jsIcon from "../../assets/images/javascriptIcon.svg";
import reactIcon from "../../assets/images/reactIcon.svg";
import cssIcon from "../../assets/images/cssIcon.svg";
import reduxIcon from "../../assets/images/reduxIcon.svg";
import { FLagIcon } from "../../../icons/flagIcon";
import ApplyNowButton from "../NewComponents/ApplyNowButton/ApplyNowButton";
import Glassmorphism from "../Glassmorphism/Glassmorphism";

const CoursePage = ({ navRef }) => {
  const { darkMode } = useContext(ThemeContext);
  const [currentWeek, setCurrentWeek] = useState(0);


  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const dayInfo = [
    "Set up your local machine and start with basic HTML and adding CSS to it.",
    "Complete a basic UI setup and see your website in action on your local machine.",
    "Let's get you started with Git, Github and help you deploy your own, (probably the very first) website.",
    "Let's take your website to next level by adding HTML Meta tags, and CSS Animations to it.",
    "Your website should look like a mobile app when users view it on mobile devices."
  ];

  const weeksTopicsList = [
    {
      weekNumber: "Week 1",
      topicName: "HTML: Basic to intermediate",
      iconUrl: cssIcon,
      dayInfo: shuffleArray(dayInfo)
    },
    {
      weekNumber: "Week 2",
      topicName: "ADVANCED CSS LAYOUTS, ACCESSIBILITY AND SEO",
      iconUrl: cssIcon,
      dayInfo: shuffleArray(dayInfo)
    },
    {
      weekNumber: "Week 3",
      topicName: "JAVASCRIPT BASIC SYNTAXES",
      iconUrl: jsIcon,
      dayInfo: shuffleArray(dayInfo)
    },
    {
      weekNumber: "Week 4",
      topicName: "JAVASCRIPT ADVANCED STRUCTURES",
      iconUrl: jsIcon,
      dayInfo: shuffleArray(dayInfo)
    },
    {
      weekNumber: "Week 5",
      topicName: "JAVASCRIPT UNDER THE HOOD",
      iconUrl: jsIcon,
      dayInfo: shuffleArray(dayInfo)
    },
    {
      weekNumber: "Week 6",
      topicName: "BIG O' NOTATION AND DATA STRUCTURES",
      iconUrl: jsIcon,
      dayInfo: shuffleArray(dayInfo)
    },
    {
      weekNumber: "Week 7",
      topicName: "DATA STRUCTURES AND ALGORITHMS IN JAVASCRIPT",
      iconUrl: cssIcon,
      dayInfo: shuffleArray(dayInfo)
    },
    {
      weekNumber: "Week 8",
      topicName: "ADVANCED JAVASCRIPT ALGORITHMS",
      iconUrl: cssIcon,
      dayInfo: shuffleArray(dayInfo)
    },
    {
      weekNumber: "Week 9",
      topicName: "'REACT' LIKE PROFESSIONALS",
      iconUrl: reactIcon,
      dayInfo: shuffleArray(dayInfo)
    },
    {
      weekNumber: "Week 10",
      topicName: "REDUX AND NEXT",
      iconUrl: reduxIcon,
      dayInfo: shuffleArray(dayInfo)
    },
    {
      weekNumber: "Week 11",
      topicName: "NODEJS AND EXPRESS, APIS",
      iconUrl: cssIcon,
      dayInfo: shuffleArray(dayInfo)
    },
    {
      weekNumber: "Week 12",
      topicName: "DATABASES: MONGODB AND POSTGRESQL",
      iconUrl: cssIcon,
      dayInfo: shuffleArray(dayInfo)
    },
    {
      weekNumber: "Week 13 ... Week 16",
      topicName: "CAPSTONE PROJECTS, MOCK INTERVIEWS, RESUME, JOB APPLICATIONS",
      iconUrl: cssIcon,
      dayInfo: shuffleArray(dayInfo)
    },
  ];

  const googleAnalyticsButtonObj = {
    CoursePageApplyButton: {
      action: "CoursePageApplyButton",
      label: "clicked on course page apply now button",
    },
  };

  return (
    <div
      ref={navRef}
      id="programs"
      className={darkMode ? "faqComponent faqComponentBlack" : "faqComponent faqComponentWhite"}
    >
      <ImageComponent
        className="rightSubscriptionCircle"
        src={RightSubscriptionCircle}
        alt="rightsubscrtiptionCircle"
      />
      <ImageComponent
        className="leftSubscriptionCircle"
        src={leftSubscriptionCircle}
        alt="LeftSubscriptionCircle"
      />
      <div className="tutorial-icon-container">
        <FLagIcon />
        Course
      </div>
      <h1 style={{ textAlign: "center" }}>Full Stack (MERN) Development</h1>
      <div className="div">
        <div className="demo-week">
          <TextComponent id="demo-week" className="demoWeekDetails">
            <div className="demo-week-image-number">
              <div className="week-number-container">{weeksTopicsList[currentWeek].weekNumber}</div>
            </div>
          </TextComponent>
          <div className="demoWeekInfos">
            <div className="demo-weel-info-wrapper">
              {currentWeek === 0 ? ''
                : <Glassmorphism
                classname="demoWeekInfos-glassmorphism"
                component = {<ApplyNowButton/>}
                text = "To Know More"
                textclassname= "demoWeekInfos-glassmorphism-text"
                />
                }
              <ul>
                {weeksTopicsList[currentWeek].dayInfo?.map((info, index) => (
                  <div className="demoWeekDayInfoWrapper" key={index}>
                    <li><span className="demoWeekDayInfo">{info}</span></li>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="paid-week">
          <div className="paid-week-div-2">
            {weeksTopicsList.map(({ weekNumber, topicName, iconUrl }, idx) => (
              <CourseInfoCard
                title={weekNumber}
                description={topicName}
                key={`week-${idx}`}
                url={iconUrl}
                onClick={() => setCurrentWeek(idx)}
                active={currentWeek === idx}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePage;
