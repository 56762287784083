import { handleFilterDataState } from "@utils/questionFilterUtils";
import * as React from "react";
const WebMastersLogo:React.FC<SvgIconProps> = ({
    height = "100%",
    width = "100%",
    isDarkMode,
    fillColor = "",
  }: SvgIconProps) => (
    <svg width={width} height={height} viewBox="0 0 549 289" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* <!-- Red "W" Path --> */}
    <path d="M163 184V0H193.5V76L239 0H270L293.5 39.5L318 0H352.5L293.5 99L254.5 34L163 184Z" fill="#ED303C"/>
    
    {/* <!-- Black "M" Path --> */}
    <path d="M256 83L196.5 183H232L256 142.5L281.5 183H310.5L357 104V183H387V0L296.5 151.5L256 83Z" fill="black" />
  </svg>
  
    
);
export default WebMastersLogo;
