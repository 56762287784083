import { createSlice } from "@reduxjs/toolkit";
import { resetTimeToZero } from "../../../utils";

const initialState: DiscussionGlobalStateType = {
  messageList: [],
  isMessageLoading: false,
};

export const discussionSlice = createSlice({
  name: "discussion",
  initialState: initialState,
  reducers: {
    setMessageLoading: (state, action) => {
      state.isMessageLoading = action.payload;
    },
    addMessage: (state, action) => {
      const lastDay = state.messageList[state.messageList.length - 1];
      const currentDateTime = new Date();
      const currentDate = resetTimeToZero(currentDateTime);
      if (!lastDay) {
        state.messageList = [
          ...state.messageList,
          {
            date: currentDateTime,
            messages: [
              {
                ...action?.payload,
              },
            ],
          },
        ];
      } else if (lastDay) {
        if (
          currentDate?.toISOString() >
          resetTimeToZero(lastDay?.date).toISOString()
        ) {
          state.messageList = [
            ...state.messageList,
            {
              date: currentDateTime,
              messages: [
                {
                  ...action?.payload,
                },
              ],
            },
          ];
        } else {
          state.messageList = [
            ...state.messageList.slice(0, state.messageList.length - 2),
            {
              date: lastDay.date,
              messages: [
                ...lastDay.messages,
                {
                  ...action?.payload,
                },
              ],
            },
          ];
        }
      }
    },
    updateMessageList: (state, action) => {
      state.messageList = action?.payload || []
    }
  },
});

export const { addMessage, setMessageLoading, updateMessageList } = discussionSlice.actions;
export const selectMessageList = (state: DiscussionGlobalStateType) =>
  state.messageList;
export default discussionSlice.reducer;