import { Select } from "../../components/select/select";
import { InputComponent } from "../../components/input/inputComponent";
import "./createQuestionComponent.scss";
import React, { useEffect, useState } from "react";
import Accordion from "../accordion/accordion";
import { Options } from "../options/options";
import { CloseCrossIcon, AddIcon } from "../../icons/index";
import { useDispatch, useSelector } from "react-redux";
import { createQuestionActions } from "../../redux/slices/createQuestion/createQuestionSlice";
import { Button } from "../../components/button/button";
import { createQuestionApi } from "../../redux/actions/admin/createQuestion";
import { useBatch } from "../../redux/actions/batchAction";
import { getBatches } from "../../utils/getBatches";
import { Checkbox } from "../checkbox/checkbox";
import { useTags } from "../../redux/actions/tagsAction";
import { CreateDSAQuestionComponent } from "../createDsaQuestionComponent/createDsaQuestionComponent";
import { useWeek } from "../../redux/actions/scheduleAction";
export const CreateQuestionComponent: React.FC<
  CreateQuestionComponentProps
> = ({ onClose }) => {
  const QUESTION_TYPE_TAGS = "questionTypeTags";
  const selectedBoolean = [
    {
      text: "true",
      value: "true",
    },
    {
      text: "false",
      value: "false",
    },
  ];
  const type = [
    {
      text: "timed",
      value: "timed",
    },
    {
      text: "recorded",
      value: "recorded",
    },
  ];
  const descriptionTypeList = [
    { text: "text", value: "text" },
    { text: "html", value: "html" },
  ];
  const { getBatchCode } = useBatch();
  const { getAllTags, tags } = useTags();
  const { tagsData } = tags || {};
  const { getWeekList } = useWeek();
  const [subTopicList, setSubTopicList] = useState<CheckboxValueType[]>([]);
  const [batchList, setBatchList] = useState<SelectOptionType[]>([]);
  const [selectedQuestionType, setSelectedQuestionType] = useState<string>("");
  const [isQuestionAdding, setIsQuestionAdding] = useState<boolean>(false);
  const [weeksMap, setWeeksListMap] = useState({});
  const [daysList, setDaysList] = useState<SelectOptionType[]>();
  const [count, setCount] = useState({
    titleCount: 1,
    answerCount: 1,
    optionsCount: 1,
  });
  const [ batchCode,setBatchCode ] = useState<string>("");
  const [weeksListData, setWeekListData] = useState<SelectOptionType[]>([]);
  const variableList: SelectOptionType[] = tagsData?.QuestionUsageCategory?.map(
    (listData) => {
      return {
        text: listData?.tagName || "",
        value: listData?._id || "",
      };
    }
  );
  const questionType: SelectOptionType[] = tagsData?.QuestionPlatformType?.map(
    (element) => {
      return { text: element.tagKey, value: element._id ?? "" };
    }
  );
  const topicList: CheckboxValueType[] = tagsData?.Topic?.map((item) => {
    return {
      text: item.tagName,
      value: item._id,
    };
  });
  const getBatchCodeList = async () => {
    const response = await getBatchCode();
    const { batches } = getBatches(response?.data?.getBatchCode?.batchData);
    setBatchList(batches);
  };
  const getWeekListData = async () => {
    const weeksMap: Record<string, WeekListType> = {};
    const { weekList } = await getWeekList();
    const weeks: WeekListType[] = weekList as WeekListType[];
    const weeksList: SelectOptionType[] = [];
    weeks.forEach((weekItem) => {
      weeksMap[weekItem?.weekNumber] = weekItem;
      weeksList.push({
        text: weekItem?.title,
        value: weekItem?.weekNumber,
      });
    });
    setWeekListData(weeksList);
    setWeeksListMap(weeksMap);
  };
  useEffect(() => {
    getBatchCodeList();
    getAllTags();
    getWeekListData();
  }, []);

  const getWeekLists = async (batchCode: string) => {
    const { weekList } = await getWeekList(batchCode);
    const weekData = weekList as WeekListType[];
    const weeksMap: Record<string, WeekListType> = {};
    setWeekListData(
      weekData.map((week) => {
       weeksMap[week?.weekNumber] = week;
        return { text: week.title, value: week.weekNumber };
      })
    );
    setWeeksListMap(weeksMap);
  };

  useEffect(()=>{
    getWeekLists(batchCode??'');
  },[batchCode]);
  const { createQuestion: createdQuestionData } = useSelector(
    (state): any => state
  );
  const { updateState } = createQuestionActions;
  const dispatch = useDispatch();

  const subTopicMap: Record<string, CheckboxValueType[]> = {};
  tagsData?.SubTopic?.forEach((subtopic) => {
    if (subtopic?.parentTagId?._id && subTopicMap[subtopic?.parentTagId?._id]) {
      const option: CheckboxValueType = {
        text: subtopic?.tagName,
        value: subtopic?._id || "",
      };
      subTopicMap[subtopic?.parentTagId?._id] = [
        ...subTopicMap[subtopic?.parentTagId?._id],
        option,
      ];
    } else if (subtopic?.parentTagId?._id) {
      const option: CheckboxValueType = {
        text: subtopic?.tagName,
        value: subtopic?._id || "",
      };
      subTopicMap[subtopic?.parentTagId?._id || ""] = [option];
    }
  });
  const handleOnSetBatchCode = (option: SelectOptionType) => {
    const value = option.text;
    const path = `meta.batchCode`;
    setBatchCode(option.text);    
    dispatch(updateState({ path, value }));
  };
  const handleOnSetDayNumber = (option: SelectOptionType) => {
    const value = Number(option.value);
    const path = `meta.day`    
    dispatch(updateState({ path, value }));
  };
  const handleOnSetWeekNumber = (option: SelectOptionType) => {
    const value: string = option.value;    
    const weeksNumberMap: Record<string, WeekListType> = weeksMap;
    const days: DaysType[] = weeksNumberMap[value]?.days;
    const daysList: SelectOptionType[] = days?.map((day) => {
      return {
        text: day?.dayNumber?.toString(),
        value: day?.dayNumber?.toString(),
      };
    });    
    setDaysList(daysList);
    const path = `meta.week`;
    dispatch(updateState({ path, value:Number(value) }));
  };

  const handleOnSetTopic = (
    currentSelected: {},
    selectedValues: Record<number, CheckboxValueType>
  ) => {
    const values = Object.values(selectedValues);
    if (!values?.length) setSubTopicList([]);
    const topics: string[] = [];
    const subTopics: any = [];
    values.forEach((item) => {
      topics.push(item?.value as string);
      const findSubTopic = subTopicMap[item?.value || ""];
      if (findSubTopic?.length) {
        subTopics.push(findSubTopic);
      }
    });
    dispatch(updateState({ path: `questionTopics`, value: topics }));
    setSubTopicList(subTopics?.flat(1));
  };
  const handleOnSetSubTopic = (
    currentSelected: {},
    selectedValues: Record<number, CheckboxValueType>
  ) => {
    const selectedSubTopicList = Object.values(selectedValues);
    const values = selectedSubTopicList.map((item) => {
      return item?.value;
    });
    dispatch(updateState({ path:`questionSubTopics`, value: values }));
  };
  const handleOnSetExpiresTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    const path = `meta.expiresInMins`;
    dispatch(updateState({ path, value }));
  };
  const handleOnSetType = (option: SelectOptionType) => {
    const value = option.value;
    const path = `meta.type`;
    dispatch(updateState({ path, value }));
  };
  const handleOnSetIsActive = (option: SelectOptionType) => {
    const value = option.value === "true";
    const path = `meta.isActive`;
    dispatch(updateState({ path, value }));
  };
  const handleOnSetIsArchived = (option: SelectOptionType) => {
    const value = option.value === "true";
    const path = `meta.isArchived`;
    dispatch(updateState({ path, value }));
  };
  const handleOnSetIsOpenable = (option: SelectOptionType) => {
    const value = option.value === "true";
    const path = `meta.isOpenable`;
    dispatch(updateState({ path, value }));
  };
  const handleOnSetMarks = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    const path = `marks`;
    dispatch(updateState({ path, value }));
  };
  const handleOnSetQuestionType = (option: SelectOptionType) => {
    const value = option.value;
    const path = `questionType`;
    setSelectedQuestionType(option.text.toLowerCase());
    dispatch(updateState({ path, value }));
  };
  const handleOnSetQuestionTypeTags = (
    currentSelected: {},
    selectedValues: Record<number, CheckboxValueType>
  ) => {
    const selectedQuestionTypeTags: string[] = Object.values(
      selectedValues
    )?.map((tag) => tag?.value || "");
    dispatch(updateState({ path:`questionUsageCategory`, value: selectedQuestionTypeTags }));
  };
  const handleOnSetDescriptionType = (option: SelectOptionType) => {
    const value = option.value;
    const path = `description.type`;
    dispatch(updateState({ path, value }));
  };
  const handleOnDescriptionValue = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    const path = `description.value`;
    dispatch(updateState({ path, value }));
  };
  const optionsList: JSX.Element[] = [];
  const titleList: JSX.Element[] = [];
  const answerList: JSX.Element[] = [];

  for (let index = 0; index < count.optionsCount; index++) {
    optionsList.push(
      <Options
        prevPath={`options.${index}`}
        optionQuestionType={selectedQuestionType}
      />
    );
  }
  for (let index = 0; index < count.titleCount; index++) {
    titleList.push(
      <Options
        prevPath={`title.${index}`}
        optionQuestionType={selectedQuestionType}
      />
    );
  }
  for (let index = 0; index < count.answerCount; index++) {
    answerList.push(
      <Options
        prevPath={`answer.${index}`}
        optionQuestionType={selectedQuestionType}
      />
    );
  }
  const handleOnAddTitleClick = () => {
    setCount({ ...count, titleCount: count.titleCount + 1 });
  };
  const handleOnAddAnswerClick = () => {
    setCount({ ...count, answerCount: count.answerCount + 1 });
  };
  const handleOnAddOptionsClick = () => {
    setCount({ ...count, optionsCount: count.optionsCount + 1 });
  };
  const handleOnAddQuestion = async () => {
    try {
      const {
        answer=[],
        options=[],
        meta,
        questionType,
        title,
        marks,
        questionUsageCategory=[],
        questionSubTopics=[],
        questionTopics=[],
        description,
        githubData,
      } = createdQuestionData as CreateQuestionInterface;
      const tags = [
        ...questionUsageCategory,
        ...questionSubTopics,
        ...questionTopics,
        questionType,
      ];
      const { createQuestion } = createQuestionApi();
      setIsQuestionAdding(true);
      const response = await createQuestion({
        answer: answer,
        marks: marks,
        meta: meta,
        options: options,
        questionType: questionType,
        title: title,
        description,
        tags,
        githubData,
      });
      setIsQuestionAdding(false);
      const message = response?.response?.message;
      const status = response?.response?.status;
      if (status === 200) {
        window.alert(message);
      } else {
        window.alert(message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="create-question-component-wrapper">
      <div className="close-button" onClick={onClose}>
        <CloseCrossIcon />
      </div>
      <h1 className="create-question-component-header">Add Question</h1>
      <h3>Meta</h3>
      <div className="question-meta-container">
        <div className="create-question-input-wrapper">
          <label htmlFor="batch-code" className="create-question-label">
            Batch Code:
          </label>
          <Select
            className="create-question-select"
            key={"batchCode"}
            data={batchList}
            onSelect={handleOnSetBatchCode}
          ></Select>
        </div>
        <div className="create-question-input-wrapper">
          <label htmlFor="batch-code" className="create-question-label">
            Week :
          </label>
          <Select
            className="create-question-select"
            key={"weekNumber"}
            data={weeksListData}
            isRequired
            onSelect={handleOnSetWeekNumber}
          ></Select>
        </div>
        <div className="create-question-input-wrapper">
          <label htmlFor="batch-code" className="create-question-label">
            Day :
          </label>
          <Select
            className="create-question-select"
            key={"dayNumber"}
            data={daysList}
            isDisabled={!daysList?.length}
            isRequired
            onSelect={handleOnSetDayNumber}
          ></Select>
        </div>
        <div className="create-question-input-wrapper">
          <label htmlFor="batch-code" className="create-question-label">
            Expires in min:
          </label>
          <InputComponent
            className="create-question-input"
            type="number"
            key={"expiresTime"}
            onChange={handleOnSetExpiresTime}
            placeholder="Expires Time"
          />
        </div>
        <div className="create-question-input-wrapper">
          <label htmlFor="batch-code" className="create-question-label">
            Active status :
          </label>
          <Select
            className="create-question-select"
            key={"isActive"}
            data={selectedBoolean}
            isRequired
            onSelect={handleOnSetIsActive}
          ></Select>
        </div>
        <div className="create-question-input-wrapper">
          <label htmlFor="batch-code" className="create-question-label">
            Archived status :
          </label>
          <Select
            key={"isArchived"}
            className="create-question-select"
            data={selectedBoolean}
            isRequired
            onSelect={handleOnSetIsArchived}
          ></Select>
        </div>
        <div className="create-question-input-wrapper">
          <label htmlFor="batch-code" className="create-question-label">
            Openable status :
          </label>
          <Select
            className="create-question-select"
            key={"isOpenable"}
            data={selectedBoolean}
            isRequired
            onSelect={handleOnSetIsOpenable}
          ></Select>
        </div>
        <div className="create-question-input-wrapper">
          <label htmlFor="batch-code" className="create-question-label">
            Type :
          </label>
          <Select
            className="create-question-select"
            key={"selcect-type"}
            data={type}
            isRequired
            onSelect={handleOnSetType}
          ></Select>
        </div>
      </div>
      <div className="break-line"></div>
      <div className="marks-questionType-wrapper">
        <div className="create-question-input-wrapper">
          <label htmlFor="batch-code" className="create-question-label">
            Marks :
          </label>
          <InputComponent
            className="create-question-input"
            type="number"
            key={"marks"}
            onChange={handleOnSetMarks}
            placeholder="Marks"
            disabled={false}
          />
        </div>
        <div className="create-question-input-wrapper">
          <label htmlFor="batch-code" className="create-question-label">
            Question Platform Type :
          </label>
          <Select
            className="create-question-select"
            key={"question-type"}
            defaultSelected="Question Platform Type"
            data={questionType}
            isRequired
            onSelect={handleOnSetQuestionType}
          ></Select>
        </div>
        <div className="create-question-input-wrapper">
          <label htmlFor="batch-code" className="create-question-label">
            Question Usage Category :
          </label>
          <Accordion
            title="Select Usage"
            className="select-accordian-container"
          >
            <Checkbox
              className="checkbox-container"
              options={variableList}
              type="multi"
              onSelect={handleOnSetQuestionTypeTags}
            />
          </Accordion>
        </div>
        <div className="create-question-input-wrapper">
          <label htmlFor="batch-code" className="create-question-label">
            Topic :
          </label>
          <Accordion
            title="Select Topic"
            className="select-accordian-container"
          >
            <Checkbox
              className="checkbox-container"
              options={topicList}
              type="multi"
              onSelect={handleOnSetTopic}
            />
          </Accordion>
        </div>
        <div className="create-question-input-wrapper">
          <label htmlFor="batch-code" className="create-question-label">
            Sub Topics :
          </label>
          <Accordion
            disabled={subTopicList.length === 0}
            title=" Sub Topic"
            className="select-accordian-container"
          >
            <Checkbox
              className="checkbox-container"
              options={subTopicList}
              type="multi"
              onSelect={handleOnSetSubTopic}
            />
          </Accordion>
        </div>
      </div>
      {selectedQuestionType === "leetcode" && (
        <div className="description-container">
          <div className="create-question-input-wrapper">
            <label htmlFor="batch-code" className="create-question-label">
              Description Type :
            </label>
            <Select
              className="create-question-select"
              key={"description-type"}
              defaultSelected="Description type"
              data={descriptionTypeList}
              onSelect={handleOnSetDescriptionType}
            ></Select>
          </div>
          <div className="create-question-input-wrapper">
            <label htmlFor="batch-code" className="create-question-label">
              Enter description :
            </label>
            <textarea
              rows={7}
              className="description-text-conatiner"
              onChange={handleOnDescriptionValue}
            ></textarea>
          </div>
        </div>
      )}
      {(selectedQuestionType === "githubdsa" ||
        selectedQuestionType === "githubnondsa") && (
        <CreateDSAQuestionComponent
          DSAType={selectedQuestionType === "githubdsa"}
        />
      )}
      <Accordion title={"Title"} key={"title"} className="accordian-container">
        {titleList.map((title) => {
          return title;
        })}
        <div className="add-option-container">
          <span onClick={handleOnAddTitleClick}>
            <AddIcon height="30" width="30" />
          </span>
        </div>
      </Accordion>
      <Accordion
        title={"Options"}
        key={"options"}
        className="accordian-container"
      >
        {optionsList.map((option) => {
          return option;
        })}
        <div className="add-option-container">
          <span onClick={handleOnAddOptionsClick}>
            <AddIcon height="30" width="30" />
          </span>
        </div>
      </Accordion>
      <Accordion
        title={"Answer"}
        key={"answer"}
        className="accordian-container"
      >
        {answerList.map((answer) => {
          return answer;
        })}
        <div className="add-option-container">
          <span onClick={handleOnAddAnswerClick}>
            <AddIcon height="30" width="30" />
          </span>
        </div>
      </Accordion>
      <div className="add-question-button-container">
        <Button
          key={"addButton"}
          text={isQuestionAdding ? "Question Adding" : "Add Question"}
          onClick={handleOnAddQuestion}
        />
      </div>
    </div>
  );
};
