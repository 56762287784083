import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Select } from "../../components/select/select";
import { useTags } from "../../redux/actions/tagsAction";
import { Tabs } from "../../components/tabs/tabs";
import "./questionFilter.scss";
import {
  getTagIdByTagTypeAndKey,
  handleFilterDataState,
  handleQuestionFilterMap,
  questionTags,
} from "../../utils/questionFilterUtils";

type QuestionFilterProps = {
  setFilter: Dispatch<SetStateAction<string[]>>;
  filterId?: string[];
  disableFilter?: boolean;
};
export const QuestionFilter: React.FC<QuestionFilterProps> = (
  props: QuestionFilterProps
) => {
  const { setFilter, filterId = [],disableFilter=false } = props;
  const { tags, getAllTags } = useTags();
  const { tagsData } = tags || {};
  const [selectedTopicTag, setSelectedTopicTag] = useState<number[]>([0]);
  const [selectedCategoryTag, setSelectedCategoryTag] = useState<number[]>([0]);
  const [selectedSubTopic, setSelectedSubTopic] = useState<Record<string, boolean>>({});
  const [selectedDifficulty, setselectedDifficulty] = useState<Record<string, boolean>>({});
  const [questionFilterUi, setQuestionFilterUi] = useState<QuestionFilterType>();
  const [questionFilterMap, setQuestionFilterMap] = useState<Record<string, string[]>>({});
  const { QuestionDifficultyLevel, QuestionUsageCategory, Topic, SubTopic } = questionFilterUi || {};

  const handleSubTopicFilter = (key: string, type: string) => {
    let newSelectedSubTopic: Record<string, boolean> = {...selectedSubTopic};
    newSelectedSubTopic[key] = newSelectedSubTopic[key] ? false : true;
    setSelectedSubTopic(newSelectedSubTopic);
    const tagId = getTagIdByTagTypeAndKey(tagsData, key, type);
    const newQuestionFilterMap = handleQuestionFilterMap(
      questionFilterMap,
      tagId,
      type
    );
    setQuestionFilterMap(newQuestionFilterMap);
    setFilter(Object.values(newQuestionFilterMap).flat(1));
  };

  const handleQuestionCategoryClick = (idx: number, idxArr?: number[]) => {
    const { value, tagType } = QuestionUsageCategory?.dataList[idx] || {};
    if (value && tagType) {
      const tagId = getTagIdByTagTypeAndKey(tagsData, value, tagType);
      const newQuestionFilterMap = handleQuestionFilterMap(
        questionFilterMap,
        tagId,
        tagType,
        setSelectedCategoryTag,
        () => {},
        idx
      );
      setQuestionFilterMap(newQuestionFilterMap);
      setFilter(Object.values(newQuestionFilterMap).flat(1));
    }
    idxArr && setSelectedCategoryTag(idxArr);
  };

  const handleTopicFilter = (idx: number, idxArr?: number[]) => {
    const { value, tagType } = Topic?.dataList[idx] || {};
    if (value && tagType) {
      const tagId = getTagIdByTagTypeAndKey(tagsData, value, tagType);
      const newQuestionFilterMap = handleQuestionFilterMap(
        questionFilterMap,
        tagId,
        tagType,
        () => {},
        setSelectedTopicTag,
        idx
      );
      setQuestionFilterMap(newQuestionFilterMap);
      setFilter(Object.values(newQuestionFilterMap).flat(1));
    }
    if(idxArr && idxArr.length > 1) {
      const filterSet = new Set(idxArr);
      if (filterSet.has(0)) filterSet.delete(0);
      setSelectedTopicTag(Array.from(filterSet.values()));
    }else if(idxArr && idxArr.length === 1){
      setSelectedTopicTag(idxArr);
    }
  };

  const handleDifficultyFilter = (option: SelectOptionType) => {
    const { value: key, tagType = "" } = option;
    let newSelectedDifficulty: Record<string, boolean> = {...selectedDifficulty};
    newSelectedDifficulty[key] = newSelectedDifficulty[key] ? false : true;
    setselectedDifficulty(newSelectedDifficulty);
    const tagId = getTagIdByTagTypeAndKey(tagsData, key, tagType);
    const newQuestionFilterMap = handleQuestionFilterMap(
      questionFilterMap,
      tagId,
      tagType
    );
    setQuestionFilterMap(newQuestionFilterMap);
    setFilter(Object.values(newQuestionFilterMap).flat(1));
  };

  useEffect(() => {
    getAllTags({tagType:questionTags});
  }, []);

  useEffect(() => {
    setQuestionFilterUi(handleFilterDataState(tagsData));
  }, [tagsData]);
  return (
    <div className="question-page-filter-container">
      <div className="question-category-wrapper">
        {QuestionUsageCategory && (
          <Tabs
            dataList={QuestionUsageCategory?.dataList}
            multi={true}
            onClick={handleQuestionCategoryClick}
            selectedTabIndex={selectedCategoryTag}
            disabled={disableFilter}
          />
        )}
      </div>
      <div className="question-difficultyLevel-topic-wise-filter-wrapper">
        {Topic && (
          <Select defaultSelected="Topics" className="question-filter-selector" isDisabled={disableFilter}>
            <Tabs
              dataList={Topic.dataList}
              className="topics-tags-list-tabs"
              onClick={handleTopicFilter}
              selectedTabIndex={selectedTopicTag}
              multi={true}
            >
              {tagsData?.Topic && (
                <div className="tab-subtopic-list-wrapper">
                  {selectedTopicTag?.map((index) =>
                    SubTopic?.dataList[Topic?.dataList[index]?.value]?.map(
                      (child, idx) => (
                        <span
                          key={`${idx * 2 + index}`}
                          onClick={() =>
                            handleSubTopicFilter(
                              child.value,
                              child?.tagType ?? ""
                            )
                          }
                          className={`subtopic-card ${
                            selectedSubTopic[child.value] &&
                            "selected-subtopic-card"
                          }`}
                        >
                          {child.text}
                        </span>
                      )
                    )
                  )}
                </div>
              )}
            </Tabs>
          </Select>
        )}
        <Select
          data={QuestionDifficultyLevel?.dataList}
          defaultSelected="Difficulty"
          className="question-filter-selector"
          onSelect={handleDifficultyFilter}
          selectedOptionClassName="selected-difficulty-level-option"
          isDisabled={disableFilter}
        />
      </div>
    </div>
  );
};
