import { gql } from "@apollo/client";

export const GET_All_USER_GOALS = gql`
  query GetAllUserGoals {
    getAllUserGoals {
      userGoals {
        _id
        userGoalStatus {
          status {
            tagKey
            tagType
            tagName
          }
          time
          goalStatusUpdateFeedback
          goalUpdatedBy {
            name
          }
        }
        goalId {
          _id
          description
          endWeek {
            weekNumber
          }
          isActive
          isLive
          title
          startWeek {
            weekNumber
          }
        }
      }
      response {
        status
        message
      }
    }
  }
`; 