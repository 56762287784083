import { gql } from "@apollo/client";

export const GET_ALL_NOTES_COUNT_BY_TOPIC_SUBTOPIC = gql`
  query GetAllNotesCountByTopicAndSubTopic {
    getAllNotesCountByTopicAndSubTopic {
      notesData {
        parentTagId
        subtopics {
          totalCount
          parentTagName
          parentTagId
          parentTagKey
          subTopicTagName
          subTopicTagId
          subTopicTagKey
        }
      }
      response {
        status
        message
      }
    }
  }
`;
