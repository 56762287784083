import Table from '../../components/goalsTable/goalsTable';
import { useEffect, useState } from 'react'
import { GoalTooltipContainer } from '../../components/goalTooltipContainer/goalTooltipContainer';
import { createTableDataByGoals } from '../../utils/index';
import Spinner from '../../components/spinner/spinner';
import { useUserGoals } from '../../redux/actions/getUserGoalsAction';
import NoDataFoundComponent from '../../components/noDataFound/noDataFound';
import './goalStatsPage.scss'
import { noFoundComponentMessages } from '../../utils/constants';
import useHeader from '../../redux/actions/headerAction';

const GoalStatsPage = () => {
  const { goalsNotFound} = noFoundComponentMessages;
  const [tableData, setTableData] = useState<TableDataType>()  
  const { getUserGoals, userGoals, } = useUserGoals();
  const { userGoalsList , isGoalLoading, } = userGoals || {};
  const { updateHeader } = useHeader();

  useEffect(()=>{
    userGoalsList && setTableData(createTableDataByGoals(userGoalsList));
  },[ userGoalsList])
  useEffect(()=>{
    getUserGoals();
  },[])

  useEffect(() => {
    updateHeader({ title: "Goals Page", options: [] })
  }, []);

  return (
    <div className='goal-stats-page-main-container'>
      <div className='goal-stats-page-sub-container'>
        <div className='goals-tools-container'>
          <div className='goals-display-view'></div>
          <div className='indicators-wrapper'>
            <div className='indicator-card'><span className='goal-indicators goal-uncompleted'></span><span className='goal-indicator-label'>Incompleted</span></div>
            <div className='indicator-card'><span className='goal-indicators goal-completed'></span><span className='goal-indicator-label'>Completed</span></div>
            <div className='indicator-card'><span className='goal-indicators goal-pending'></span><span className='goal-indicator-label'>Pending</span></div>
          </div>
        </div>
        {isGoalLoading ? (
          <Spinner />
        ) : !tableData ? (
          <NoDataFoundComponent message={goalsNotFound} height='200px'/>
        ) : (
          <Table tableData={tableData} TooltipChildren={GoalTooltipContainer} />
        )} 
      </div>
    </div>
  )
}

export default GoalStatsPage;
