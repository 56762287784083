import { useEffect, useState } from "react";
import useHeader from "../../redux/actions/headerAction";
import "./question.scss";
import { useQuestions } from "../../redux/actions/questionAction";
import { useParams } from "react-router-dom";
export const Question: React.FC = ({}: DashboardProps) => {
  const { updateHeader } = useHeader();
  const { getQuestion } = useQuestions();
  const { questionId = "" } = useParams();
  const [question, setQuestion] = useState<GetQuestionDataType>();
  const getQuestionById = async () => {
    try {
      const questionData = await getQuestion({ questionId });
      if (questionData?.response?.status === 200) {
        setQuestion(questionData?.question);
      }
    } catch (error) {

    }
  };
  useEffect(() => {
    getQuestionById();
  }, []);

  useEffect(() => {
    updateHeader({ title: "Question Page", options: [] });
  }, []);
  return (
    <div className="question-container">
      {question?.title && (
        <div className="question-title-container">
          <span className="question-data-label-title">Title:- </span>
          <span className="question-data-itle">{question?.title[0].text}</span>
        </div>
      )}
      {question?.questionType && (
        <div className="question-content-container">
          <span className="label-question-data">Question Type :- </span>
          <span className="question-data"> {question?.questionType}</span>
        </div>
      )}
      {question?.marks && (
        <div className="question-content-container">
          <span className="label-question-data">Marks:- </span>
          <span className="question-data"> {question?.marks}</span>
        </div>
      )}
      {question?.meta?.batchCode && (
        <div className="question-content-container">
          <span className="label-question-data">Batch Code:- </span>
          <span className="question-data"> {question?.meta?.batchCode}</span>
        </div>
      )}
    </div>
  );
};
