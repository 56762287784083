import "./tutorialAccordianContent.scss";
import React from "react";
import { PlayIcon } from "../../icons";

interface TutorialAccordianContentProps {
  className?: string;
  title: string;
  description: string;
  thumbnailUrl: string;
  videoUrl?: string;
}

export const TutorialAccordianContent: React.FC<
  TutorialAccordianContentProps
> = ({ className, title, thumbnailUrl, description, videoUrl }) => {
  const handlePlayClick = () => {};

  return (
    <div className={`tutorial-accordian-content-container ${className}`}>
      <div className="tutorial-accordian-content-container-header">
        <h3 className="tutorial-accordian-content-header">{title}</h3>
        <p className="tutorial-accordian-content-header-paragraph">
          {description}
        </p>
      </div>
      <a className="video-container"
      target="_blank" 
      href={videoUrl}
      onClick={handlePlayClick}>
        <img
          className="tutorial-accordian-video-thumbnail"
          src={thumbnailUrl}
          alt="Video Thumbnail"
        />
        <div
          className="tutorial-accordian-video-thumbnail-play-button"
          onClick={handlePlayClick}
        >
          <a target="_blank" href={videoUrl ?? "#"}>
            {" "}
            <PlayIcon />{" "}
          </a>
        </div>
        <div className="tutorial-video-thumbnail-mask"></div>
      </a>
    </div>
  );
};
