import { gql } from "@apollo/client";

export const GET_QUESTION = gql`
  query GetQuestionById($questionId: String) {
    getQuestionById(questionId: $questionId) {
      response {
        status
        message
      }
      question {
        questionId
        questionType
        title {
          text
          redirectLink
        }
        meta {
          topic
          day
          week
          batchCode
          isActive
          isArchived
          type
          expiresInMins
          isOpenable
        }
        description {
          value
          type
        }
        marks
        githubData {
          issueTitle
          issueBody
          branchName
          issueLabels
          files {
            fileName
            content
          }
        }
      }
    }
  }
`;
