import * as React from "react";
const CrossIconNew: React.FC<SvgIconProps> = ({
    height = "25px",
    width = "25px",
    isDarkMode,
    fillColor = "",
  }: SvgIconProps) => (
  <svg
    fill={fillColor ? fillColor : isDarkMode ? "#fff" : "#000"}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    id="cross"
    data-name="Line Color"
    xmlns="http://www.w3.org/2000/svg"
    className="icon line-color"
  >
    <line
      id="primary"
      x1={19}
      y1={19}
      x2={5}
      y2={5}
      style={{
        fill: "none",
        stroke: "rgb(0, 0, 0)",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 4,
      }}
    />
    <line
      id="primary-2"
      data-name="primary"
      x1={19}
      y1={5}
      x2={5}
      y2={19}
      style={{
        fill: "none",
        stroke: "rgb(0, 0, 0)",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 4,
      }}
    />
  </svg>
);
export default CrossIconNew;
