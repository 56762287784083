import "./NavUser.scss";
import React, { useContext, useState } from 'react';
import { AppStateContext } from '../../AppState/appState.context';
import ImageComponent from '../ImageComponent/ImageComponent';
import { NameIcon } from '../../../icons/index';
import defaultAvatar from "../../assets/images/avatar2.svg";
import { deleteCookie } from "../../../utils/deleteCookie";


interface NavUserProps {
  image?: string;
  setIsDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavUser: React.FC<NavUserProps> = ({ setIsDropdown, image = defaultAvatar }) => {
  const { userData, setIsLoggedIn } = useContext(AppStateContext);
  const { name, profileImage } = userData || {};
  const { secureUrl } = profileImage || {};
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);


  const clickHandler = () => {
    deleteCookie(process.env.REACT_APP_JWT_SECRET_KEY ||""); 
    setIsDropdown(false);
    setIsLoggedIn(false);
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className='nav-user-component'>
      {isDropdownVisible && (
        <div className='nav-user-drop-down'>
          <a className="nav-user-drop-down-logout" onClick={clickHandler}>Logout</a>
          <a href="/dashboard" style={{ marginTop: "5px" }} className="nav-user-drop-down-Dashboard">Dashboard</a>
        </div>
      )}

      <div onClick={toggleDropdown} className="nav-user-dp">
        {userData ? (
          secureUrl ? (
            <ImageComponent
              src={secureUrl}
              alt="Profile"
              className="containerImage"
            />
          ) : (
            <NameIcon height='40px' width='40px' name={name} />
          )
        ) : (
          <ImageComponent
            src={image}
            alt="Default avatar"
            className="containerImage"
          />
        )}
      </div>
    </div>
  );
};

export default NavUser;