import React from 'react';

import "./ApplyNowButton.scss";
import { use } from 'i18next';
import { useNavigate } from 'react-router-dom';

const ApplyNowButton = () => {
  const navigate = useNavigate();
  return (
    <div onClick={()=> navigate('/register')} className='apply-now-button-container'>
        Apply Now 
    </div>
  )
}

export default ApplyNowButton;



