import { useEffect, useState } from "react";
import { InputComponent } from "../../components/input/inputComponent";
import { Button } from "../../components/button/button";
import { useUserGoalsMutation } from "../../redux/actions/createUserGoal";
import { currentDateTime, goalStatus,showWindowMessage } from "../../utils/index";

export const UserGoalResponseComponent = ({
  profileType,
  responseData,
  id = "",
  className,
  userResponseLabels,
  setStatusState=()=>{},
  statusState,
}: UserResponseInputType) => {
  const {labels=[], response : singleResponse=""} = responseData || {};
  const [userResponse, setUserResponse] = useState<string>(singleResponse);
  const [userMultiResponse, setUserMultiResponse] = useState<Record<string,string>>({});
  const {isLoading, updateUserGoal } = useUserGoalsMutation();
  
  const buttonTextMap = {
    UPDATE: "Update",
    UPDATING: "Updating",
    SUBMIT: "Submit",
    SUBMITTING:"Submitting",
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setUserResponse(value);
  };
  
  const handleOnUpdate = async () => {
    try {
      const labels = Object.entries(userMultiResponse).map(([label,value])=>({label,value}));
      const response = await updateUserGoal({labels, time: currentDateTime, response:userResponse}, id, profileType);
      const updatedUserGaol = response?.data?.updateUserGoalCompletion;
      const message = updatedUserGaol?.response?.message;
      const status = updatedUserGaol?.userGoalCompletion?.currentGoalStatus?.status?.tagKey;
      status && setStatusState(status);
      message && showWindowMessage(message);
    } catch (error) {
      console.error(error)
    }
  };

  const handleOnMultiChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const newUserMultiResponse = {...userMultiResponse};
    newUserMultiResponse[key] = e.target.value;
    setUserMultiResponse(newUserMultiResponse);
  }

  useEffect(()=>{
    const newUserMultiResponse : Record<string,string> = {};
    labels.forEach(label=>newUserMultiResponse[`${label.label}`] = `${label.value}`)
    setUserMultiResponse(newUserMultiResponse)
  },[])

  return (
      <div className={`user-response-component ${className}`}>
        <div className="user-response-component-inputs-wrapper">
          <div className="usergoal-user-response-input-label-wrapper">
            <label className="user-response-input-label">Add Description</label>
            <textarea 
              onChange={handleOnChange} 
              value={userResponse}
              className="usergoal-description-text-container"
            ></textarea>
          </div>
          {
          Boolean(userResponseLabels?.length) &&
            userResponseLabels?.map((res: UserGoalResponseLabels)=>(
              <div className="usergoal-user-response-input-label-wrapper">
                <label className="user-response-input-label">{res.label}</label>
                <InputComponent
                  onChange={(e)=>handleOnMultiChange(e,res.value)}
                  value={userMultiResponse[res.value] ?? ""}
                  type="Text"
                />
              </div>
            )) 
          }
          </div>
        {statusState && 
          (statusState !== goalStatus.COMPLETED) &&
          <Button
          isDisabled={!userResponse && !Object.keys(userMultiResponse).length}
          variant="contained"
          text={ isLoading ?
            (responseData ? buttonTextMap.UPDATING : buttonTextMap.SUBMITTING) :
            (responseData ? buttonTextMap.UPDATE : buttonTextMap.SUBMIT)
          }
          onClick={handleOnUpdate}
          isLoading={isLoading}
        />}
      </div>
  );
};