import { gql } from "@apollo/client";

export const GET_USER_GOAL_BY_ID = gql`
  query GetUserGoalById($input: GetUserGoalByIdInput) {
    getUserGoalById(input: $input) {
      userGoalCompletion {
        _id
        userId {
          _id
          email
          name
          batchCode
          phoneNumber
          profileImage {
            secureUrl
          }
        }
        goalId {
            _id
          title
          description
          goalType {
            goalType
          }
          profileType
          userResponseLabels {
            label
            value
          }
          createdAt
        }
        completedAt
        userResponse {
          labels {
            label
            value
          }
          time
          response
        }
        userGoalStatus {
          status {
            tagName
            tagKey
            tagType
            _id
          }
          time
          goalStatusUpdateFeedback
          goalUpdatedBy {
            _id
            name
          }
        }
        currentGoalStatus {
          status {
            _id
            tagName
            tagKey
            tagType
          }
          time
          goalStatusUpdateFeedback
          goalUpdatedBy {
            _id
            name
          }
        }
        createdAt
        updatedAt
      }
      response {
        status
        message
      }
    }
  }
`;
