import { gql } from "@apollo/client";

export const GET_ATTEMPTED_QUESTION = gql`
  query GetAttemptedQuestions($batchCode: String) {
    getAttemptedQuestions(batchCode: $batchCode) {
      response {
        status
        message
      }
      questionAttemptedData {
        userName
        questionAttemptId
        marksObtained
        attemptDate
        isMarked
        prLink
        totalMarks
        questionType
        questionId
      }
    }
  }
`;
