import { gql } from "@apollo/client";


export const GET_LEADERBOARD_DATA = gql`
query GetLeaderBoardData($batchCode: String!) {
    getLeaderBoardData(batchCode: $batchCode) {
        user {
            _id
            name
            email
            profileImage {
                secureUrl
            }
        }
        rank
        totalSubmissions
    }
}`;