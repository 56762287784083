import * as React from "react";
export const BoldIcon: React.FC<SvgIconProps> = ({
    height = "25px",
    width = "25px",
    className,
    fillColor = "#fff"
}:SvgIconProps) => (
  <svg
    fill={fillColor}
    width={width}
    height={height}
    viewBox="-48 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M214 80Q266 80 299 107 331 134 331 176 331 201 320 222 308 243 291 251L291 253Q319 262 336 284 352 306 352 335 352 377 321 405 290 432 242 432L64 432 64 80 214 80ZM218 224Q239 224 253 211 267 198 267 180 267 164 254 154 240 144 218 144L128 144 128 224 218 224ZM236 368Q258 368 273 357 288 345 288 328 288 309 274 299 259 288 236 288L128 288 128 368 236 368Z" />
  </svg>
);