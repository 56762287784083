import React from 'react'
import "./Glassmorphism.scss"

interface GlassmorphismProps {
    component?: React.ReactNode
    text: string
    classname?: string
    textclassname?: string
}

const Glassmorphism: React.FC<GlassmorphismProps> = ({
    classname,
    component,
    text,
    textclassname
}) => {
    return (
        <div className={component? classname: "glassmorphism"}>
            {component}
            <p className={textclassname? textclassname:'glassmorphism-text'}>{text}</p>
        </div>
    )
}

export default Glassmorphism