import { Card } from "../../components/card/card";
import React, { useEffect, useState } from "react";

import { FilterTags } from "../../components/filterTags/filterTags";
import { useVideos } from "../../redux/actions/videosAction";
import "./videosPage.scss";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/spinner/spinner";
import { useLocation } from "react-router-dom";
import { Text } from "../../components/text/text";
import NoDataFoundComponent from "../../components/noDataFound/noDataFound";
import { setHeader } from "../../redux/slices/header/headerSlice";
import useHeader from "../../redux/actions/headerAction";

const VideosPage: React.FC = () => {
  const [filterTagMap, setFilterTagMap] = useState<Record<string, boolean>>({
    HTML: false,
    CSS: false,
    JS: false,
    REACT: false,
    NODE: false,
    MONGODB: false,
  });
  const { videoData, getAllVideos } = useVideos();
  const { t } = useTranslation();
  const { updateHeader } = useHeader();

  const handleFilter = (value: string) => {
    const tempFilterTagMap = { ...filterTagMap };
    tempFilterTagMap[value] = !tempFilterTagMap[value];
    setFilterTagMap(tempFilterTagMap);
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dayNumber = queryParams.get("dayNumber");
  const weekNumber = queryParams.get("weekNumber");
  const getAllVideosRequest = async () => {
    await getAllVideos(
      weekNumber && dayNumber
        ? {
            weekNumber: Number(weekNumber),
            dayNumber: Number(dayNumber),
          }
        : {}
    );
  };
  useEffect(() => {
    getAllVideosRequest();
  }, [weekNumber, dayNumber]);
  const { videoList,isVideosLoading } = videoData;

  const isVideoTagCheckedInFilterTag = (tagList: string[]) => {
    let isAllTagsExists = true;
    Object.entries(filterTagMap).map(([tag, isTagChecked]) => {
      if (isTagChecked && !tagList.includes(tag)) {
        isAllTagsExists = false;
      }
    });
    return isAllTagsExists;
  };
  const isAnyFilterApplied = Object.values(filterTagMap).some((tag) => tag);

  useEffect(() => {
    updateHeader({ title: "Videos Page", options: [] });
  }, [])
  

  if (!Boolean(videoData?.videoList?.length) && !isVideosLoading) {
    return (
      <NoDataFoundComponent message="No videos found" className="noDataFound" />
    );
    
  }
  

  return (
    <div className="video-page-container">
    <div className="video-page-sub-container">
        {isVideosLoading ? <Spinner/> :
      <><FilterTags setFilterTag={handleFilter} filterTagMap={filterTagMap} />
      <div className="videos-wrapper">
        {videoList?.map(
          (video) =>
            /* TODO: @dhananjay - Instead of using this filter, need to do an API call  */
            (isVideoTagCheckedInFilterTag(video?.topics || []) ||
              !isAnyFilterApplied) && (
              <Card tagPosition="left" data={video} className="videos-card"></Card>
            )
        )}
      </div></>
      }
    </div>
    </div>
  );
};
export default VideosPage;
