import { gql } from "@apollo/client";

export const GET_GOAL_TYPE_LIST = gql`
  query GetGoalTypeList {
    getGoalTypeList {
      response {
        status
        message
      }
      goalList {
        goalType
        _id
      }
    }
  }
`;
