import "./CourseInfoCard.scss";

export const CourseInfoCard = ({
  icon = <></>,
  title = "",
  description = "",
  url = "",
  onClick = () => { },
  active = false
}) => {
  return (
    <div className={`course-info-card-container ${active && "active-course-info-card-container"}`} onClick={onClick}>
      <div className="course-info-sub-container">
        <div className="icon-container">
          {url ? <img src={url} className="icon-url" /> : <div className="icon-wrapper">{icon}</div>}
        </div>
        <div className="title-text">{title}</div>
      </div>
      <div className="description-text">{description}</div>
    </div>
  );
};
