import useSocket from "../../hooks/useSocket";
import "./discussionPage.scss";
import { useDispatch, useSelector } from "react-redux";
import ChatInput from "../../components/chatInput/chatInput";
import DiscussionSidebar from "../../components/discussionSidebar/discussionSidebar";
import { AvatarIcon, BotIcon, GeneralGroupIcon } from "../../icons";
import { useEffect, useRef } from "react";
import {
  addMessage,
  updateMessageList,
} from "../../redux/slices/discussion/discussionSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const DiscussionPage = () => {
  const { messageList, isMessageLoading } = useSelector(
    (state: { discussion: DiscussionGlobalStateType }) => state.discussion
  );
  const { sendGeneralMessage, isConnected } = useSocket();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const onSendMessage = (
    message: string,
    files: Array<string | ArrayBuffer>
  ) => {
    if (isConnected) {
      sendGeneralMessage({ message, files });
    }
  };
  const channelList = [
    {
      displayText: "General",
      icon: GeneralGroupIcon,
    },
  ];

  const activeOption = channelList[0];

  const formatDate = (date: Date) =>
    date.toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
      timeZone: "Asia/Kolkata",
    });

  const formatTime = (date: Date) =>
    date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    });

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.children[
        containerRef.current.children.length - 1
      ].scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  const showErrorConnectionMsg = () =>
    dispatch(
      addMessage({
        sentAt: new Date().toISOString(),
        name: "Webmasters bot",
        svgIcon: <BotIcon />,
        message:
          "Hello, it appears that your internet connection is not active.",
        isSentByBot: true,
      })
    );

  const removeBotMsg = () =>
    dispatch(
      updateMessageList(
        messageList.map((messageData) => ({
          ...messageData,
          messages: messageData?.messages?.filter(
            (message) => !message.isSentByBot
          ),
        }))
      )
    );

  useEffect(() => {
    if (isConnected) {
      removeBotMsg();
    } else {
      showErrorConnectionMsg();
    }
  }, [isConnected]);

  return (
    <div className="discussion-page-container">
      <DiscussionSidebar optionList={channelList} />
      <div className="discussion-page-chat-message">
        <div className="discussion-page-channel-header">
          {<activeOption.icon />}
          <h4>{activeOption.displayText}</h4>
        </div>
        <div className="discussion-chat-message-main-container">
          {messageList?.map((dayMessageInfo, index: number) => (
            <div className="discussion-page-day-chat-container" key={index}>
              <div className="discussion-message-day-date-header">
                {dayMessageInfo?.date &&
                  formatDate(new Date(dayMessageInfo?.date))}
              </div>
              <div>
                {dayMessageInfo?.messages?.map(
                  (
                    messageData: ChatDiscussionMessageDataType,
                    index: number
                  ) => (
                    <div
                      ref={containerRef}
                      className="discussion-chat-message-container"
                      key={index}
                    >
                      {messageData?.profileImage ? (
                        <img
                          height={30}
                          width={30}
                          src={messageData?.profileImage}
                          alt=""
                        />
                      ) : (
                        messageData?.svgIcon || <AvatarIcon />
                      )}

                      <div className="discussion-chat-message-content-wrapper">
                        <div className="discussion-chat-message-user-heading">
                          {messageData?.name || "unknown"}
                          <span className="chat-message-time">
                            {messageData?.sentAt &&
                              formatTime(new Date(messageData.sentAt))}
                          </span>
                        </div>
                        <div className="discussion-chat-message-content">
                          {messageData?.message}
                        </div>
                        <div className="discussion-chat-files-container">
                          {messageData?.files?.map(({ secureUrl }) => (
                            <img
                              key={secureUrl}
                              className="discussion-chat-file"
                              src={secureUrl}
                              alt="unable to load file"
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}

          {isMessageLoading && (
            <SkeletonTheme
              baseColor="rgb(255, 255, 255, 0.5)"
              highlightColor="rgb(255, 255, 255, 0.7)"
            >
              <Skeleton height={25} count={1} />
            </SkeletonTheme>
          )}
        </div>
        <ChatInput
          disableSendBtn={!isConnected}
          className="discussion-chat-input-container"
          onSendClick={onSendMessage}
        />
      </div>
    </div>
  );
};

export default DiscussionPage;