import { LeftArrowIcon } from "../../icons/leftArrowIcon";
import "./goalHistoryTable.scss";
import { TooltipIcon } from "../../icons/tooltipIcon";
import { useState } from "react";
import { Modal } from "../Modal/modal";
import RichTextEditor from "../richTextEditor/richTextEditor";
import { CloseCrossIcon } from "../../icons/closeCrossIcon";

export const GoalHistoryTable: React.FC<GoalHistoryTableProps> = ({
  goalStatusRecord,
  prevStatus,
  lastIndex,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const { goalStatusUpdateFeedback, goalUpdatedBy } = goalStatusRecord;
  const userGoalUpdatedBy = goalUpdatedBy?.name ? goalUpdatedBy.name : "Admin";
  const goalFeedback = goalStatusUpdateFeedback ? goalStatusUpdateFeedback : "";
  const status = goalStatusRecord?.status?.tagName;
  const time = new Date(goalStatusRecord?.time)?.toLocaleString();
  const statusHistory = prevStatus ? (
    <>
      <span className={lastIndex ? "latest-update-previous" : ""}>
        {lastIndex ? prevStatus?.toUpperCase() : prevStatus}
      </span>
      <LeftArrowIcon height="8" width="8" fillColor="#000" />{" "}
      {Boolean(status) && (<span className={lastIndex ? "latest-update-current" : ""}>
        {lastIndex ? status?.toUpperCase() : status}
      </span>)}
    </>
  ) : (
    <>
      <span className={lastIndex ? "latest-update-previous" : ""}>
        {lastIndex ? "NONE" : "None"}
      </span>
      <LeftArrowIcon height="10" width="10" fillColor="#000" />{" "}
      <span className={lastIndex ? "latest-update-current" : ""}>
        {lastIndex ? status?.toUpperCase() : status}
      </span>
    </>
  );
  const statusTag = (
    <>
      <span className="status-text">{userGoalUpdatedBy}</span> changed the{" "}
      <span className="status-text">Status </span>
      <span> {time} </span>
    </>
  );
  const handleOnTooltipClick = () => {
    setIsTooltipOpen(true);
  };
  const closeToolTip = () => {
    setIsTooltipOpen(false);
  };
  return (
    <div className="goal-history-table-container">
      <div className="status-headbar">
        {statusTag}
        {goalFeedback && (
          <span className="tooltip-container" onClick={handleOnTooltipClick}>
            {" "}
            <TooltipIcon height={12} width={12} />{" "}
          </span>
        )}
      </div>
      <div className="goal-history-status">{statusHistory}</div>
      <Modal className="feedback-goal-modal" isOpen={isTooltipOpen}>
        {
          <div className={`goal-feedback-container`}>
            <div
              className="feedback-close-icon-container"
              onClick={closeToolTip}
            >
              <CloseCrossIcon height={"20"} width={"20"} fillColor="#ce3434" />
            </div>
            <RichTextEditor value={goalFeedback} editable={false} className="goal-rich-editor" />
          </div>
        }
      </Modal>
    </div>
  );
};
