import { createSlice } from "@reduxjs/toolkit";

const initialState: QuestionTopicSubtopicDataType[] = [];

const questionTopicSubtopicSlice = createSlice({
  name: "questionTopicSubtopic",
  initialState: initialState,
  reducers: {
    setQuestionTopicSubtopic: (state, action) => {
      const questionData = action.payload;
      return questionData;
    },
  },
});
export const {
  actions: questionTopicSubtopicAction,
  reducer: questionTopicSubtopicReducer,
} = questionTopicSubtopicSlice;
export const topicSubtopicQuestionCountData = (state: {
  questionTopicSubtopicCount: QuestionTopicSubtopicDataType;
}) => state.questionTopicSubtopicCount;
export default questionTopicSubtopicSlice.reducer;
