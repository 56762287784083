import { PieChart } from "../pieChart/pieChart";
import "./dashboardQuestionComponent.scss";
const state = {
  options: {},
  labels: [],
};
const chartData = 
    {
      label: [],
      data: [],
      background: [
        "yellow",
        "green",
        "red"
      ]
    };
export const DashboardQuestionComponent: React.FC = () => {
  return (
    <div className="dashboard-question-component-container">
      <div className="current-question-header">Question</div>
      <div className="current-class-wrapper">
        <PieChart
          type={"doughnut"}
          height="190"
          width="190"
          labels={chartData.label}
          dataCount={chartData.data}
          backgroundColor={chartData.background}
          className="dashboard-chart"
        />
        <div className="question-dashboard-chart-text-container">
            {chartData?.label?.map((item, index) => (
              <div className="question-dashboard-chart-text-single-row">
                <div className="question-dashboard-chart-text">
                  <div
                    style={{ background: chartData?.background[index]}}
                    className="question-dashboard-chart-text-dot"
                  ></div>
                  <div
                    className="question-dashboard-chart-text-label"
                  >
                    {item}
                  </div>
                </div>
                <div >{chartData?.data[index]}</div>
              </div>
            ))}
          </div>
      </div>
    </div>
  );
};
