import { createSlice } from "@reduxjs/toolkit";

const initialState: TopicData[] = [];

const notesTopicSubtopicSlice = createSlice({
  name: "notesTopicSubtopic",
  initialState: initialState,
  reducers: {
    setNotesTopicSubtopic: (state, action) => {
      const notesData = action.payload;
      return notesData;
    },
  },
});
export const {
  actions: notesTopicSubtopicAction,
  reducer: notesTopicSubtopicReducer,
} = notesTopicSubtopicSlice;
export const topicSubtopicNotesCountData = (state: {
  notesTopicSubtopicCount: TopicData[];
}) => state.notesTopicSubtopicCount;
export default notesTopicSubtopicSlice.reducer;
