import React from 'react'
import './ElevatePage.scss'
import ArrowUpRightIcon from '../../assets/images/arrowUpRightIcon.svg'
import coastalvacationestates from '../../assets/images/coastalvacationestates.png'
import petragarmon from '../../assets/images/petragarmon.png'
import niyoframer from '../../assets/images/niyoframer.png'
import nftwebframer from '../../assets/images/nftwebframer.png'
import Fniyoframer from '../../assets/images/Fniyoframer.png'
import FlagIcon from '../../assets/icons/FlagIcon'

  
interface ElevatePageProps {
    navRef: React.RefObject<HTMLDivElement>;
  }
export const ElevatePage: React.FC<ElevatePageProps> = ({navRef}) => {
    const dataList = [
        { title: 'petragarmon.com', text: '', image: petragarmon , url:"https://petragarmon.com" },
        { title: 'niyo.framer.ai', text: '', image: niyoframer, url:"https://niyo.framer.ai" },
        { title: 'Fniyo.framer.ai', text: '', image: Fniyoframer, url:"https://www.framer.com/?utm_campaign=freeplanbadge&utm_source=https%3A%2F%2Fniyo.framer.ai" },
        { title: 'nftweb.framer.ai', text: '', image: nftwebframer, url:"https://nftweb.framer.ai" },
        { title: 'coastalvacationestates.com', text: '', image: coastalvacationestates, url:"https://www.coastalvacationestates.com/" },
    ]
    return (
        <div className='elevate-page-component' ref={navRef}>
            <div className='elevate-page-head'>
                <h4 className='elevate-page-head-h4'>
                    <FlagIcon/> Sample Website
                </h4>
                <h1 className='elevate-page-head-h1'>Explore: Sample Website for Web Development</h1>
                <p className='elevate-page-head-p'>This sample website demonstrates essential web development concepts, including responsive design, interactivity, and best coding practices.</p>
            </div>
            <div className='elevate-page-container'>
                {dataList.map(({ title, text, image, url }) => (
                    <div className='elevate-card'
                    onClick={()=>window.open(url)}
                    >
                        <div className='elevate-card-text-container'>
                            <div className='elevate-card-text-left'>
                                <h4 className='elevate-card-text-h4'>{title}</h4>
                                <p className='elevate-card-text-p'>{text}</p>
                            </div>
                            <div className='arrow-container'>
                                <img className='arrow-image-one' src={ArrowUpRightIcon} alt="" />
                                <img className='arrow-image-two' src={ArrowUpRightIcon} alt="" />
                            </div>
                        </div>
                        <div className='elevate-card-image-container'>
                            <img className='elevate-card-image' src={image} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
