import { useDispatch, useSelector } from "react-redux";
import { apolloClient } from "../../graphql/apolloClient/apolloClient";
import { actions, selectTag } from "../slices/tags/tagsSlice";
import { GET_ALL_TAGS } from "../../graphql/query/tags/getAllTags";
export const useTags = () => {
    const dispatch = useDispatch();
    const tags = useSelector(selectTag);
    const getAllTags = async (filter : TagsFilterType = { } ) => {
        const { tagKey,tagName,tagType,_id,parentTagId } = filter || {};
                try {
            const response = await apolloClient.query({
                query: GET_ALL_TAGS,
                variables: {
                    filter : {
                        _id,
                        tagKey,
                        tagName,
                        tagType,
                        parentTagId,
                    }
                },
            });
            dispatch(actions.setTag(response.data.getAllTags));
            return response;
        } catch (err) {
            console.error(err);
        }
    };


    return { tags, getAllTags };
};