import React from "react";
import "./topics.scss"; 
import { DashboardIcon, FLagIcon } from "../../icons"
import amazonIcon from "../../monorepoClient/assets/images/amazonIcon.svg"
import microsoftIcon from "../../monorepoClient/assets/images/microsoftIcon.svg";
import sliceIcon from "../../monorepoClient/assets/images/sliceIcon.svg";
import razorpayIcon from "../../monorepoClient/assets/images/razorpayIcon.svg";
import payTmIcon from "../../monorepoClient/assets/images/PayTmIcon.svg";
import swiggyIcon from "../../monorepoClient/assets/images/swiggyIcon.svg";
import zomatoIcon from "../../monorepoClient/assets/images/zomatoIcon.svg";
import adobeIcon from "../../monorepoClient/assets/images/adobeIcon.svg";
import walmartIcon from "../../monorepoClient/assets/images/walmartIcon.svg";
import meeshoIcon from "../../monorepoClient/assets/images/meeshoIcon.svg";
import flipkartIcon from "../../monorepoClient/assets/images/flipkartIcom.svg";
import olaIcon from "../../monorepoClient/assets/images/olaIcon.svg";
interface TopicsList {
  name: string;
  logo: any;
}

const topics: TopicsList[] = [
  { name: "Amazon", logo: amazonIcon },
  { name: "Microsoft", logo: microsoftIcon },
  { name: "Slice", logo: sliceIcon },
  { name: "Razorpay", logo: razorpayIcon },
  { name: "PayTM", logo: payTmIcon },
  { name: "Swiggy", logo: swiggyIcon },
  { name: "Zomato", logo: zomatoIcon },
  { name: "Adobe", logo: adobeIcon },
  { name: "Walmart", logo: walmartIcon },
  { name: "Meesho", logo: meeshoIcon },
  { name: "Flipkart", logo: flipkartIcon},
  { name: "OLA", logo: olaIcon },
];

interface TopicsProps {
  navRef: React.RefObject<HTMLDivElement>;
}

export const Topics: React.FC<TopicsProps> = ({navRef}) => {
  return (
    <div className="topics-section">
      <div className="topics-header">
        <div className="topics-icon">
        <FLagIcon/> Companies
        </div>
        <h2 className="topics-main-header">Where Our Graduates Landed: </h2>
        <p className="topics-header-paragraph">
        Companies Offering 6 LPA+ Jobs
        </p>
      </div>
      <div className="topics-grid">
        {topics?.map((topic) => (
          <div key={topic.name} className="topic-card">
            <div className="topic-logo">
              <img className="topic-logo-img" src={topic.logo} alt="" />
            </div>
            <div className="topic-name">{topic.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
