import { gql } from "@apollo/client";

export const UPDATE_ATTEMPTED_QUESTION = gql`
  mutation UpdateMarksObtained($attemptId: String!, $marksObtained: Int!) {
    updateMarksObtained(attemptId: $attemptId, marksObtained: $marksObtained) {
      status
      message
    }
  }
`;
