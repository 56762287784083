import React from 'react'

export const LinkedInLogo = () => {

  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M19.99 3.3H4.01C3 3.3 2.2 4.1 2.2 5.11v13.77c0 1.01.81 1.81 1.81 1.81h15.98c1.01 0 1.81-.81 1.81-1.81V5.11c0-1.01-.81-1.81-1.81-1.81zM8.57 18.01H5.68V10.35h2.89v7.66zm-1.44-8.74c-.92 0-1.65-.74-1.65-1.65 0-.91.74-1.65 1.65-1.65s1.65.74 1.65 1.65c0 .91-.74 1.65-1.65 1.65zm12.24 8.74h-2.89v-3.84c0-.92-.02-2.11-1.28-2.11-1.28 0-1.48 1-1.48 2.03v3.93h-2.89V10.35h2.78v1.04h.04c.39-.74 1.36-1.52 2.8-1.52 2.99 0 3.55 1.97 3.55 4.52v3.63z"></path>
  </svg>
  )
}
