import { BackButton } from "../backButton/backButton";
import React from "react";
import { useSelector } from "react-redux";
import "./header.scss";
import { useLocation } from "react-router-dom";
import { stringConstants } from "../../utils/constants";
import { selectUser } from "../../redux/slices/user/userSlice";
import { protectedRoutesMap } from "../../routes/protectedRoutes";

const Header = () => {
  const { title, options = [] } = useSelector((state: any) => state.header);
  const { userData } = useSelector(selectUser);
  const location = useLocation();
  const isFirstPage = location.key === stringConstants.default;
  return (
    protectedRoutesMap[location.pathname] && (
      <div className="header-container-wrapper">
        <div className="header-container">
          <div className="header-first-container">
            {!isFirstPage && <BackButton />}
            <span className="header-title">{title}</span>
          </div>
          <div>{options.map((option: Element) => option)}</div>
        </div>
      </div>
    )
  );
};

export default Header;
