import React from "react";
import { AvatarIcon, LeftArrowIcon } from "../../icons";
import "./integrations.scss";
import FlagIcon from "../../monorepoClient/assets/icons/FlagIcon";
import Glassmorphism from "../../monorepoClient/Components/Glassmorphism/Glassmorphism";
// import ApplyNowButton from "../../monorepoClient/Components/ApplyNowButton/ApplyNowButton";
import ApplyNowButton from "../../monorepoClient/Components/NewComponents/ApplyNowButton/ApplyNowButton";
import writing from "../../monorepoClient/assets/images/writingIcon.svg"
import technical from "../../monorepoClient/assets/images/technicalIcon.svg"
import videos from "../../monorepoClient/assets/images/videoIcon.svg"
import freelance from "../../monorepoClient/assets/images/freelanceIcon.svg"
import internship from "../../monorepoClient/assets/images/internshipIcon.svg"
import avatar from "../../monorepoClient/assets/images/avatar.svg"
interface Integration {
  name: string;
  logo: string;
  description: string;
}

const integrations: Integration[] = [
  {
    logo: writing, // Replace with actual logo component
    name: "Earn by Writing Technical Blogs",
    description: "Showcase your expertise through detailed blogs and get paid for your knowledge.",
  },
  {
    logo: technical,
    name: "Create Technical Content for Us",
    description: "Craft engaging and informative content that educates and inspires developers.",
  },
  {
    logo: videos,
    name: "Record Technical Videos for Us",
    description: "Share your coding insights through tutorials, building a professional portfolio.",
  },
  {
    logo: freelance,
    name: "Start Freelance Work with Us",
    description: "Work on exciting projects, leveraging your skills for real-world applications.",
  },
  {
    logo: internship,
    name: "Begin Internship on Stipend",
    description: "Gain hands-on experience with a stipend while building your technical expertise.",
  },
  {
    logo: avatar,
    name: "Trello",
    description: "Sync Trello boards seamlessly with SAP.",
  },
];

interface IntegrationSectionProps {
  navRef: React.RefObject<HTMLDivElement>;
}
export const IntegrationSection: React.FC<IntegrationSectionProps> = ({ navRef }) => {
  return (
    <div className="integration-section"
      ref={navRef}
    >
      <div className="integration-header">
        <div className="integration-icon">
          <FlagIcon />
          Potential</div>
        <h2 className="integration-main-header">
          Earn While You Learn
        </h2>
        <p className="integration-header-paragraph">
          Explore various opportunities to enhance your skills while earning through content creation, freelancing, internships, and more.
        </p>
      </div>
      <div className="integration-grid">
        {integrations.map((integration, index) => (
          <div key={integration.name} className="integration-card">
            {index===integrations.length-1 &&
              <Glassmorphism
                classname='integration-card-glassmorphism'
                component={<ApplyNowButton />}
                text='To Know More'
                textclassname='integration-card-glassmorphism-text'
              />
            }
            <div className="integration-logo-header">
              <div className="integration-logo">
                <img className="integration-logo-img" src={integration.logo} alt="" />
                {/* {integration.logo} */}
                </div>
              <h3 className="integration-name">{integration.name}</h3>
            </div>
            <div className="integration-content">
              <p className="integration-description">
                {integration.description}
              </p>
            </div>
            {/* <a
              href={integration.link}
              target="_blank"
              rel="noopener noreferrer"
              className="integration-link"
            >
              <div className="integration-link-container">
                <div className="integrations-visit-website">Visit Website</div>{" "}
                <div className="integrations-visit-icon">
                  <LeftArrowIcon fillColor="#666666" />
                </div>
              </div>
            </a> */}
          </div>
        ))}
      </div>
    </div>
  );
};
