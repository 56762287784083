import * as React from "react";

export const CssIcon: React.FC<SvgIconProps> = ({
  height = "25px",
  width = "25px",
  isDarkMode,
  fillColor = "",
}: SvgIconProps) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"css"}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="invisible_box" data-name="invisible box">
        <rect width={48} height={48} fill="none" />
      </g>
      <g id="Q3_icons" data-name="Q3 icons">
        <path
          fill={fillColor ? fillColor : isDarkMode ? "#fff" : "#000"}
          d="M24,46,8.3,41.7,5,4H43L39.7,41.7ZM12,38.6l12,3.3,12-3.3L38.6,8H9.4Z"
        />
        <path
          fill={fillColor ? fillColor : isDarkMode ? "#fff" : "#000"}
          d="M14,13H34L32.2,34.3,23.9,37l-8.4-2.1-.9-7.2h4.5v3.6l4.8.9,4.4-.9V25.9H14.6l-.3-4.5a2.3,2.3,0,0,1,1.5-.6c6.9,0,12.5.6,12.5.6V17.8H14.6Z"
        />
      </g>
    </g>
  </svg>
);