import React from "react";

export const FaqIcons:React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={18}
    height={18}
    fill="#333"
    stroke="#333"
    viewBox="-50.2 -50.2 602.4 602.4"
  >
    <circle
      cx={251}
      cy={251}
      r={241}
      style={{
        fill: "#fff",
      }}
    />
    <path d="M251 502c-67.044 0-130.076-26.108-177.484-73.516S0 318.044 0 251 26.108 120.924 73.516 73.516 183.956 0 251 0c67.045 0 130.076 26.108 177.483 73.516C475.892 120.924 502 183.956 502 251s-26.108 130.076-73.517 177.484C381.076 475.892 318.045 502 251 502zm0-482C123.626 20 20 123.626 20 251s103.626 231 231 231 231-103.626 231-231S378.374 20 251 20z" />
    <path
      d="M370 170.359c0-65.617-53.383-119-119-119s-119 53.383-119 119c0 13.807 11.193 25 25 25s25-11.193 25-25c0-38.047 30.953-69 69-69s69 30.953 69 69c0 38.322-30.953 69.5-69 69.5-13.807 0-25 11.193-25 25v68.781c0 13.807 11.193 25 25 25 13.808 0 25-11.193 25-25v-46.443c53.653-11.565 94-59.555 94-116.838z"
      style={{
        fill: "#ffffff9e6",
      }}
    />
    <path d="M251 368.641c-19.299 0-35-15.701-35-35V264.86c0-19.299 15.701-35 35-35 32.532 0 59-26.691 59-59.5 0-32.533-26.468-59-59-59-32.533 0-59 26.467-59 59 0 19.299-15.701 35-35 35s-35-15.701-35-35c0-71.131 57.869-129 129-129s129 57.869 129 129c0 29.756-10.345 58.835-29.129 81.881-16.791 20.601-39.596 35.582-64.871 42.763v38.638c0 19.297-15.701 34.999-35 34.999zm0-277.282c43.561 0 79 35.439 79 79 0 43.836-35.439 79.5-79 79.5-8.271 0-15 6.729-15 15v68.781c0 8.271 6.729 15 15 15s15-6.729 15-15v-46.443a9.999 9.999 0 0 1 7.893-9.775C323.787 266.667 360 221.641 360 170.359c0-60.103-48.897-109-109-109s-109 48.897-109 109c0 8.271 6.729 15 15 15s15-6.729 15-15c0-43.56 35.439-79 79-79z" />
    <path
      d="M251 396.641c-13.807 0-25 11.193-25 25v4c0 13.807 11.193 25 25 25s25-11.193 25-25v-4c0-13.808-11.193-25-25-25z"
      style={{
        fill: "#ffffff9e6",
      }}
    />
    <path d="M251 460.641c-19.299 0-35-15.701-35-35v-4c0-19.299 15.701-35 35-35s35 15.701 35 35v4c0 19.298-15.701 35-35 35zm0-54c-8.271 0-15 6.729-15 15v4c0 8.271 6.729 15 15 15s15-6.729 15-15v-4c0-8.271-6.729-15-15-15zM49.274 303.002c-4.642 0-8.803-3.25-9.782-7.974A217.593 217.593 0 0 1 35 251c0-5.523 4.477-10 10-10s10 4.477 10 10c0 13.462 1.372 26.911 4.077 39.972 1.12 5.408-2.356 10.7-7.764 11.82-.684.142-1.367.21-2.039.21zM168.992 450.034a9.958 9.958 0 0 1-3.979-.831c-48.481-21.06-87.496-59.051-109.856-106.976-2.335-5.005-.171-10.955 4.834-13.29 5.003-2.335 10.955-.171 13.29 4.834 20.294 43.495 55.702 77.975 99.701 97.088 5.065 2.2 7.388 8.09 5.188 13.156a10.004 10.004 0 0 1-9.178 6.019z" />
  </svg>
)
