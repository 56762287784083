import { gql } from "@apollo/client";

export const CREATE_GOAL = gql`
  mutation CreateNewGoal($input: GoalInputType) {
    createNewGoal(input: $input) {
      goal {
        _id
        title
        goalType {
          goalType
        }
        userResponseLabels {
          label
          value
        }
      }
      response {
        status
        message
      }
    }
  }
`;
