import { useTranslation } from "react-i18next";
import { Button } from "../../components/button/button";
import React, { ChangeEvent, useState } from "react";
import "./installmentItem.scss";
import { readFileAsDataURL } from "../../utils/readFileAsDataURL";
import CrossIcon from "../../icons/CrossIcon";
import { CheckedIcon } from "../../icons/CheckedIcon";
const InstallmentItem: React.FC<InstallmentItemProps | any> = ({
  installment,
  handlePayNow,
  index,
  isApproved,
  isPending=false,
}) => {
  const [paymentReceipt, setPaymentReceipt] = useState<string | ArrayBuffer>(
    ""
  );
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const openImagePreview = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setIsImagePreviewOpen(true);
  };
  const closeImagePreview = () => {
    setSelectedImage("");
    setIsImagePreviewOpen(false);
  };
  const handleOnClick = async () => {
    setIsLoading(true);
    await handlePayNow(installment, paymentReceipt);
    setPaymentReceipt("");
    setIsLoading(false);
  };
  const handleOnSelectImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files?.[0];
    if (files) {
      const paymentReceiptUrl = await readFileAsDataURL(files);
      setPaymentReceipt(paymentReceiptUrl);
    }
  };
  const removeReceipt = () => {
    setPaymentReceipt("");
  };
  const id = `selectImageInput${index}`;
  return (
    <div className="installment-item">
      {!paymentReceipt ? (
        <div className="installment-info">
          <span className="installment-amount">
            <span className="rupee-sign-container">&#8377; </span>
            <span className="amount-container">{installment.amount}</span>
          </span>
          <div className="installement-info-container">
            <span className="installment-elem">
              Pay by:
              {new Date(installment?.dueDate).toDateString().slice(4)}
            </span>
          </div>
          <div className="file-input-wrapper">
            <input
              key={index}
              id={id}
              type="file"
              accept="image/*"
              className="file-input"
              onChange={handleOnSelectImage}
              disabled={isPending||isApproved}
            />
            {isApproved ? (
              <CheckedIcon width="50" height="50" />
            ) : (
              <label
                className={`file-input-label ${isPending && "disabled"}`}
                htmlFor={id}
              >
                Select Receipt
              </label>
            )}
          </div>
        </div>
      ) : (
        <div className="image-preview-outer-container">
          <div className="cross-icon-container" onClick={removeReceipt}>
            <CrossIcon height={20} width={20} />
          </div>
          <div className="image-preview-container">
            <img
              src={paymentReceipt.toString()}
              alt="img"
              className="receipt-preview"
              onClick={() => openImagePreview(paymentReceipt.toString())}
            />
          </div>
        </div>
      )}
      {paymentReceipt && (
        <Button
          className={`pay-now-button ${
            !paymentReceipt ? "disabled" : "enabled"
          }`}
          text={t("Pay")}
          isDisabled={!paymentReceipt}
          onClick={handleOnClick}
          isLoading={isLoading}
          key={installment._id}
        />
      )}
      {isImagePreviewOpen && (
        <div className="image-preview-modal" onClick={closeImagePreview}>
          <img
            className="preview-image"
            src={selectedImage || ""}
            alt="Image Preview"
          />
        </div>
      )}
    </div>
  );
};

export default InstallmentItem;
