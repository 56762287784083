import { useEffect } from "react";
import { useQuestions } from "../../redux/actions/questionAction";
import "./topicSubtopicPage.scss";
import { useVideos } from "../../redux/actions/videosAction";
import { useNotes } from "../../redux/actions/notesAction";
import useHeader from "../../redux/actions/headerAction";
export const TopicSubtopicPage: React.FC = () => {
  const { getAllQuestionByTopicSubtopicCount, questionCountData } =
    useQuestions();
  const { getAllVideosByTopicSubtopicCount, videosCountData } = useVideos();
  const { getAllNotesByTopicSubtopicCount, notesCountData } = useNotes();
  const { updateHeader } = useHeader();

  useEffect(() => {
    const allTopicSubtopicApi = [
      getAllNotesByTopicSubtopicCount(),
      getAllQuestionByTopicSubtopicCount(),
      getAllVideosByTopicSubtopicCount(),
    ];
    Promise.all(allTopicSubtopicApi);
  }, []);

  useEffect(() => {
    updateHeader({ title: "Topics/Subtopics Page", options: [] });
  }, []);

  return (
    <div className="topic-subtopic-wrapper-container">
      <div className="topic-subtopic-container">
        <div className="topic-subtopic-table-container"></div>
      </div>
    </div>
  );
};
