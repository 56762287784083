import React, { MutableRefObject, useRef, useState } from "react";
import "./installmentList.scss";
import { useUser } from "../../redux/actions/userAction";
import InstallmentItem from "../../components/installmentItem/installmentItem";
import {status as statusName} from "../../utils/constants"

type StringBooleanObject = {
  [key: string]: string;
};
const InstallmentList: React.FC<InstallmentListProps> = ({
  allInstallment,
  userIntsallment,
  userFeePlan,
  setIsLoading,
  isLoading,
}) => {
  const { createUserPayment } = useUser();
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const openImagePreview = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setIsImagePreviewOpen(true);
  };
  const closeImagePreview = () => {
    setSelectedImage(null);
    setIsImagePreviewOpen(false);
  };
  const allIntalmentPaymentObj: Record<string, AllIntalmentPaymentObj> = {};
  allInstallment?.forEach((installment: Installment) => {
    allIntalmentPaymentObj[installment?._id?.toString() || ""] = {
      ...installment,
      userIntsallmentList: [],
    };
  });
  userIntsallment?.forEach((installment) => {
    const { installmentId } = installment;
    if (allIntalmentPaymentObj[installmentId ?? ""]) {
      const curentInstallment = allIntalmentPaymentObj[installmentId ?? ""];
      curentInstallment?.userIntsallmentList?.push(installment);
      allIntalmentPaymentObj[installmentId ?? ""] = curentInstallment;
    }
  });
  const paymentInstellmentId = Object.keys(allIntalmentPaymentObj);
  const handlePayNow = async (
    installment: Installment,
    paymentReceipt: string | ArrayBuffer
  ) => {
    if (paymentReceipt !== null) {
      try {
        setIsLoading && setIsLoading(true);
        const input: UserPaymentInputType = {
          batch: userFeePlan?.batchCode,
          feePlan: userFeePlan?._id,
          imageUrl: String(paymentReceipt),
          installmentId: installment._id ?? "",
        };
        const response = await createUserPayment(input);

        if (response?.data && setIsLoading) {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error submitting payment receipt", error);
      }
    } else {
      console.log("Please select a payment receipt");
    }
  };
  const statusMap: Record<string, string> = {
    APPROVED: "approved",
    REJECTED: "rejected",
    PENDING: "pending",
  };
  const approvedInstallment = useRef<StringBooleanObject>({});  
  
  return (
    <div className="installment-list-container">
      {paymentInstellmentId.map((id, index) => {
        const installmentData = allIntalmentPaymentObj[id];
        return (
          <React.Fragment key={index}>
            <div className="installment-container">
              <div className="intalment-side-container">
                <InstallmentItem
                  handlePayNow={handlePayNow}
                  installment={installmentData}
                  index={index}
                  isApproved={
                    approvedInstallment.current[id ?? ""] ===
                    statusName.APPROVED
                  }
                  isPending={
                    approvedInstallment.current[id ?? ""] ===
                    statusName.PENDING
                  }
                />
              </div>
              <div className="installment-meta-wrapper">
                <div className="installment-meta-content-wrapper">
                  <div className="meta-wrapper-container">
                    <span className="meta-tab-container">
                      <span className="tab-header">Status</span>
                      <span className="tab-header">Payment date</span>
                      <span className="tab-header">Uploaded Receipt</span>
                      <span className="tab-header">Approved date</span>
                      <span className="tab-header">Approved Receipt</span>
                    </span>
                  </div>
                  <div className="content-wrapper">
                    {installmentData?.userIntsallmentList?.map(
                      (installment: UserPaymentSchemaType) => {
                        const status =
                          installment?.status?.paymentStatus?.tagName;
                        if (
                          status === statusName.APPROVED ||
                          status === statusName.PENDING
                        ) {
                          approvedInstallment.current[id ?? ""] = status;
                        }

                        return (
                          <div className="content-container">
                            <div
                              className={`payment-status-container ${
                                statusMap[status ?? ""]
                              }`}
                            >
                              <span className="status-tag">{status}</span>
                            </div>
                            <div className="payment-status-container">
                              {installment?.createdAt &&
                                new Date(installment?.createdAt)
                                  .toDateString()
                                  .slice(4)}
                            </div>
                            <div className="image-preview-container">
                              <img
                                src={installment?.image?.secureUrl}
                                alt=""
                                className="receipt-preview"
                                onClick={() =>
                                  openImagePreview(
                                    installment?.image?.secureUrl
                                  )
                                }
                              />
                            </div>
                            <div className={`payment-status-container`}>
                              {installment?.updatedAt && status !== "PENDING"
                                ? new Date(installment?.updatedAt)
                                    .toDateString()
                                    .slice(4)
                                : "--"}
                            </div>

                            <div className={`image-preview-container`}>
                              {status === "APPROVED" &&
                              installment?.receipt?.secureUrl ? (
                                <img
                                  src={installment?.receipt?.secureUrl}
                                  alt=""
                                  className="receipt-preview"
                                  onClick={() =>
                                    openImagePreview(
                                      installment?.receipt?.secureUrl
                                    )
                                  }
                                />
                              ) : (
                                "--"
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
      {isImagePreviewOpen && (
        <div className="image-preview-modal" onClick={closeImagePreview}>
          <img
            className="preview-image"
            src={selectedImage || ""}
            alt="Image Preview"
          />
        </div>
      )}
    </div>
  );
};

export default InstallmentList;
