import { apolloClient } from "../../../graphql/apolloClient/apolloClient";
import { UPDATE_USER_GOAL_STATUS } from "../../../graphql/mutation/admin/goal/userGoalCompletion/updateUserGoalCompletion";
export const updateUserGoalStatus = async ({ goalId,status,userId,goalStatusUpdateFeedback}: UpdateUserGoalStatusType) => {
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_USER_GOAL_STATUS,
      variables: {
        updateInput: {
          goalId,
          status,
          userId,
          goalStatusUpdateFeedback
        },
      },
    });
    return response?.data?.updateUserGoalStatus;
  } catch (err) {
    console.error(err);
  }
};
