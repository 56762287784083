export const getBatches = (batchData:[]) => {
  const batchMap: Record<string,string> = {}; 
    const batches = batchData?.map(({batchCode, _id}: { [key: string]: string }) => {
      batchMap[_id] = batchCode;
      return {
        text: batchCode,
        value: _id,
      };
    });
    return {batches, batchMap}
}