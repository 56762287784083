import { createSlice } from "@reduxjs/toolkit";

const initialState: TopicData[] = [];

const videosTopicSubtopicSlice = createSlice({
  name: "videosTopicSubtopic",
  initialState: initialState,
  reducers: {
    setVidoesTopicSubtopic: (state, action) => {
      const videosData = action.payload;
      return videosData;
    },
  },
});
export const {
  actions: videosTopicSubtopicAction,
  reducer: videosTopicSubtopicReducer,
} = videosTopicSubtopicSlice;
export const topicSubtopicVideosCountData = (state: {
  videosTopicSubtopicCount: TopicData[];
}) => state.videosTopicSubtopicCount;
export default videosTopicSubtopicSlice.reducer;
