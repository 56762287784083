import React from 'react'
import "./Landingpage.scss";
import HeadNavBar from '../HeadNavBar/HeadNavBar'
import HomePageHeaderSection from '../HomePageHeaderSection.tsx/HomePageHeaderSection';
interface NavRefs {
  homeRef: React.RefObject<HTMLDivElement>;
  tutorialsRef: React.RefObject<HTMLDivElement>;
  coursesRef: React.RefObject<HTMLDivElement>;
  topicsRef: React.RefObject<HTMLDivElement>;
  pricingRef: React.RefObject<HTMLDivElement>;
  integrationRef: React.RefObject<HTMLDivElement>;
  aboutUsRef: React.RefObject<HTMLDivElement>;
  elevateRef: React.RefObject<HTMLDivElement>;
  technologyRef: React.RefObject<HTMLDivElement>;
  blogRef: React.RefObject<HTMLDivElement>;
  faqRef: React.RefObject<HTMLDivElement>;
  footerRef: React.RefObject<HTMLDivElement>;
}

interface LandingPageProps {
  navRefs: NavRefs;
}

export const LandingPage: React.FC<LandingPageProps> = ({ navRefs }) => {
  return (
    <div className='landing-page-container' ref={navRefs.homeRef}>
      <HeadNavBar  navRefs ={navRefs} />
      <HomePageHeaderSection/>
     
    </div>
  )
}
