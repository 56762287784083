import "./createUserGoals.scss";
import { Modal } from "../../../components/Modal/modal";
import { Button } from "../../../components/button/button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateGoalComponent } from "../../../components/createGoalCompoent/createGoalComponent";

export const CreateUserGoals: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleOnAddGoalClick = () => {
    setIsModalOpen(true);
  };
  const { t } = useTranslation();
  const handleOnCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="create-goal-wrapper-container">
      <div className="create-goal-header-button-wrapper">
        <h1 className="create-goal-header">Create Goal</h1>
        <div className="add-goal-button-wrapper">
          <Button
            text="Add Goal"
            className="add-goal-button"
            onClick={handleOnAddGoalClick}
          />
        </div>
      </div>
      <Modal className="goal-modal-wrapper" isOpen={isModalOpen}>
        <CreateGoalComponent onClose={handleOnCloseModal} />
      </Modal>
    </div>
  );
};
