import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserGoals } from "../../../redux/actions/getUserGoalsAction";
import { updateUserGoalStatus } from "../../../redux/actions/admin/updateUserGoalStatus";
import {
  Modal, Button, GoalHistoryTable,
  RejectGoalFeedback, UserResponseCard,
} from "../../../components/index";
import Spinner from "../../../components/spinner/spinner";
import RichTextEditor from "../../../components/richTextEditor/richTextEditor";
import { goalStatus, NOT_AVAILABLE, } from "../../../utils/index";
import "./userGoalResponse.scss";

export const UserGoalResponse = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [goalFeedback, setGoalFeedback] = useState<string>("");
  const [loadingInfo, setLoadingInfo] = useState({
    loaderType: "",
    isLoading: false,
  });

  const navigate = useNavigate();
  const { state } = useLocation();
  const { id = "" } = state || {};
  const { userGoals, getUserGoalById } = useUserGoals();
  const { userGoal, isUserGoalLoading } = userGoals || {};
  const { 
    goalId, userId, userResponse, 
    userGoalStatus, currentGoalStatus,
  } = userGoal || {};

  const handleOnSubmissionAction = async (rejectAction: boolean = true) => {
    setLoadingInfo({
      loaderType: rejectAction ? goalStatus.REJECTED : goalStatus.COMPLETED,
      isLoading: true,
    });
    try {
      if (!goalFeedback && rejectAction) {
        window.alert("Please Enter Goal Feedback");
        setLoadingInfo({ ...loadingInfo, isLoading: false });
      } else {
        const { response } = await updateUserGoalStatus({
          goalId: goalId?._id,
          userId: userId?._id,
          status: rejectAction ? goalStatus.REJECTED : goalStatus.COMPLETED,
          goalStatusUpdateFeedback: rejectAction ? goalFeedback : "",
        });
        window.alert(response?.message);
        if (response?.status === 200) {
          setIsModalOpen(false);
          !rejectAction && setIsCompleted(true);
        }
      }
    } catch (err) {
      console.error(err);
      window.alert(`Failed to ${rejectAction ? "Reject" : "Approve"} goal`);
    } finally {
      setLoadingInfo({ ...loadingInfo, isLoading: false });
    }
  };

  const handleOnRejectGoal = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  useEffect(() => {
    id ? getUserGoalById({id}) : navigate("/admin_panel");
  }, [id,isCompleted]);

  useEffect(() => {
    currentGoalStatus?.status?.tagKey &&
      setIsCompleted(currentGoalStatus?.status?.tagKey === goalStatus.COMPLETED);
  }, [currentGoalStatus]);
  return (
    <div className="user-response-page-main-container">
      {isUserGoalLoading ? (
        <Spinner />
      ) : (
        <div className="user-response-page-sub-container">
          <div className="user-response-goal-and-user-data-wrapper">
            {goalId && (
              <div className="user-response-card-data-container">
                <div className="user-response-card-title">Goal Info</div>
                <div className="user-response-card-data-content-container">
                  <UserResponseCard label="Title">
                    {goalId?.title || NOT_AVAILABLE }
                  </UserResponseCard>
                  {goalId?.description && 
                  <UserResponseCard label="Description">
                    <RichTextEditor
                      value={goalId.description}
                      editable={false}
                    />
                  </UserResponseCard>}
                  <UserResponseCard label="Labels">
                    {goalId?.userResponseLabels?.length
                      ? goalId?.userResponseLabels?.map(({ label, value }) => (
                          <span>{label}</span>
                        ))
                      : NOT_AVAILABLE}
                  </UserResponseCard>
                  <UserResponseCard label="Created At">
                    {goalId?.createdAt
                      ? new Date(goalId?.createdAt).toLocaleString()
                      : NOT_AVAILABLE}
                  </UserResponseCard>
                </div>
              </div>
            )}
            {userId && (
              <div className="user-response-card-data-container">
                <div className="user-response-card-title">User Info</div>
                <div className="user-response-card-data-content-container">
                  <UserResponseCard label="Name">
                    {userId?.name || NOT_AVAILABLE}
                  </UserResponseCard>
                  <UserResponseCard label="Email">
                    {userId?.email || NOT_AVAILABLE}
                  </UserResponseCard>
                  <UserResponseCard label="BatchCode">
                    {userId?.batchCode || NOT_AVAILABLE}
                  </UserResponseCard>
                  <UserResponseCard label="Phone Number">
                    {userId?.phoneNumber || NOT_AVAILABLE}
                  </UserResponseCard>
                </div>
              </div>
            )}
            {Boolean(userGoalStatus?.length) && (
              <div className="user-response-card-data-container">
                <div className="user-response-card-title">Status History</div>
                <div className="user-response-card-data-content-container">
                  <UserResponseCard
                    label=""
                    className="user-response-page-status-history-container"
                  >
                    {userGoalStatus
                      ?.map((goalStatus, index) => (
                        <GoalHistoryTable
                          goalStatusRecord={goalStatus}
                          prevStatus={
                            index === 0
                              ? ""
                              : userGoalStatus[index - 1].status?.tagName
                          }
                          lastIndex={index === userGoalStatus.length - 1}
                        />
                      ))
                      .reverse()}
                  </UserResponseCard>
                </div>
              </div>
            )}
            {userResponse && userResponse?.length > 0 && (
              <div className="user-response-card-data-container">
                <div className="user-response-card-title">{`Total Responses ${userResponse.length}`}</div>
                <div className="user-response-card-data-content-container">
                  {userResponse.map(({ labels, response, time }, idx) => (
                    <div>
                      {userResponse.length > 1 && <span>{idx + 1}</span>}
                      <UserResponseCard label="Response">
                        {response || NOT_AVAILABLE}
                      </UserResponseCard>
                      <UserResponseCard label="">
                        {labels?.map(({ label, value }) => (
                          <UserResponseCard label={label}>
                            {value || NOT_AVAILABLE}
                          </UserResponseCard>
                        ))}
                      </UserResponseCard>
                      <UserResponseCard label="Time">
                        {time ? new Date(time).toLocaleString() : NOT_AVAILABLE}
                      </UserResponseCard>
                      {userResponse.length > 1 &&
                        idx !== userResponse.length - 1 && (
                          <div className="content-separator"></div>
                        )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          {!isCompleted && (
            <div className="user-goal-response-button-wrapper">
              <Button
                className="approve-button goal-status-button"
                isLoading={
                  loadingInfo?.loaderType === goalStatus?.COMPLETED &&
                  loadingInfo?.isLoading
                }
                iconPosition="center"
                text="Approve"
                onClick={()=>handleOnSubmissionAction(false)}
              ></Button>
              <Button
                className="reject-button goal-status-button"
                text="Reject"
                onClick={handleOnRejectGoal}
              />
            </div>
          )}
          {
            <Modal className="reject-goal-modal" isOpen={isModalOpen}>
              <RejectGoalFeedback
                onClose={()=>setIsModalOpen(false)}
                isLoading={
                  loadingInfo?.loaderType === goalStatus?.REJECTED &&
                  loadingInfo?.isLoading
                }
                onChange={setGoalFeedback}
                handleOnRejectGoal={handleOnSubmissionAction}
              />
            </Modal>
          }
        </div>
      )}
    </div>
  );
};
