import * as React from "react";
export const ItalicIcon: React.FC<SvgIconProps> = ({
  height = "25px",
  width = "25px",
  fillColor = "#fff",
}: SvgIconProps) => (
  <svg
    fill="#000000"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    id="italic"
    data-name="Flat Color"
    xmlns="http://www.w3.org/2000/svg"
    className="icon flat-color"
  >
    <path
      id="primary"
      d="M8,22a1,1,0,0,1,0-2H9.65L12.32,4H11a1,1,0,0,1,0-2h5a1,1,0,0,1,0,2H14.35L11.68,20H13a1,1,0,0,1,0,2Z"
      style={{
        fill: fillColor,
      }}
    />
  </svg>
);