import { useEffect, useState } from "react";
import { Select } from "../select/select";
import { useBatch } from "../../redux/actions/batchAction";
import { getBatches } from "../../utils/getBatches";
import { useGoals } from "../../redux/actions/goalsAction";
import { useWeek } from "../../redux/actions/scheduleAction";
import { selectBooleanType } from "../../utils/constants";
import "./goalSelectContainer.scss";
import { InputComponent } from "../../components/input/inputComponent";
import { Button } from "../../components/button/button";
import { generateCamelCase } from "../../utils/index";

export const GoalSelectContainer: React.FC<{
  setGoalData: React.Dispatch<React.SetStateAction<CreateGoalType | undefined>>;
  goalData: CreateGoalType | undefined
}> = ({ setGoalData, goalData }) => {
  const { getWeekList } = useWeek();
  const { getBatchCode } = useBatch();
  const { getGoalTypeList } = useGoals();
  const [batchesList, setBatchesList] = useState<SelectOptionType[]>();
  const [batchMap, setBatchMap] = useState<Record<string,string>>({});
  const [weekListData, setWeekListData] = useState<SelectOptionType[]>();
  const [goalListData, setGoalListData] = useState<SelectOptionType[]>();
  const [userResponseLabels, setUserResponseLabels] = useState<Record<string,string>>({});
  const [userResponseLabelsInput, setUserResponseLabelsInput] = useState<string>("");
  const [endWeek, setEndWeek] = useState<SelectOptionType[]>();

  const getBatchCodeList = async () => {
    const response = await getBatchCode();
    const {batches,batchMap} = getBatches(response?.data?.getBatchCode?.batchData);
    setBatchMap(batchMap);
    setBatchesList(batches);
  };
  const getWeekLists = async (batchCode: string) => {
    const { weekList } = await getWeekList(batchCode);
    const weekData = weekList as WeekListType[];
    setWeekListData(
      weekData.map((week) => {
        return { text: week.weekNumber, value: week._id };
      })
    );
  };
  const getGoalTypeLists = async () => {
    const { goalList } = await getGoalTypeList();
    const goals = goalList as GoalTypeList[];
    setGoalListData(
      goals.map((goalType) => {
        return { text: goalType.goalType, value: goalType._id };
      })
    );
  };
  useEffect(() => {
    getBatchCodeList();
    getGoalTypeLists();
  }, []);

  useEffect(()=>{
    getWeekLists(batchMap[goalData?.batchCode??'']);
  },[goalData?.batchCode]);

  const handleOnSetStartWeek = (option: SelectOptionType) => {
    setGoalData((goalData) => {
      return { ...goalData, startWeek: option.value };
    });
    const data = weekListData?.filter((week) => {
      return Number(week.text) >= Number(option.text);
    });
    setEndWeek(data);
  };
  const handleOnSetEndWeek = (option: SelectOptionType) => {
    setGoalData((goalData) => {
      return { ...goalData, endWeek: option.value };
    });
  };
  const handleOnSetBatch = (option: SelectOptionType) => {
    setGoalData((goalData) => {
      return { ...goalData, batchCode: option.value };
    });
  };
  const handleOnSetIsLive = (option: SelectOptionType) => {
    setGoalData((goalData) => {
      return { ...goalData, isLive: option.value === "true" };
    });
  };
  const handleOnSetIsManadatory = (option: SelectOptionType) => {
    setGoalData((goalData) => {
      return { ...goalData, isMandatory: option.value === "true" };
    });
  };
  const handleOnSetGoalType = (option: SelectOptionType) => {
    setGoalData((goalData) => {
      return { ...goalData, goalType: option.value };
    });
  };
  const handleOnSetIsActive = (option: SelectOptionType) => {
    setGoalData((goalData) => {
      return { ...goalData, isActive: option.value==="true" };
    });
  };

  const setGoalDataHandler = (newUserResponseLabels: Record<string, string>) => {
    const newUserResponseLabelsArr = Object.entries(newUserResponseLabels).map(([key,val])=>({label:val,value:key}));
    setGoalData(goalData=>({...goalData,userResponseLabels:newUserResponseLabelsArr}));
    setUserResponseLabels(newUserResponseLabels);
  }
  const handleSetUserResponseLabels = () => {
    const labelKey = generateCamelCase(userResponseLabelsInput);
    const newUserResponseLabels = {...userResponseLabels,[labelKey]:userResponseLabelsInput.trim()}
    setGoalDataHandler(newUserResponseLabels);
    setUserResponseLabelsInput("");
  }

  const handleUserResponseDeletion = (label: string) => {
    const newUserResponseLabels = {...userResponseLabels};
    delete newUserResponseLabels[label];
    setUserResponseLabels(newUserResponseLabels);
  }
  

  return (
    <div className="wrapper-container">
      <div className="create-goal-select-wrapper">
        <label htmlFor="batch-code" className="create-goal-label">
          Select batch code :
        </label>
        <Select
          className="create-goal-select"
          key={"batch-code"}
          data={batchesList}
          defaultSelected="Select Batch"
          onSelect={handleOnSetBatch}
          isRequired
        ></Select>
      </div>
      <div className="create-goal-select-wrapper">
        <label htmlFor="batch-code" className="create-goal-label">
          Select start week :
        </label>
        <Select
          className="create-goal-select"
          key={"start-week"}
          data={weekListData}
          defaultSelected="Select start week"
          onSelect={handleOnSetStartWeek}
          isRequired
        ></Select>
      </div>
      <div className="create-goal-select-wrapper">
        <label htmlFor="batch-code" className="create-goal-label">
          Select end week :
        </label>
        <Select
          className="create-goal-select"
          key={"end-week"}
          data={endWeek}
          defaultSelected="Select end week"
          onSelect={handleOnSetEndWeek}
          isDisabled={!endWeek?.length}
          isRequired
        ></Select>
      </div>
      <div className="create-goal-select-wrapper">
        <label htmlFor="batch-code" className="create-goal-label">
          Select goal type :
        </label>
        <Select
          className="create-goal-select"
          key={"goal-type"}
          data={goalListData}
          onSelect={handleOnSetGoalType}
          defaultSelected="Select Goal Type"
        ></Select>
      </div>
      <div className="create-goal-select-wrapper">
        <label htmlFor="batch-code" className="create-goal-label">
          Select mandatory status :
        </label>
        <Select
          className="create-goal-select"
          key={"is-mandatory"}
          data={selectBooleanType}
          onSelect={handleOnSetIsManadatory}
          defaultSelected="Select mandatory"
        ></Select>
      </div>
      <div className="create-goal-select-wrapper">
        <label htmlFor="batch-code" className="create-goal-label">
          Select live status :
        </label>
        <Select
          className="create-goal-select"
          key={"is-live"}
          data={selectBooleanType}
          onSelect={handleOnSetIsLive}
          defaultSelected="Select live"
        ></Select>
      </div>
      <div className="create-goal-select-wrapper">
        <label className="create-goal-label">
          Select Active status :
        </label>
        <Select
          className="create-goal-select"
          key={"is-active"}
          data={selectBooleanType}
          onSelect={handleOnSetIsActive}
          defaultSelected="Select Active"
        ></Select>
      </div>
      <div className="create-goal-select-wrapper">
        <label className="create-goal-label">
          Create User Response Labels:<span className="create-goal-label-required">*</span>
        </label>
        <form onSubmit={(e)=>e.preventDefault()} className="user-goal-response-form">
          <InputComponent 
            type="text" 
            value={userResponseLabelsInput} 
            onChange={(e)=>setUserResponseLabelsInput(e.target.value)} 
            className="user-goal-response-labels-input"
          />
          <Button 
            onClick={handleSetUserResponseLabels}
            text="Add"
            className="user-goal-response-labels-button"
            isDisabled={!userResponseLabelsInput}
          />
        </form>
        <div className="user-goal-response-labels-list">
          {
            Object.entries(userResponseLabels)?.map(([value,label])=>(
              <span className="user-goal-response-label-wrapper">
                <span className="user-goal-response-label">{label}</span>
                <span 
                  className="user-goal-response-label-cancel-button" 
                  onClick={()=>handleUserResponseDeletion(value)}
                  >x</span>
              </span>
            ))
          }
        </div>
      </div>
      
    </div>
  );
};
