import React, { useRef } from "react";
// import FaqComponent from "../../Components/FaqComponent/FaqComponent";
import CoursePage from "../../Components/CoursePage/CoursePage";
// import Footer from "../../Components/Footer/Footer";
// import PreviewImages from "../../Components/PreviewImages/PreviewImages";
// import TopBanner from "../../Components/TopBanner/TopBanner";
// import Testimonials from "../../Components/Testimonials/Testimonials";
// import Banner from "../../Components/Banner/Banner";
import "./Homepage.scss";
// import { localMessages } from "../../helpers/constants/localMessages";
import { FooterInfoPanel } from "../../../components/footerInfoPanel/footerInfoPanel";
import { TechnologyOverview } from "../../Components/TechnologyOverview/TechnologyOverview";
import { AboutUsComponent } from "../../Components/AboutUsComponent/AboutUsComponent";
import { PricingInfo } from "../../Components/PricingInfo/PricingInfo";
import { TutorialsSection } from "../../../components/tutorial/tutorial";
import { Topics } from "../../../components/topics/topics";
import { IntegrationSection } from "../../../components/integrations/integrations";
import { BlogSection } from "../../../components/blog/blog";
import { FaqPage } from "../../../components/faq/faq";
import { ElevatePage } from "../../Components/ElevatePage/ElevatePage";
import { LandingPage } from "../../Components/NewComponents/LandingPage/Landingpage";
// import JobBoard from "../../Components/JobBoard/JobBoard";

const Homepage = () => {

  const landingRef = useRef(null);
  const tutorialsRef = useRef(null);
  const coursesRef = useRef(null);
  const topicsRef = useRef(null);
  const pricingRef = useRef(null);
  const integrationRef = useRef(null);
  const aboutUsRef = useRef(null);
  const elevateRef = useRef(null);
  const technologyRef = useRef(null);
  const blogRef = useRef(null);
  const faqRef = useRef(null);
  const footerRef = useRef(null);  

  const navRefs = {
    homeRef: landingRef,
    tutorialsRef: tutorialsRef,
    coursesRef: coursesRef,
    topicsRef: topicsRef,
    pricingRef: pricingRef,
    integrationRef: integrationRef,
    aboutUsRef: aboutUsRef,
    elevateRef: elevateRef,
    technologyRef: technologyRef,
    blogRef: blogRef,
    faqRef: faqRef,
    footerRef: footerRef,
  };

  return (
    // <>
    //   {/* <Banner
    //     className={`bannerWrapperContainer`}
    //     text={localMessages.BANNER_NEXT_BATCH_TEXT}
    //   /> */}
    //   <LandingPage/>
    //   {/* <PreviewImages /> */}
    //   {/* <TopBanner /> */}
    //   <TutorialsSection/>
    //   <CoursePage />
    //   <Topics/>
    //   <PricingInfo/>
    //   <IntegrationSection/>
    //   {/* <Testimonials /> */}
    //   <AboutUsComponent/>
    //   <ElevatePage/>
    //   <TechnologyOverview/>
    //   <BlogSection/>
    //   {/* <JobBoard /> */}
    //   {/* <FaqComponent /> */}
    //   <FaqPage/>
    //   <FooterInfoPanel />
    // </>
    <>
    <LandingPage navRefs={navRefs} />
    <TechnologyOverview navRef={technologyRef} />
    <BlogSection navRef={blogRef} />
    <CoursePage navRef={coursesRef} />
    <IntegrationSection navRef={integrationRef} />
    <Topics navRef={topicsRef} />
    <ElevatePage navRef={elevateRef} />
    <AboutUsComponent navRef={aboutUsRef} />
    <TutorialsSection navRef={tutorialsRef} />
    <PricingInfo navRef={pricingRef} />
    <FaqPage navRef={faqRef} />
    <FooterInfoPanel navRef={footerRef} />
  </>
  );
};

export default Homepage;
