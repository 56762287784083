import "./githubFilesContainer.scss";
import { InputComponent } from "../input/inputComponent";
import { useDispatch, useSelector } from "react-redux";
import { createQuestionActions } from "../../redux/slices/createQuestion/createQuestionSlice";

export const GithubFilesContainer = ({ prevPath }: { prevPath: string }) => {
  const dispatch = useDispatch();
  const { updateState } = createQuestionActions;
  const handleOnFileContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const path = `${prevPath}.content`;
    dispatch(updateState({ path, value: e.target.value }));
  };
  const handleOnFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const path = `${prevPath}.fileName`;
    dispatch(updateState({ path, value: e.target.value }));
  };

  return (
    <div className="files-container">
      <div className="file-name-input-wrapper">
        <label className="file-name-label">File Name :</label>
        <InputComponent
          className="file-name-input"
          type="text"
          key={"file-name"}
          onChange={handleOnFileName}
          placeholder="File Name"
          disabled={false}
        />
      </div>
      <div className="issue-body-input-wrapper">
        <label className="issue-label">File Content :</label>
        <textarea
          rows={5}
          className="description-text-conatiner"
          onChange={handleOnFileContent}
        ></textarea>
      </div>
    </div>
  );
};
