import { RightIcon } from "../../icons/rightIcon";
import "./dashboardAssignmentComponent.scss";
// Dummy data for the task list
const tasks: Task[] = [
  // { id: 1, title: 'Presentation', dueDate: '02/07', status: 'Upcoming' },
  // { id: 2, title: 'Do Research', dueDate: '30/06', status: 'Pending' },
  // { id: 3, title: 'Practice work', dueDate: '24/06', status: 'Graded', grade: 'A' },
  // { id: 4, title: 'Create a webpage for toys for toys', dueDate: '20/06', status: 'Graded', grade: 'A+' },
  // { id: 5, title: 'Read the article', dueDate: '19/06', status: 'Submitted' },
];

export const DashboardAssignmentComponent: React.FC = () => {
  return (
    <div className="dashboard-assignment-component-container">
      <div className="current-assignment-header">
        Assignment <RightIcon fillColor="#fff" height="14" width="14" />
      </div>
      <div className="task-list-container">
      {tasks?.map((task) => (
        <div key={task.id} className="task-item">
          <div className="task-title">
            <span>{task.title}</span>
          </div>
          <div className="status-date-container">
          <div className="task-date">{task.dueDate}</div>
          <div className={`task-status ${task.status.toLowerCase()}`}>
            {task.status === 'Graded' ? task.grade : task.status}
          </div>
          </div>
        </div>
      ))}
    </div>

    </div>
  );
};
