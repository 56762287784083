import React from 'react'
import './TechnologyOverview.scss'
import technologiesImage from "../../assets/images/technologiesImage.png"
import html from "../../assets/images/htmlIcon.svg"
import css from "../../assets/images/cssIcon.svg"
import javaScript from "../../assets/images/javascriptIcon.svg"
import react from "../../assets/images/reactIcon.svg"
import nodeJs from "../../assets/images/nodejsIcon.svg"
import mongoDB from "../../assets/images/mongoDbIcon.svg"
import github from "../../assets/images/githubIcon.svg"
import next from "../../assets/images/nextjsIcon.svg"
import postgresql from '../../assets/images/postgresqlIcon.svg'
import aws from'../../assets/images/awsIcon.svg'
import FlagIcon from '../../assets/icons/FlagIcon'
interface TechnologyOverviewProps {
    navRef: React.RefObject<HTMLDivElement>;
}
export const TechnologyOverview: React.FC<TechnologyOverviewProps> = ({navRef}) => {
    const leftTechnologyList = [
        { icon: html, technology: "HTML", text: "Ever wonder how search engines understand your web pages? HTML’s semantic structure is key to making your content discoverable and accessible." },
        { icon: css, technology: "CSS (with SCSS, TailwindCSS)", text: "Designing Netflix-level UI? With SCSS and TailwindCSS, you'll create pixel-perfect, responsive interfaces in a fraction of the time."},
        { icon: javaScript, technology: "JavaScript", text: "Power dynamic experiences like Google Docs—JavaScript is the brain behind real-time updates and user interactions." },
        { icon: react, technology: "ReactJs (with Redux)", text: "Building Instagram-level interactivity? React with Redux helps manage complex UI states seamlessly across your entire app." },
        { icon: next, technology: "NextJs", text: "Want your web app to load like lightning? Next.js delivers server-side rendering, giving you the speed and SEO edge of apps like Airbnb." },
    ]
    const rightTechnologyList = [
        { icon: nodeJs, technology: "NodeJs", text: "Ever wonder how backend systems like Uber process millions of requests per second? Node.js handles real-time data with ease." },
        { icon: mongoDB, technology: "MongoDB", text: "Curious how e-commerce giants manage millions of products? MongoDB’s flexible document model lets you store massive amounts of data effortlessly." },
        { icon: postgresql, technology: "PostgreSQL", text: "Need Amazon-level transaction speeds? PostgreSQL’s advanced querying powers high-performance, scalable web apps."},
        { icon: github, technology: "Git and GitHub", text: "Ever collaborate on a massive project with teammates across the world? GitHub keeps everyone in sync with powerful version control." },
        { icon: aws, technology: "AWS & Deployment", text: "Think deploying apps like Spotify to millions of users sounds complex? AWS makes scaling and managing infrastructure a breeze." },
    ]
    return (
        <div className='technology-overview-component'
        ref={navRef}
        >
            <div className='technology-overview-head'>
            <h4 className='technology-overview-head-h4'>
                    <FlagIcon/> Technology
                </h4>
                <h3 className='technology-overview-head-h3'>Learn the Tech That Employers Demand</h3>
                <p className='technology-overview-head-p'>During our Full Stack Development program we cover a bunch of latest, in-demand web development technologies.</p>
            </div>
            <div className='technology-overview-body'>
                <div className='technology-overview-body-column'>
                {leftTechnologyList.map(({technology,icon,text}) => (
                        <div className='technology-overview-body-column-row'>
                            <div className='technology-overview-body-column-row-top'>
                                <div className='technology-overview-body-column-row-top-icon-container'>
                                    <img className='technology-overview-body-column-row-top-icon' src={icon} alt="" />
                                </div>
                                <h5 className='technology-overview-body-column-row-top-headline'>{technology}</h5>
                            </div>
                            <div className='technology-overview-body-column-row-bottom'>
                                <p className='technology-overview-body-column-row-bottom-text'>{text}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='technology-overview-body-column'>
                    <img src={technologiesImage} alt="" />
                </div>
                <div className='technology-overview-body-column'>
                    {rightTechnologyList.map(({technology,icon,text}) => (
                        <div className='technology-overview-body-column-row'>
                            <div className='technology-overview-body-column-row-top'>
                                <div className='technology-overview-body-column-row-top-icon-container'>
                                <img className='technology-overview-body-column-row-top-icon' src={icon} alt="" />
                                </div>
                                <h5 className='technology-overview-body-column-row-top-headline'>{technology}</h5>
                            </div>
                            <div className='technology-overview-body-column-row-bottom'>
                                <p className='technology-overview-body-column-row-bottom-text'>{text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}