import { gql } from "@apollo/client";

export const GET_WEEK_LIST = gql`
  query GetWeekList($batchCode: String) {
    getWeekList(batchCode: $batchCode) {
      weekList {
        _id
        title
        weekNumber
        days {
          dayNumber
          weekNumber
        }
      }
      response {
        status
        message
      }
    }
  }
`;
