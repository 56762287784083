import { gql } from "@apollo/client";

export const REGISTER_USER = gql`
  mutation RegisterUser($data: RegistrationInputType!) {
    registerUser(data: $data) {
      response {
        status
        message
      }
      userData {
        sessionPreference
        phoneNumber
        name
        email
        # occupation
        # collegeName
        # isJobSeeker
        # expectedSalary
        # courseYear
        # course
        # branch
        # location
        # batchCode
        profileImage {
          publicId
          secureUrl
        }
      }
      credentials
    }
  }
`;
