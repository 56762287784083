import "./dashboardClassComponent.scss";
const currentClassData:ClassData[] = [ ];
export const DashboardClassComponent: React.FC = () => {
  return (
    <div className="dashboard-class-component-container">
      <div className="current-class-header">Class</div>
      <div className="current-class-wrapper">
        {currentClassData?.map((classData) => {
            const date = new Date(classData.date);
            const formattedDate = date.toDateString(); 
          return (
            <div className={`current-class-item ${classData.status}`}>
                <div className="current-class-topic-status-wrapper">
              <div className="current-class-topic">{classData.topic}</div>
              <div className={`current-class-status`}>
                <span className={`${classData.status==="upcoming"?"current-class-upcoming":"current-class-completed"}`}>
                  {classData.status}
                </span>
              </div>
              </div>
              <div className="class-data-container">
                <span className="class-data-topic">notes</span>
                <span className="class-data-topic">question</span>
                <span className="class-data-topic">video</span>
                <span className="class-data-date">{(formattedDate)}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
