import { gql } from "@apollo/client";

export const UPDATE_USER_GOAL_STATUS = gql`
  mutation UpdateUserGoalStatus($updateInput: UpdateUserGoalStatusInputType) {
    updateUserGoalStatus(updateInput: $updateInput) {
      currentGoalStatus {
        status {
          tagName
          tagKey
          tagType
        }
        time
        goalStatusUpdateFeedback
      }
      response {
        status
        message
      }
    }
  }
`;