import React, { ReactElement } from "react";

export const TooltipIcon: React.FC<IconProps> = ({
  width = 20,
  height = 20,
  fillColor = "#000",
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 256 256"
      fill={fillColor}
    >
      <defs></defs>
      <g transform="translate(18.12918287937744 18.12918287937741) scale(2.43 2.43)">
        <path
          d="M 49.083 71.489 l 5.776 -21.96 l 4.186 -15.247 c 3.497 -16.18 -32.704 -2.439 -38.002 1.695 l 0.425 4.853 c 4.824 -3.395 23.091 -7.744 19.449 4.275 l -1.634 6.135 l 0 0 l -8.329 31.071 c -3.497 16.18 32.704 2.439 38.002 -1.695 l -0.425 -4.853 C 63.708 79.159 45.441 83.508 49.083 71.489 z"
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <circle
          cx="53.871"
          cy="11.201"
          r="11.201"
          transform="  matrix(1 0 0 1 0 0) "
        />
      </g>
    </svg>
  );
};
