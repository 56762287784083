export const generateCamelCase = (string: string): string => {
    let newString = "";
    const stringArr = string.trim().split(" ");
    for(let i = 0; i<stringArr.length;i++){
        const word = stringArr[i].trim();
        if(word){
            if(i===0){
                newString = word.toLowerCase();
            }else {
                newString += (word.charAt(0).toUpperCase()) + (word.substring(1,word.length).toLowerCase());
            }
        }
    }
    return newString;
}