import { gql } from "@apollo/client";

export const GET_ALL_QUESTION_TOPIC_SUBTOPIC = gql`
  query GetAllQuestionsDataBySubTopics {
    getAllQuestionsDataBySubTopics {
      response {
        status
        message
      }
      questionData
    }
  }
`;
