import { useEffect, useState } from "react";
import "./goalTooltipContainer.scss";
import { UserGoalResponseComponent } from "../../components/userResponse/userResponse";
import { goalStyleByStatus } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import RichTextEditor from "../richTextEditor/richTextEditor";
import { GoalHistoryTable } from "../goalHistoryTable/goalHistoryTable";
import DropDownIcon from "../../icons/dropDownIcon";
import { useUserGoals } from "../../redux/actions/getUserGoalsAction";
import Spinner from "../../components/spinner/spinner";
import NoDataFoundComponent from "../../components/noDataFound/noDataFound";

export const GoalTooltipContainer : React.FC<TooltipChildrenProps> = (
  { id, className } : TooltipChildrenProps
) => {
  
  const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);
  const { userGoals, getUserGoalById } = useUserGoals();
  const {  userGoal, isUserGoalLoading } = userGoals || {};
  const { 
    goalId, currentGoalStatus, userGoalStatus,userResponse, profileType
  } = userGoal || {};

  const { status } = currentGoalStatus || {};
  const { description } = goalId || {};

  const [statusState, setStatusState ] = useState<string>();

  const setDefaultStatusState = () => {
    const defaultValue = currentGoalStatus ?
     currentGoalStatus?.status?.tagKey : '' ;
    setStatusState(defaultValue);
  }

  const { t } = useTranslation();

  const handleOnViewHistory = () => {
    setIsHistoryOpen((isHistoryOpen)=>!isHistoryOpen)
  }

  useEffect(()=>{
    id && getUserGoalById({id,latestResponse:true});
  },[statusState])

  useEffect(() => {
    setDefaultStatusState();
  }, [currentGoalStatus]);

  return (
    isUserGoalLoading ? 
    <Spinner/> : 
    (userGoal && Boolean(Object.keys(userGoal).length)) ? 
    <div className="tooltip-goal-container">
      {description && 
        <div className="tooltip-goal-description-wrapper">
          <label className="tooltip-goal-label">Description</label>
          <div className="tooltip-goal-description">
            <RichTextEditor value={description} editable={false} />
          </div>
        </div>
      }
      { 
        userGoalStatus && 
          <div className="goal-status-card-main-container">
            <div className="goal-current-status-wrapper">
              <span>Status : { status &&
                 <span 
                  className={`goal-current-status ${goalStyleByStatus[status?.tagKey]}`}
                 >
                  {status?.tagName}
                </span>
              }</span>
              <div onClick={handleOnViewHistory } className={`view-goal-history-button ${isHistoryOpen && "history-open"}`}><DropDownIcon/></div>
            </div>
            {
              isHistoryOpen && Array.isArray(userGoalStatus) && userGoalStatus?.map((goalStatus, index) => {
                let prevStatus = "";
                if (index !== 0) { 
                  prevStatus=userGoalStatus[index - 1].status?.tagName
                }
                return <GoalHistoryTable goalStatusRecord={goalStatus} prevStatus={prevStatus} lastIndex={index===userGoalStatus.length-1 } />;
              }).reverse()
                
            }
          </div>
      }
        <UserGoalResponseComponent 
          id={id} 
          profileType={goalId?.profileType}
          className="user-goal-response-container"
          responseData={userResponse?.at(-1)}
          userResponseLabels={goalId?.userResponseLabels}
          setStatusState={setStatusState}
          statusState={statusState}
        />
    </div> : <NoDataFoundComponent width="300px" messageFontSize="28px"/>
  );
};
