import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useUser } from '../redux/actions/userAction';
import UnauthorizedPage from '../pages/unauthorizedPage/unauthorizedPage';
import AdminPanel from '../pages/[admin]/adminPage/adminPage';
import { UserGoalResponse } from '../pages/[admin]/userGoalResponse/userGoalResponse';
import { routeUrl } from '../utils/index';

const AdminRoutesMap: RoutesMapInterface = Object.freeze({
    "/admin_panel": <AdminPanel/>,
    [routeUrl.USER_GOAL_RESPONSE]: <UserGoalResponse/>,
});

export const AdminRoutes = () => {
    const { user } = useUser();
    const { isLoggedIn, isAdmin } = user;

 
    return <Routes>
        {
            Object.entries(AdminRoutesMap).map(([path, element]) => (
                <Route key={path} path={path} element={(isLoggedIn && isAdmin) ? element : <UnauthorizedPage/>} />
            ))
        }
    </Routes>
};
