import React, { useEffect, useRef, useState } from "react";
import {
  AddIcon,
  BoldIcon,
  ItalicIcon,
  PaperPlanIcon,
  UnderlineIcon,
} from "../../icons";
import "./chatInput.scss";
import { readFileAsDataURL } from "../../utils";

const ChatInput: React.FC<ChatInputProps> = ({
  className = "",
  defaultValue = "",
  onSendClick,
  disableSendBtn,
}: ChatInputProps) => {
  const [fileList, setFileList] = useState<Array<string | ArrayBuffer>>([]);
  const [inputValue, setInputValue] = useState<string>(defaultValue);
  const inputContainerRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const focusInputContainer = () =>
    inputContainerRef.current && inputContainerRef.current.focus();
  useEffect(() => {
    focusInputContainer();
  }, [inputValue]);
  const [existingFilesSize, setExistingFilesSize] = useState(0);

  /**
   * TODO: @dhananjay - This component is not handling bold, italic and underline buttons for now. We need to update this.
   */
  const optionList = [
    {
      icon: <AddIcon height="40px" />,
      onClick: () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      },
    },
    {
      icon: <BoldIcon fillColor="gray" height="40px" />,
      onClick: () => {
        // handleEditorClick("strong");
      },
    },
    {
      icon: <ItalicIcon fillColor="gray" height="22px" />,
      onClick: () => {
        // handleEditorClick("em");
      },
    },
    {
      icon: <UnderlineIcon fillColor="gray" height="20px" />,
      onClick: () => {
        // handleEditorClick("u");
      },
    },
  ];

  const focusEndOfContentEditable = () => {
    const range = document.createRange();
    if (inputContainerRef.current) {
      range.selectNodeContents(inputContainerRef.current);
      range.collapse(false); // Collapse range to the end
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
      inputContainerRef.current.focus();
    }
  };

  const handleEditorClick = (element: string) => {
    const selection = window.getSelection();
    if (selection) {
      const range = selection.getRangeAt(0);
      const { startContainer, startOffset } = range;
      const boldElement = document.createElement(element);
      boldElement.appendChild(document.createTextNode(" "));
      const isAtEnd =
        startContainer.nodeValue &&
        startContainer.nodeType === Node.TEXT_NODE &&
        startOffset === startContainer.nodeValue.length;
      range.setStart(startContainer, startOffset);
      range.deleteContents();
      if (isAtEnd) {
        if (inputContainerRef.current) {
          inputContainerRef.current.appendChild(boldElement);
        }
        focusEndOfContentEditable();
      } else {
        range.insertNode(boldElement);
        selection.removeAllRanges();
        selection.addRange(range);
      }
      boldElement.focus();
    }
  };
  const maxTotalFileSizeLimitInKb = 300;
  const onFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const fileDataList = [];
    let fileSize = existingFilesSize;
    for (let i = 0; files && i < files?.length; i += 1) {
      fileSize += files[i].size / 1000;
    }
    if (fileSize > maxTotalFileSizeLimitInKb) {
      alert(`File size limit is ${maxTotalFileSizeLimitInKb}`);
      return;
    } else {
      for (let i = 0; files && i < files?.length; i += 1) {
        const fileData = await readFileAsDataURL(files[i]);
        fileDataList.push(fileData);
      }
      setFileList([...fileList, ...fileDataList]);
      setExistingFilesSize(fileSize);
    }
  };

  const removeFile = (fileIndex: number) => {
    setFileList(fileList.filter((file, index) => index !== fileIndex));
  };

  return (
    <div className={`chat-input-wrapper-continer ${className}`}>
      <div
        ref={inputContainerRef}
        contentEditable={true}
        className="chat-input-container"
        onInput={(e) => setInputValue(e.currentTarget.innerText)}
      ></div>
      {fileList.length > 0 && (
        <div className="chat-input-files-container">
          {fileList.map((file, index) => (
            <div className="chat-input-file-sub-container">
              <img
                className="chat-input-file"
                height={20}
                src={file as string}
                key={index}
              />
              <span
                onClick={() => removeFile(index)}
                className="chat-file-cross-btn"
              >
                X
              </span>
            </div>
          ))}
        </div>
      )}
      <div className="chat-footer-container">
        <div className="chat-options-container">
          {optionList.map((option) => (
            <button
              onClick={option.onClick}
              className="chat-options-container-item"
            >
              {option.icon}
            </button>
          ))}
        </div>
        <div
          onClick={() => !disableSendBtn && onSendClick(inputValue, fileList)}
          className={`chat-message-send-btn ${
            disableSendBtn && "chat-message-send-btn-disabled"
          }`}
        >
          <PaperPlanIcon fillColor={disableSendBtn ? "gray" : "#4caf50"} />
        </div>
      </div>
      <input
        onChange={onFileInput}
        accept=".jpeg, .jpg, .png"
        multiple
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default ChatInput;