import { useDispatch } from "react-redux";
import { setHeader } from "../slices/header/headerSlice";

const useHeader = () => {
    const dispatch = useDispatch();
    const updateHeader = (payload: HeaderStateType) => {
        dispatch(setHeader(payload))
    }
    return { updateHeader };
}

export default useHeader;