import { useDispatch, useSelector } from "react-redux";
import { apolloClient } from "../../graphql/apolloClient/apolloClient";
import { GET_NOTES } from "../../graphql/query/notes/getAllNotes";
import { actions, selectNotes } from "../slices/notes/notesSlice";
import { GET_ALL_NOTES_COUNT_BY_TOPIC_SUBTOPIC } from "../../graphql/query/notes/getAllNotesCountByTopicAndSubTopic";
import {
  notesTopicSubtopicAction,
  topicSubtopicNotesCountData,
} from "../slices/notes/topicSubtopicNotesCount";
export const useNotes = () => {
  const dispatch = useDispatch();
  const noteData = useSelector(selectNotes);

  const getAllNotes = async ({
    link,
    title,
    dayNumber,
    weekNumber,
    topics,
    noOfPages,
    description,
    estimatedReadingTime,
    tags,
  }: NotesFilterDataType) => {
    try {
      dispatch(actions.setIsNotesLoading(true));
      const response = await apolloClient.query({
        query: GET_NOTES,
        variables: {
          filterData: {
            link,
            title,
            dayNumber,
            weekNumber,
            topics,
            noOfPages,
            description,
            estimatedReadingTime,
            tags,
          },
        },
      });
      dispatch(actions.setNotes(response.data.getAllNotes));
      return response;
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(actions.setIsNotesLoading(false));
    }
  };

  const notesCountData = useSelector(topicSubtopicNotesCountData);

  const getAllNotesByTopicSubtopicCount = async () => {
    const { setNotesTopicSubtopic } = notesTopicSubtopicAction;
    try {
      const response = await apolloClient.query({
        query: GET_ALL_NOTES_COUNT_BY_TOPIC_SUBTOPIC,
        variables: {},
      });
      dispatch(
        setNotesTopicSubtopic(
          response?.data?.getAllNotesCountByTopicAndSubTopic?.notesData || []
        )
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch notes topic subtopic", error);
    }
  };

  return { noteData, getAllNotes, getAllNotesByTopicSubtopicCount,notesCountData };
};