import React from "react";
import "./noDataFound.scss";
import { NoDataFound } from "../../icons/noDataFound";

const NoDataFoundComponent: React.FC<NoDataFoundProps> = ({ message = "No data found" ,className,height,style,width,messageFontSize="40px"}) => {
  return (
    <div className={`no-data-container ${className}`} style={style}>
      <div className="user-icon">
        <NoDataFound height={height} width={width} />
      </div>
      <p style={{ fontSize: messageFontSize }}>{message}</p>
    </div>
  );
};

export default NoDataFoundComponent;
