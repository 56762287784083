import {gql} from "@apollo/client";

export const GET_BATCH_DETAILS = gql`
query GetBatchDetails($batchCode: String) {
  getBatchDetails(batchCode: $batchCode) {
    response {
      status
      message
    }
    batchDetails {
      githubOrgRepo
      discordServerLink
    }
  }
}
`