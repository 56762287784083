import React, { useRef, useState } from "react";
import "./goalsTable.scss";
import useOnOutsideClick from "../../hooks/useOnOutsideClick";
import { GoalsTableHeaderChildren } from "../tableHeaderChildren/tableHeaderChildren";
import { GoalInfoModal } from "../../components/goalInfoModal/goalInfoModal";
import { GoalTooltipContainer } from "../../components/goalTooltipContainer/goalTooltipContainer";
import { goalStyleByStatus, goalStatus } from "../../utils/constants";
import { getCurrentTime } from "../../utils/getCurrentTime";

const GoalsTable = ({
  tableData,
  onSubmit,
}: TableProps ) => {
  const defaultTooltipData : TableToolTipStateType = {
    colIndex: null,
    rowIndex: null,
  };
  const [currentOpenTooltip, setCurrentOpenTooltip] =
    useState<TableToolTipStateType>(defaultTooltipData);
  const subContainerRef = useRef<HTMLDivElement | null>(null);
  const rowContainerRef = useRef<HTMLDivElement | null>(null);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const goalContainerRef = useRef<HTMLDivElement | null>(null);
  const closeGoalInfoModal = () => {
    setCurrentOpenTooltip(defaultTooltipData)
  }
  useOnOutsideClick(goalContainerRef,closeGoalInfoModal);
  const handleRowContainerScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollLeft } = e.currentTarget;
    if (
      subContainerRef.current &&
      rowContainerRef.current &&
      tableContainerRef.current
    ) {
      subContainerRef.current.scrollLeft = scrollLeft;
      rowContainerRef.current.scrollLeft = scrollLeft;
      const rowScrollLeft = rowContainerRef.current.scrollLeft;
      const rowScrollTop = rowContainerRef.current.scrollTop;

      tableContainerRef.current.style.boxShadow =
        rowScrollLeft > 0 ? "inset 8px 0 10px -5px gray" : "none";

      subContainerRef.current.style.boxShadow =
        rowScrollTop > 0 ? "0 0 2px 5px gray" : "none";
    }
  };
  const currentDate = getCurrentTime();
  return (
    <div
      className="goals-table-container"
      ref={tableContainerRef}
    >
      <div ref={subContainerRef} className="goals-table-sub-container" onScroll={handleRowContainerScroll}>
        {tableData?.header?.map((header: GoalsTableDataHeaderType, headIndex: number) => (
            <div key={headIndex} className="goals-table-header-cell-wrapper">
              <div key={headIndex} className="goals-table-header-cell">{header?.title}</div>
                { 
                  header?.children && <GoalsTableHeaderChildren key={headIndex} data={header.children}/>
                } 
            </div>

        ))}
      </div>

      <div
        className="goals-table-row-container"
        ref={rowContainerRef}
        onScroll={handleRowContainerScroll}
      >
        {tableData?.rows?.map((column, rowIndex: number) => (
          <div key={`goals-table-row-${rowIndex}`} className="goals-table-column-cell">
            {column?.map((columnData , colIndex: number) => {
              const {userGoalStatus, duplicate, goalId } = columnData;
              const currentStatus = userGoalStatus?.at(-1);
              const time = new Date(currentStatus?.time);
              return (
              !duplicate &&
               <div 
                style={{ width: 200 * (columnData?.weekCount ?? 1) }} key={rowIndex * 100 + colIndex}
                className="goals-table-row" >
               {
               Boolean(Object.entries(columnData).length) && 
                <div className="goal-info-and-tooltip-wrapper">
                  <div
                  ref={goalContainerRef}
                  onClick={(e) => {
                    e.stopPropagation();
                      if(currentStatus?.status && currentStatus?.status?.tagKey !== goalStatus.INACTIVE) {
                        setCurrentOpenTooltip({ colIndex, rowIndex });
                      }
                  }}
                  className={`goal-container-table
                  ${currentStatus?.status && goalStyleByStatus[currentStatus?.status?.tagKey]}
                  `}
                >
                  {goalId?.title}
                  { colIndex === currentOpenTooltip?.colIndex &&
                    rowIndex === currentOpenTooltip?.rowIndex && 
                      <GoalInfoModal 
                        title={goalId?.title} 
                        onOutsideClick={closeGoalInfoModal}
                        onClick={closeGoalInfoModal}
                      >
                        <GoalTooltipContainer
                          id={columnData?._id ?? ""}
                        />
                      </GoalInfoModal>
                  }
                </div>
                  <div className={`goal-status-tooltip-container 
                                ${rowIndex === tableData?.rows?.length-1
                                   && "goal-status-tooltip-container-upward" 
                                  }`
                              }
                  >
                    <div className="goal-status-tooltip">
                      {currentStatus?.status?.tagName || ""}{" "}
                        •
                      {" "}{time.toLocaleString()}
                    </div>
                  </div>
                  </div>
                } 
              </div>
            )})}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoalsTable;
