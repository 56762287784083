import { apolloClient } from "../../../graphql/apolloClient/apolloClient";
import { CREATE_GOAL } from "../../../graphql/mutation/admin/goal/createGoal";

export const createGoal = async ({
  batchCode,
  description,
  endWeek,
  isLive,
  isMandatory,
  startWeek,
  title,
  goalType,
  isActive,
  userResponseLabels,
}: CreateGoalType) => {
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_GOAL,
      variables: {
        input: {
          batchCode: batchCode,
          description: description,
          endWeek: endWeek,
          goalType: goalType,
          isLive: isLive,
          isMandatory: isMandatory,
          startWeek: startWeek,
          title: title,
          isActive:isActive,
          userResponseLabels,
        },
      },
    });
    return response.data.createNewGoal;
  } catch (err) {
    console.log(err);
  }
};
