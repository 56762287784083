import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    batchData: null,
    currentBatch: {},
    githubOrgRepo:"",
    discordServerLink:""
};

export const batchSlice = createSlice({
    name: "batch",
    initialState: initialState,
    reducers: {
        setBatchCode: (state, action) => {
            state.batchData = action.payload?.batchData;
        },
        setCurrentBatchCode: (state, action) => {
            state.currentBatch = action.payload;
        },
        setBatchGithubOrgRepoAndDiscordServerLink:(state,action)=>{
            state.githubOrgRepo = action.payload?.githubOrgRepo?? "";
            state.discordServerLink = action.payload?.discordServerLink?? "";
        }
    },
});

export const { actions } = batchSlice;
export const selectBatch = (state: {
    batch: { batchData: BatchSchemaType[]; currentBatch: CurrentBatchType,githubOrgRepo:string,discordServerLink:string };
}) => state.batch;
export default batchSlice.reducer;