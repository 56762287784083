import React from 'react'
import "./footerInfoPanel.scss";
import {  useNavigate } from 'react-router-dom';
import WebMastersLogo from '../../monorepoClient/icons/webMastersLogo';
import instagramIcon from "../../icons/instagramIcon.svg"
import twitterxIcon from "../../icons/twitterxIcon.svg"
import linkedinIcon from "../../icons/linkedinIcon.svg"
interface FooterInfoPanelProps {
    navRef: React.RefObject<HTMLDivElement>;
}
export const FooterInfoPanel: React.FC<FooterInfoPanelProps> = ({ navRef }) => {

    const navigate = useNavigate()
    return (
        <div className='footer-info-panel-component'
            ref={navRef}
        >
            <div className='footer-info-panel-container'>
                <div className='footer-info-panel-card-wrap'>
                    <div className='footer-info-panel-card-one'>
                        <div className='footer-info-panel-card-one-logo'>
                            <div className="webmaster-logo">
                                <WebMastersLogo isDarkMode={true} height='64px' width='64px' />
                            </div>
                        </div>
                        <div className='footer-info-panel-card-one-text-container'>
                            <p className='footer-info-panel-card-one-text'>Become a Top Notch Software Developer.</p>
                            <p className='footer-info-panel-card-one-text'>All rights reserved.</p>
                        </div>
                        <div className='footer-info-panel-card-one-social-media-wrap'>
                            <a className='footer-info-panel-card-one-social-media'
                                target="_blank"
                                href='https://x.com/bewebmasters?s=11'
                                rel="noreferrer"
                                
                            >
                                <img className='footer-info-panel-card-one-social-media-img' src={twitterxIcon} alt="" />
                            </a>
                            <a className='footer-info-panel-card-one-social-media'
                                target='_blank'
                                href='https://www.instagram.com/bewebmasters?igsh=MXFoeGs2dHQ5dDNhaQ=='
                                rel="noreferrer"
                            >
                                <img className='footer-info-panel-card-one-social-media-img' src={instagramIcon} alt="" />
                            </a>
                            <a className='footer-info-panel-card-one-social-media'
                                target='_blank'
                                href='https://www.linkedin.com/company/webmasters-coding-academy/'
                                rel="noreferrer"
                            >
                                <img className='footer-info-panel-card-one-social-media-img' src={linkedinIcon} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className='footer-info-panel-card-two'>
                        <div className='footer-info-panel-card-two-link-container'>
                            <h4 className='footer-info-panel-card-two-link-head'>Home</h4>
                            <p className='footer-info-panel-card-two-link'>Benefits</p>
                            <p className='footer-info-panel-card-two-link'>How To Use</p>
                            <p className='footer-info-panel-card-two-link'>Key Features</p>
                            <p className='footer-info-panel-card-two-link'>Pricing</p>
                            <p className='footer-info-panel-card-two-link'>Testimonials</p>
                            <p className='footer-info-panel-card-two-link'>FAQ’s</p>
                        </div>
                        <div className='footer-info-panel-card-two-link-container'>
                            <h4 className='footer-info-panel-card-two-link-head'>App</h4>
                            <p className='footer-info-panel-card-two-link'>Mobile App</p>
                            <p className='footer-info-panel-card-two-link'>Desktop App</p>
                            <p className='footer-info-panel-card-two-link'>How To Use</p>
                        </div>
                        <div className='footer-info-panel-card-two-link-container'>
                            <h4 className='footer-info-panel-card-two-link-head'>All Pages</h4>
                            <p className='footer-info-panel-card-two-link'>Home</p>
                            <p className='footer-info-panel-card-two-link'>App</p>
                            <p className='footer-info-panel-card-two-link'>Blogs</p>
                            <p className='footer-info-panel-card-two-link'>Blogs Open</p>
                            <p className='footer-info-panel-card-two-link'>Contact</p>
                            <p className='footer-info-panel-card-two-link'>Privacy Policy</p>
                            <p className='footer-info-panel-card-two-link'>404</p>
                        </div>
                    </div>
                    <div className='footer-info-panel-card-three'>
                        <div className="footer-info-panel-card-three-contactLink">
                            <iframe className='footer-info-panel-card-three-contactLink-iframe'
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d404.47429496545516!2d77.46567808478822!3d23.25112226936742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c43d4e34b978d%3A0x752d37d81bc1ac4!2sWebMasters!5e0!3m2!1sen!2sin!4v1699013109312!5m2!1sen!2sin"
                                title="webmasters"
                                style={{ border: "0" }}
                                // allowfullscreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                            <div className='footer-info-panel-card-three-location-map-link-container'>
                                <p className='footer-info-panel-card-three-location-map-link'
                                    onClick={() => navigate('https://maps.app.goo.gl/Yt6Z4RQMWUhDbgHg8/')}
                                >
                                    <span className="contact-heading">Address</span>
                                    <span className="contact-body">245, D.R.V.S Apartment, Sector C, Indrapuri, Bhopal</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-info-panel-foot'>
                    <p className='footer-info-panel-foot-p'>© 2024 Priyam Innovations Pvt. Ltd.</p>
                    <p className='footer-info-panel-foot-p'>Privacy Policy</p>
                </div>
            </div>
        </div>
    )
}
